//@flow
import React, { Component } from "react";
import { View, Text, StyleSheet } from "react-native";
import { constants, strings, store } from "cockpit-shared";
import Circle from "./Circle";

export type FilledCircleProps = {
  radius: number,
  color?: string,
  isFilled?: boolean
};
export type FilledCircleState = {};

export default class FilledCircle extends React.PureComponent<
  FilledCircleProps,
  FilledCircleState
> {
  get color() {
    return this.props.color || "white";
  }

  get fillColor() {
    return this.props.isFilled ? this.color : "transparent";
  }
  render() {
    return (
      <Circle
        radius={this.props.radius}
        borderColor={this.color}
        style={{
          backgroundColor: this.fillColor
        }}
      />
    );
  }
}
