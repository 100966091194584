//@flow
import type { BaseAction } from "../base/BaseActions";
import type { ConnectivityActionType } from "./ConnectivityActionTypes";
import { connectivityActionTypes } from "./ConnectivityActionTypes";
import { baseReducerHelper } from "../base";
import { commonActionHandler } from "../base/BaseActions";

export type ConnectivityReducerState = {
  isConnected: boolean
};

export type ConnectivityAction = BaseAction & {
  type: ConnectivityActionType
};

let defaultState: ConnectivityReducerState = {
  isConnected: true
};

export const connectivityReducer = baseReducerHelper.createReducer(defaultState, {
  [connectivityActionTypes.ON_NETWORK_CONNECTION_CHANGED]: commonActionHandler
});
