//@flow
import { BaseManager } from "../base";

type InfoRequest = {
  url: string,
  headers: Object
};

export default class InfoManager extends BaseManager {
  createInfoRequest(url: string) {
    return { url, headers: this.service.getTokenHeader(this.getToken()) };
  }

  getViewTypeUrl(viewType: string) {
    return `${this.mainDomain}view/${viewType}`;
  }

  getViewTypeWithDataSetUrl(dataSetId: string, viewType: string) {
    let viewTypeUrl = this.getViewTypeUrl(viewType);
    return `${viewTypeUrl}/${dataSetId}`;
  }

  getGlossaryRequest(dataSetId: string, categoryId: string): InfoRequest {
    let urlWithDataSet = this.getViewTypeWithDataSetUrl(dataSetId, "glossary");
    return this.createInfoRequest(`${urlWithDataSet}/categories/${categoryId}`);
  }

  getPrivacyPolicyRequest(): InfoRequest {
    return this.getHelperRequest(`privacy-policy`);
  }

  getHelperRequest(path: string): InfoRequest {
    let viewTypeUrl = this.getViewTypeUrl("help");
    return this.createInfoRequest(`${viewTypeUrl}/${path}`);
  }

  getHelperRequestForDesktop(path: string): InfoRequest {
    let viewTypeUrl = this.getViewTypeUrl("help");
    return this.createInfoRequest(`${viewTypeUrl}/desktop/${path}`);
  }

  getMessagesRequest(
    dataSetId: string,
    categoryId: ?string,
    entityId: ?string
  ) {
    let urlWithDataSet = this.getViewTypeWithDataSetUrl(dataSetId, "messages");
    let categoryIdPath = categoryId || "null";
    let entityIdPath = entityId || "null";
    return this.createInfoRequest(
      `${urlWithDataSet}/categories/${categoryIdPath}/entities/${entityIdPath}/`
    );
  }

  /*
   * Fetch the HTML to load for the contextuals 
   * Help, Glossary, Messages
   */
  async getContextualContent(content): Promise<string> {
    let response: BaseManager = await this.fetchHTML(content);
    return response;
  }
}
