import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

function getRedirectUrl() {
  //Get the redirect Url, and make sure that there are no queries in it
  return window.location.origin + "/login";
}

const AuthAwareRoute = ({ component: Component, isAuthenticated, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      //Need to do it this way, othervise when redirecting the redirect url will be visible to the user
      if (isAuthenticated) {
        return <Component {...props} />
      } else {
        window.location.href = getRedirectUrl()// `https://backend.uszcockpit.ch/saml/login/?redirect=${getRedirectUrl()}`;
      }
    }
    }
  />
);

function mapStateToProps(state) {
  return {
    isAuthenticated: state.authenticationStore.token !== null,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthAwareRoute);
