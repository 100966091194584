//@flow
import React, { Component } from "react";
import * as icon from "../icon";
import { constants } from "cockpit-shared";
import { Tappable } from "../tappable";
import type { IconAssetType } from "../icon/assets";
import { H3 } from "../texts/index";
import { NotificationBadge } from "../notification-badge";

import "./HeaderNavigator.css";

type BottomIconProps = {
  iconName: IconAssetType,
  onTap: ?() => void,
  size?: number,
  isDisabled?: boolean
};

export default class BottomIcon extends Component<BottomIconProps> {
  render() {
    return (
      <Tappable
        isDisabled={this.props.isDisabled}
        onTap={() => {
          if (this.props.onTap) {
            this.props.onTap();
          }
        }}
      >
        <div className="header-navigator-icon-text-pairs" id={this.props.id}>
          <div className="header-navigator-icon">
            <icon.Icon
              name={this.props.iconName}
              color={
                this.props.isDisabled
                  ? constants.colors.gray
                  : constants.colors.lightBlue
              }
              size={this.props.size || icon.iconSizes.TINY}
            />
          </div>
          {this.props.unreadNotificationCount > 0 ? (
            <div className="header-navigator-notification-container">
              <NotificationBadge
                notificationsCount={this.props.unreadNotificationCount}
              />
            </div>
          ) : null}
          {this.props.text ? (
            <div className="header-navigator-text">
              <H3 style={{
                textAlignVertical: "center",
                color: constants.colors.white,
                alignSelf: "center",
                fontWeight: 200,
              }}>{this.props.text}</H3>
            </div>
          ) : null
          }
        </div>
      </Tappable>
    );
  }
}
