//@flow
import { chartValueFormatter as formatter  } from "../chart-value-formatter";

export type budgetPreviousYearJsonType = {
  ytd: formatter.valueType,
  bu_perc: formatter.valueType,
  bu_abs: formatter.valueType,
  pre_year_perc: formatter.valueType,
  pre_year_abs: formatter.valueType
};

export default class BudgetPreviousYearBaseViewModel {
  _json: budgetPreviousYearJsonType;
  budgetPercentageFormatted: string;
  previousYearPercentageFormatted: string;
  budgetAbsoluteFormatted: string;
  previousYearAbsoluteFormatted: string;
  ytdAbsoluteFormatted: string;

  constructor(json: budgetPreviousYearJsonType) {
    this._json = json;
    this._formatValues();
  }

  _formatValues() {
    let formatAbsolute = (value: ?number) => formatter.formatChartValue(value, false);
    let formatPercentage = (value: ?number) => formatter.formatChartValue(value, true);
    this.budgetPercentageFormatted = formatPercentage(this.budget(true));
    this.previousYearPercentageFormatted = formatPercentage(this.previousYear(true));
    this.budgetAbsoluteFormatted = formatAbsolute(this.budget(false));
    this.previousYearAbsoluteFormatted = formatAbsolute(this.previousYear(false));
    this.ytdAbsoluteFormatted = formatAbsolute(this.ytd);
  }

  budget(isPercentage: boolean): ?number {
    return formatter.validateValue(isPercentage ? this._json.bu_perc : this._json.bu_abs);
  }

  previousYear(isPercentage: boolean): ?number {
    return formatter.validateValue(isPercentage ? this._json.pre_year_perc : this._json.pre_year_abs);
  }

  get ytd(): ?number {
    return formatter.validateValue(this._json.ytd);
  }

  budgetFormatted(isPercentage: boolean): string {
    return isPercentage ? this.budgetPercentageFormatted : this.budgetAbsoluteFormatted;
  }

  previousYearFormatted(isPercentage: boolean): string {
    return isPercentage ? this.previousYearPercentageFormatted : this.previousYearAbsoluteFormatted;
  }

  ytdFormatted() {
    return this.ytdAbsoluteFormatted;
  }
}
