//@flow
import type { RootReducerState } from "../root/RootReducer";
import { dataSetActionTypes } from "./DataSetActionTypes";
import { createAction } from "../base/BaseActions";
import data from "../../data";
import moment from "moment/moment";

function findDataSetYearFromId(dataSetId: string): ?number {
  let parts = dataSetId.split("-");
  if (parts.length === 1) {
    let date = moment(dataSetId, "dd.mm.YYYY");
    return date.year() || null;
  }
  let yearPart = parts[1];
  if (yearPart) {
    return parseInt(yearPart);
  }
  return null;
}


export function updateDataSet(newDataSet: string) {
  return async function (dispatch: any, getState: () => RootReducerState) {
    let action = createAction(dataSetActionTypes.UPDATE_DATA_SET, {
      dataSet: newDataSet
    });
    dispatch(action);
  };
}

export function loadDataSets() {
  return async function (dispatch: any, getState: () => RootReducerState) {
    let loadingAction = createAction(dataSetActionTypes.LOAD_DATA_SETS, {
      isLoading: true
    });
    dispatch(loadingAction);
    let manager = new data.dataSets.DataSetsManager();
    let response = await manager.getDataSets();
    let endActionPayload;
    if (response instanceof data.error.ServiceError) {
      endActionPayload = {
        isLoading: false
      };
    } else {
      endActionPayload = {
        isLoading: false,
        dataSets: response
      };
    }
    return dispatch(createAction(dataSetActionTypes.LOAD_DATA_SETS, endActionPayload));
  };
}

export function changeDataSet(newDataSetId: string) {
  return async function (dispatch: any, getState: () => RootReducerState) {
    let dataSets = getState().dataSetStore.dataSets;
    let newDataSet: data.dataSets.DataSetViewModel = dataSets.find(item => item.id === newDataSetId);
    if (newDataSet) {
      let changeDataSetAction = createAction(dataSetActionTypes.CHANGE_DATA_SET, {
        id: newDataSet.id,
        name: newDataSet.name,
        currentMonth: newDataSet.currentMonth,
        currentYear: findDataSetYearFromId(newDataSet.id),
      });
      return dispatch(changeDataSetAction);
    }
  };
}

export function loadDefaultDataSet() {
  return async function (dispatch: any, getState: () => RootReducerState) {
    await dispatch(loadDataSets());
    let defaultDataSet = undefined;
    if (getState().dataSetStore.dataSets.length === 1) {
      defaultDataSet = getState().dataSetStore.dataSets[0];
    } else {
      defaultDataSet = getState().dataSetStore.dataSets.find(item => item.isDefault);
    }
    if (defaultDataSet) {
      return dispatch(changeDataSet(defaultDataSet.id));
    } else {
      return dispatch(changeDataSet(getState().dataSetStore.dataSets[0].id));
    }
  }
}


export function loadDataSet(id: String) {
  return async function (dispatch: any, getState: () => RootReducerState) {
    await dispatch(loadDataSets());
    let dataSet = getState().dataSetStore.dataSets.find(item => item.id === id);
    if (dataSet) {
      return dispatch(changeDataSet(dataSet.id));
    }
  }
}

export function isNewDefaultDataSetAvailable() {
  return async function (dispatch: any, getState: () => RootReducerState) {
    let manager = new data.dataSets.DataSetsManager();
    let response = await manager.getDataSets();
    if (response instanceof data.error.ServiceError) {
      return false;
    }
    let currentDefaultDataSet = getState().dataSetStore.dataSets.find(item => item.isDefault);
    let newDefaultDataSet = response.find(item => item.isDefault);
    if (currentDefaultDataSet == null || newDefaultDataSet == null) {
      return false;
    }
    return newDefaultDataSet.id !== currentDefaultDataSet.id;
  }
}
