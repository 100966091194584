//@flow
import type { BaseAction } from "../base/BaseActions";
import type { DimensionsActionType } from "./DimensionsActionTypes";
import { dimensionsActionTypes } from "./DimensionsActionTypes";
import { baseReducerHelper } from "../base";
import data from "../../data";
import { commonActionHandler } from "../base/BaseActions";

export type DimensionsReducerState = {
  isLoading: boolean,
  dimensions: Array<data.dimension.DimensionViewModel>,
  selectedDimension: ?data.dimension.DimensionViewModel
};

export type DimensionsAction = BaseAction & {
  type: DimensionsActionType
};

let defaultState: DimensionsReducerState = {
  isLoading: false,
  dimensions: [],
  selectedDimension: null
};

export const dimensionsReducer = baseReducerHelper.createAuthAwareReducer(defaultState, {
  [dimensionsActionTypes.LOAD_DIMENSIONS]: commonActionHandler,
  [dimensionsActionTypes.SELECT_DIMENSION]: commonActionHandler,
  [dimensionsActionTypes.CLEAR_DIMENSIONS]: commonActionHandler,
  [dimensionsActionTypes.SHOW_LOADING_SPINNER]: commonActionHandler,
  [dimensionsActionTypes.RESET_STATE]: () => defaultState
});
