//@flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { View, FlatList } from "react-native";
import EntityRankItem, { EntitiesRankingHeader } from "./EntityRankItem";
import { constants } from "cockpit-shared";
import { ChartDataViewModel } from "cockpit-shared/src/data/chart-data";

type EntitiesRankingPageProps = {
  categories: Array<RankViewModel>,
  isColorInverted: boolean,
  onColumnTap: (columnIndex: number) => void,
};

type EntitiesRankingPageState = {};

class EntitiesRankingPage extends Component<
  EntitiesRankingPageProps,
  EntitiesRankingPageState
  > {
  render() {
    return (
      <View style={{ flex: 1, backgroundColor: constants.colors.white }}>
        <EntitiesRankingHeader />
        <FlatList
          data={this.categories}
          renderItem={this.renderItem.bind(this)}
          keyExtractor={this.getKey.bind(this)}
        />
      </View>
    );
  }

  get categories(): Array<RankViewModel> {
    return this.props.categories;
  }

  onItemTap(item) { }

  renderItem({ item, index, section }) {
    if (item.name == null) {
      return null;
    }
    return (
      <EntityRankItem
        item={item}
        index={index}
        onItemTap={this.onItemTap.bind(this)}
        barHeight={26}
        isColorInverted={this.props.isColorInverted}
      />
    );
  }

  getKey(item: Object, index: number): string {
    return index + "";
  }
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EntitiesRankingPage);
