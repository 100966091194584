//@flow
import type { BaseAction } from "../base/BaseActions";
import type { AuthenticationActionType } from "./AuthenticationActionTypes";
import { authenticationActionTypes } from "./AuthenticationActionTypes";
import { baseReducerHelper } from "../base";
import { commonActionHandler } from "../base/BaseActions";

export type AuthenticationReducerState = {
  token: ?string,
  username: ?string,
  uid: ?string,
  firstName: ?string,
  lastName: ?string
};

export type AuthenticationAction = BaseAction & {
  type: AuthenticationActionType
};

let defaultState: AuthenticationReducerState = {
  token: null,
  username: null,
  uid: null,
  firstName: null,
  lastName: null
};

export const authenticationReducer = baseReducerHelper.createAuthAwareReducer(
  defaultState,
  {
    [authenticationActionTypes.AUTHENTICATE_WITH_EMAIL]: commonActionHandler,
    [authenticationActionTypes.AUTHENTICATE_WITH_MICROSOFT]: commonActionHandler,
  }
);

export function createAuthenticationLogOutHandler(initialState: ?Object | ?Array<any>) {
  return {
    [authenticationActionTypes.LOGOUT]: (state: Object, action: AuthenticationAction) => {
      return initialState;
    }
  };
}
