//@flow

export default {
  container: {
    flex: 1,
    backgroundColor: "white"
  },
  chart: {
    flex: 1
  }
};
