//@flow
import { store as loginStore } from "../login/";

export default {
  navigationStore: null,
  homeNavigatorStore: null,
  loginPageStore: loginStore.loginPageReducer,
  subCategoriesPageStore: null,
  organizationDrilldownNavigatorStore: null,
  resetPasswordPageStore: null,
  changePasswordPageStore: null,
};
