//@flow
import React, { Component } from "react";
import { View, Text, StyleSheet } from "react-native";
import { constants, strings, store } from "cockpit-shared";

export type CircleProps = {
  radius: number,
  borderColor?: string,
  backgroundColor?: string,
  style?: Object,
};
export type CircleState = {};

export default class Circle extends React.PureComponent<
  CircleProps,
  CircleState
> {
  render() {
    return (
      <View
        style={{
          width: this.props.radius,
          height: this.props.radius,
          borderRadius: this.props.radius / 2,
          borderColor: this.props.borderColor || "white",
          borderWidth: 1,
          backgroundColor: this.props.backgroundColor || "transparent",
          ...this.props.style
        }}
      />
    );
  }
}
