//@flow
import * as React from "react";
import { View } from "react-native";
import { Logo } from "../logo";
import * as icon from "../icon";
import { NAVIGATION_HEADER_HEIGHT } from ".";
import { Link } from "react-router-dom";
import { constants } from "cockpit-shared";
import { Tappable } from "../tappable";

export type LoginHeaderProps = {
  notFoundPage: ?Boolean,
  showHelp: () => void
};
export type LoginHeaderState = {};


//It needs renaming => and discussing for notFound page
export default class LoginHeader extends React.Component<LoginHeaderProps, LoginHeaderState> {

  constructor(props) {
    super(props);
  }

  onHelpClick = () => {
    this.props.showHelp();
  }

  renderLogo() {
    return (
      <Link to="/">
        <Logo />
      </Link>
    );
  }

  renderRightView() {
    return (
      this.props.notFoundPage ? null : (
        <Tappable
          isDisabled={this.props.isDisabled}
          onTap={this.onHelpClick}
        >
          <icon.Icon
            name={icon.iconAssets.question}
            color={constants.colors.lightBlue}
            size={icon.iconSizes.TINY}
          />
        </Tappable>
      )
    );
  }

  render() {
    return (
      <View style={{ flexDirection: "column" }}>
        <View
          style={{
            marginHorizontal: 32,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            height: NAVIGATION_HEADER_HEIGHT,
          }}
        >
          {this.renderLogo()}
          {this.renderRightView()}
        </View>
      </View>
    );
  }
}
