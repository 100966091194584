//@flow
export const dimensionsActionTypes = {
    LOAD_DIMENSIONS: "LOAD_DIMENSIONS",
    SELECT_DIMENSION: "SELECT_DIMENSION",
    CLEAR_DIMENSIONS: "CLEAR_DIMENSIONS",
    RESET_STATE: "RESET_STATE_DIMENSIONS",
    SHOW_LOADING_SPINNER: "SHOW_LOADING_SPINNER"
  };
  
  export type DimensionsActionType = $Keys<typeof dimensionsActionTypes>;
  