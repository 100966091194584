//@flow
import type { RootReducerState } from "../root/RootReducer";
import { organizationsActionTypes } from "./OrganizationsActionTypes";
import { createAction } from "../base/BaseActions";
import data from "../../data";
import { dimensions } from "..";
import { chartsActions } from "../charts";
import { defaultState } from "./OrganizationsReducer";
import { toggleSpinner } from "../dimensions/DimensionsActions";

export function loadOrganizations() {
  return async function (dispatch: any, getState: () => RootReducerState) {
    let loadingAction = createAction(organizationsActionTypes.LOAD_ORGANIZATIONS, {
      isLoading: true,
    });
    dispatch(loadingAction);
    let manager = new data.entity.OrganizationEntityManager();
    let currentDataSetId = getState().dataSetStore.id;
    let currentCategoryId = getState().categoriesStore.category.id;
    let response:
      | Array<data.entity.OrganizationEntityModel>
      | data.error.ServiceError = await manager.getOrganizationEntities(
        currentDataSetId,
        currentCategoryId
      );
    var actionPayload = {
      organizations: response,
      isLoading: false,
    };
    if (response instanceof data.error.ServiceError) {
      actionPayload = {
        isLoading: false,
      };
    }
    await dispatch(createAction(organizationsActionTypes.LOAD_ORGANIZATIONS, actionPayload));
    return response;
  };
}

export function clearOrganizations() {
  return async function (dispatch: any, getState: () => RootReducerState) {
    return dispatch(
      createAction(organizationsActionTypes.CLEAR_ORGANIZATIONS, {
        organizations: [],
        selectedOrganization: defaultState.selectedOrganization,
      })
    );
  };
}

export function selectOrganization(
  newOrganization: data.entity.OrganizationEntityModel | data.entity.EntityViewModel
) {
  return async function (dispatch: any, getState: () => RootReducerState) {
    let selectAction = createAction(organizationsActionTypes.SELECT_ORGANIZATION, {
      selectedOrganization: newOrganization,
    });
    return dispatch(selectAction);
  };
}

export function selectDefaultOrganization() {
  return async function (dispatch: any, getState: () => RootReducerState) {
    let organizations = getState().organizationsStore.organizations;
    if (organizations && organizations.length > 0) {
      dispatch(selectOrganization(organizations[0]));
    }
  };
}

export function selectOrganizationAndRefresh(
  newOrganization: data.entity.OrganizationEntityModel | data.entity.EntityViewModel
) {
  return async function (dispatch: any, getState: () => RootReducerState) {
    let isDimensionSelected = getState().dimensionsStore.selectedDimension != null;
    dispatch(toggleSpinner(true));
    if (isDimensionSelected) {
      await dispatch(chartsActions.updateChartDataLoadingState(true));
    }
    await dispatch(selectOrganization(newOrganization));
    await dispatch(dimensions.dimensionsActions.loadDimensions());
    if (isDimensionSelected) {
      return dispatch(chartsActions.loadChartData());
    }
  };
}

export function selectOrganizationAndRefreshForWeb(
  newOrganization: data.entity.OrganizationEntityModel | data.entity.EntityViewModel
) {
  return async function (dispatch: any, getState: () => RootReducerState) {
    let isDimensionSelected = getState().dimensionsStore.selectedDimension;
    let selectedDimension = getState().dimensionsStore.selectedDimension;
    dispatch(toggleSpinner(true));
    if (isDimensionSelected) {
      await dispatch(chartsActions.updateChartDataLoadingState(true));
    }
    await dispatch(selectOrganization(newOrganization));
    let newDimens = await dispatch(dimensions.dimensionsActions.loadDimensions());
    let isSelectedDimensionAvailable = newDimens.filter(it => it.json.id === selectedDimension.json.id).length !== 0;
    await dispatch((isDimensionSelected && isSelectedDimensionAvailable)
      ? dimensions.dimensionsActions.selectDimension(selectedDimension)
      : dimensions.dimensionsActions.selectDefaultDimension()
    );
    if (isDimensionSelected) {
      return dispatch(chartsActions.loadChartData());
    }
  };
}

export function searchForFullOrganizationViewModel(organizationId: string) {
  return function (dispatch: any, getState: () => RootReducerState) {
    let allOrganizations: Array<data.entity.OrganizationEntityModel> = getState().organizationsStore
      .organizations;
    let searchInChildren = (childrenList, id) => {
      let result = childrenList.find((item) => item.id === id);
      if (result == null) {
        for (var child of childrenList) {
          if (child.children != null) {
            let r = searchInChildren(child.children, id);
            if (r != null) {
              return r;
            }
          }
        }
      } else {
        return result;
      }
    };
    return searchInChildren(allOrganizations, organizationId);
  };
}

export function drillUp() {
  return async function (dispatch: any, getState: () => RootReducerState) {
    let selectedOrganization = getState().organizationsStore.selectedOrganization;
    let parentId = selectedOrganization.parentId;
    let parentOrganization = dispatch(searchForFullOrganizationViewModel(parentId));
    dispatch(selectOrganizationAndRefresh(parentOrganization));
  };
}
