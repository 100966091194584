//@flow
import type { InAppNotificationActionType } from "./InAppNotificationActionsTypes";
import { inAppNotificationActionTypes } from "./InAppNotificationActionsTypes";
import { baseReducerHelper } from "../base";
import { commonActionHandler } from "../base/BaseActions";

export type InAppNotificationReducerState = {
  contentView: ?any
};

export type InAppNotificationAction = {
  type: InAppNotificationActionType,
  payload: Object
};

let defaultState: InAppNotificationReducerState = {
  contentView: null
};

export const inAppNotificationReducer = baseReducerHelper.createReducer(defaultState, {
  [inAppNotificationActionTypes.SHOW_IN_APP_NOTIFICATION]: commonActionHandler,
  [inAppNotificationActionTypes.HIDE_IN_APP_NOTIFICATION]: commonActionHandler
});
