//@flow
import * as React from "react";
import { Text, View, ScrollView, Image } from "react-native";
import { logoSource } from "./assets";

export type LogoProps = {
  style?: Object,
};
export type LogoState = {};
export default class Logo extends React.Component<LogoProps, LogoState> {
  render() {
    return <Image resizeMode="cover" resizeMethod={"scale"} style={{  height: 32, width: 180, ...this.props.style}} source={logoSource} />;
  }
}