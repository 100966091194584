//@flow
import { Dimensions } from "react-native";
var { height, width } = Dimensions.get("window");

let ScreenSize = {
  SCREEN_WIDTH: width,
  SCREEN_HEIGHT: height,
  SCREEN_MAX_LENGTH: Math.max(width, height),
  SCREEN_MIN_LENGTH: Math.min(width, height),
  IPHONE_INDICATOR_SIZE: 0
};

export { ScreenSize };
