//@flow
import * as base from "./base";
import * as authentication from "./authentication";
import * as error from "./error";
import * as color from "./color";
import * as categories from "./categories";
import * as category from "./category";
import * as charts from "./charts";
import * as chartData from "./chart-data";
import * as date from "./date";
import * as number from "./number";
import * as dimensions from "./dimensions";
import * as dimension from "./dimension";
import * as entity from "./entity";
import * as entitiesRanking from "./entities-ranking";
import * as rank from "./rank";
import * as info from "./info";
import * as dataSets from "./data-sets";
import * as notifications from "./notifications";

export default {
  base,
  authentication,
  error,
  color,
  categories,
  category,
  charts,
  chartData,
  date,
  number,
  dimension,
  dimensions,
  entity,
  entitiesRanking,
  rank,
  info,
  dataSets,
  notifications
};
