//@flow

var onTokenExpired = () => {};

export function onTokenExpiredHandlerReceived(handler: Function) {
  onTokenExpired = handler;
}

export function handleOnTokenExpired() {
  onTokenExpired();
}
