//@flow
import React from "react";
import { View } from "react-native";
import { H2, NumberText } from "../texts";
import { constants, strings } from "cockpit-shared";
import { Tappable } from "../tappable";
import { Circle } from "../circle";
import { DimensionViewModel } from "cockpit-shared/src/data/dimension";
import DimensionTableColorHelper from "./DimensionTableColorHelper";

const styles = {
  tableText: {
    alignItems: "center",
  },
  row: {
    flexDirection: "row",
    minHeight: 40,
    alignItems: "center",
    marginStart: 12,
  },
};

class TableLabelContainer extends React.PureComponent<
  { style: any, children: any },
  {}
> {
  render() {
    return (
      <View style={[styles.tableText, this.props.style]}>
        {this.props.children}
      </View>
    );
  }
}

type TableTextCommonProps = { style: any, color: string, children: any, isSelected: boolean };

class TableText extends React.PureComponent<TableTextCommonProps> {
  render() {
    return (
      <TableLabelContainer style={[this.props.style]}>
        <H2
          numberOfLines={1}
          style={{ color: this.props.color, ...this.props.textStyle }}
        >
          {this.props.children}
        </H2>
      </TableLabelContainer>
    );
  }
}

class TableHeaderText extends React.PureComponent<TableTextCommonProps> {
  render() {
    return (
      <View style={[this.props.style]}>
        <H2
          numberOfLines={1}
          style={{ color: this.props.color, ...this.props.headerTextStyle }}
        >
          {this.props.children}
        </H2>
      </View>
    );
  }
}

class TableNumberText extends React.PureComponent<TableTextCommonProps, {}> {
  isSelectedAndDefined() {
    return this.props.isSelected && this.props.color !== constants.colors.gray;
  }
  render() {
    return (
      <TableLabelContainer style={{ ...this.props.style, flexDirection: "row" }}>
        {
          this.isSelectedAndDefined() ?
            <Circle
              radius={11}
              borderColor={constants.colors.white}
              backgroundColor={this.props.color}
              isFilled={true}
              style={{ marginEnd: 5 }}
            />
            : <View style={{ width: 5 }} />
        }
        <NumberText
          style={{ color: this.isSelectedAndDefined() ? constants.colors.white : this.props.color, ...this.props.textStyle }}
        >
          {this.props.children}
        </NumberText>
      </TableLabelContainer>
    );
  }
}

type DimensionsTableItemProps = {
  isPercentage: boolean,
  onItemTap: (item: DimensionViewModel) => void,
  onItemOptionsTap: (item: Object) => void,
  item: DimensionViewModel,
  index: number,
  selectedDimensionId: String
};

type DimensionsTableItemState = {};

const firstColumnStyle = {
  flex: 10,
  alignItems: "flex-start",
  paddingStart: 20,
};

export default class DimensionsTableItem extends React.Component<
  DimensionsTableItemProps,
  DimensionsTableItemState
> {
  getVjColor(item: DimensionViewModel): string {
    //Vj color is dependent on absolute value, not the percentage one
    let vjValue: ?number = item.previousYear(false);
    let isColorInverted = item.invertedStatus.isDimensionTableVjColorInverted(
      this.isPercentage
    );
    return DimensionTableColorHelper.getCommonColor(vjValue, isColorInverted);
  }

  getBuColor(item: DimensionViewModel) {
    //Bu color is dependent on absolute value, not the percentage one
    let buValue: ?number = item.budget(false);
    let isColorInverted = item.invertedStatus.isDimensionTableBuColorInverted(
      this.isPercentage
    );
    return DimensionTableColorHelper.getCommonColor(buValue, isColorInverted);
  }

  getYtdColor(item: DimensionViewModel) {
    return this.isSelectedDimension(item.id) ? constants.colors.white : constants.colors.black;
  }

  isSelectedDimension(id) {
    return id === this.props.selectedDimensionId;
  }

  getBackgroundColor(id, index: number) {
    if (this.isSelectedDimension(id)) {
      return constants.colors.w_blue;
    } else if (index % 2 === 0) {
      return constants.colors.white;
    } else {
      return constants.colors.w_greyBlue;
    }
  }

  get isPercentage() {
    return this.props.isPercentage;
  }

  render() {
    let item = this.props.item;
    return (
      <Tappable
        onTap={() => {
          if (item.chartsAvailability.isAnyChartAvailable()) {
            this.props.onItemTap(item);
          }
        }}
      >
        <View
          style={[
            styles.row,
            { backgroundColor: this.getBackgroundColor(item.id, this.props.index), paddingEnd: 10 },
          ]}
        >
          <TableText
            textStyle={{ fontWeight: "500" }}
            style={{ ...firstColumnStyle }}
            color={this.isSelectedDimension(item.id) ? constants.colors.white : constants.colors.black}
          >
            {item.name}
          </TableText>
          <TableNumberText
            style={{ flex: 7, justifyContent: "flex-end", marginEnd: 10 }}
            color={this.getYtdColor(item)}
          >
            {item.ytdFormatted()}
          </TableNumberText>
          <TableNumberText
            style={{ flex: 7, justifyContent: "flex-end", marginEnd: 10 }}
            color={this.getBuColor(item)} isSelected={this.isSelectedDimension(item.id)}
          >
            {item.budgetFormatted(this.isPercentage)}
          </TableNumberText>
          <TableNumberText
            style={{ flex: 7, justifyContent: "flex-end", marginEnd: 10 }}
            color={this.getVjColor(item)} isSelected={this.isSelectedDimension(item.id)}
          >
            {item.previousYearFormatted(this.isPercentage)}
          </TableNumberText>
        </View>
      </Tappable>
    );
  }
}

export const DimensionTableHeader = (props: { section: Object, selectDataSetYear?: Number }) => {
  let commonProps = {
    style: {
      flex: 7,
      marginEnd: 10,
      alignItems: "flex-end"
    },
    textStyle: {
      marginStart: 10,
      fontWeight: "600",
    },
    color: constants.colors.black,
  };
  return (
    <View style={[styles.row, { backgroundColor: constants.colors.white, minHeight: 64, paddingEnd: 10 }]}>
      <TableHeaderText {...commonProps} style={{ ...firstColumnStyle }} headerTextStyle={{ fontWeight: "600" }} >
        {strings.stringsRepo.dimensionsHeaderTitle}
      </TableHeaderText>
      <TableHeaderText {...commonProps} headerTextStyle={{ ...commonProps.textStyle }}>
        {props.section.name}
      </TableHeaderText>
      <TableHeaderText {...commonProps} headerTextStyle={{ ...commonProps.textStyle }}>
        {strings.stringsRepo.budgetShort.toUpperCase()}
      </TableHeaderText>
      <TableHeaderText {...commonProps} headerTextStyle={{ ...commonProps.textStyle }}>
        {strings.stringsRepo.previousYearShort.toUpperCase()}
      </TableHeaderText>
    </View>
  );
};
