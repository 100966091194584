//@flow

export default class ServiceError {
  code: ErrorCodeType;
  httpStatusCode: ?number;
  message: ?string;
  constructor(code: ErrorCodeType, httpStatusCode: ?number, message: ?string) {
    this.code = code;
    this.httpStatusCode = httpStatusCode;
    this.message = message;
  }
}

export const errorCodes = {
  NO_INTERNET: "NO_INTERNET",
  GENERAL_ERROR: "GENERAL_ERROR"
};

export type ErrorCodeType = $Keys<typeof errorCodes>;
