//@flow
import type { BaseComponentProps } from "../../base-component";
import * as React from "react";
import { Text, View, ScrollView, Image } from "react-native";
import { IconTextNotification } from "./index";
import { iconAssets } from "../../icon/index";
import { constants } from "cockpit-shared";

export type SuccessNotificationProps = BaseComponentProps & {
  message: string,
};
export type SuccessNotificationState = {};
export default class SuccessNotification extends React.Component<
  SuccessNotificationProps,
  SuccessNotificationState
> {
  get messageStyle() {
    return {
      backgroundColor: constants.colors.brightGreen
    };
  }

  render() {
    return (
      <IconTextNotification
        style={this.messageStyle}
        message={this.props.message}
      />
    );
  }
}
