import { axisInversionType } from "./AxisInversionType";

//@flow
export type invertedJsonType = {
    axis_inversion_schema: string,
    actual_year_color_inverted: boolean,
    abs_deviation_color_inverted: boolean
};
/**
 * Tells if the values from the response should be treated as inverted or not.
 * In some cases is "good" to have a negative value.
 */
export default class InvertedViewModel {
  json: invertedJsonType;
  constructor(json: invertedJsonType) {
    this.json = json;
  }
  get axisInversionSchema() {
    return this.json.axis_inversion_schema;
  }
  isActualYearColorInverted() {
    return this.json.actual_year_color_inverted;
  }
  isAbsoluteDeviationColorInverted() {
    return this.json.abs_deviation_color_inverted ;
  }
  isBubbleChartInverted() {
    //When axisInversionSchema is true, bubble chart is inverted
    return this.axisInversionSchema === axisInversionType.inverted;
  }
  isSpecialAxisInversionSchema() {
    return this.axisInversionSchema === axisInversionType.special;
  }
  //BarChartDeviation = Budget/Vorjahr
  isBarChartDeviationInverted(percentage: boolean) {
    return !percentage && this.json.axis_inversion_schema === axisInversionType.special;
  }
  isBarChartActualYearInverted() {
    return this.json.axis_inversion_schema == axisInversionType.special
  }
  isBubblesColorInverted() {
    return this.json.actual_year_color_inverted === true;
  }
  isRankingChartColorInverted() {
    return this.json.actual_year_color_inverted === true;
  }
  isDimensionTableBuColorInverted(percentage) {
    return this.json.abs_deviation_color_inverted === true;
  }
  isDimensionTableVjColorInverted(percentage) {
    return  this.json.abs_deviation_color_inverted === true;
  }
  isDimensionsTableYtdColorInverted() {
    //YTD color are not inverted
    return false;
  }
  isBarChartDeviationColorInverted(percentage) {
    return this.json.abs_deviation_color_inverted === true;
  }
  //Also known as "ist" chart
  isBarChartActualYearColorInverted() {
    //Actual year color are never inverted
    return false; 
  }
}
