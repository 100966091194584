//@flow
import { createRootStore } from "./root/RootStore";
import { persistStore } from "redux-persist";

class StoreProvider {
  subscribers: Object;
  rootStore: ?Object;
  isBusy: boolean;

  constructor() {
    this.subscribers = {};
    this.rootStore = null;
    this.isBusy = false;
  }

  get shouldNotifyNewSubscriber() {
    return this.rootStore != null;
  }

  subscribe(id: string, onStoreCreated: (store: Object) => void) {
    this.subscribers[id] = onStoreCreated;
    if(this.isBusy) {
      return;
    }
    if (this.shouldNotifyNewSubscriber) {
      this._notifySubscriber(id);
    }
  }

  unsubscribe(id: string) {
    this.subscribers[id] = null;
  }

  _notfiySubscribers() {
    for (var key in this.subscribers) {
      this._notifySubscriber(key);
    }
  }

  _notifySubscriber(id: string) {
    let callback = this.subscribers[id];
    if (callback) {
      callback(this.rootStore);
    }
  }

  async createStore(props: Object) {
    this.isBusy = true;
    this.rootStore = createRootStore(
      {},
      props.reducers || [],
      props.middlewares,
      props.persistConfig
    );
    await this.createPersistor(this.rootStore);
    this.isBusy = false;
    this._notfiySubscribers();
  }

  async createPersistor(store: Object) {
    return new Promise((resolve, reject) => {
      let persistorOptions = null;
      let persistor = persistStore(store, persistorOptions, resolve);
    });
  }
}

export const storeProvider = new StoreProvider();
