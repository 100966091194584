//@flow
import type { Saga } from "redux-saga";
import { put, spawn, takeLatest } from "redux-saga/effects";
import { categoriesActionTypes } from "../categories";
import { dataSetActionTypes } from "../data-set/DataSetActionTypes";
import { organizationsActionTypes } from "../organizations/OrganizationsActionTypes";
import { loadNumberOfNewNotifications } from "./NotificationsActions";

function* updateNotificationCount(action) {
  yield put(loadNumberOfNewNotifications());
}

function* notificationsCountUpdateSaga(): Saga {
  yield takeLatest(
    [
      organizationsActionTypes.SELECT_ORGANIZATION,
      categoriesActionTypes.SELECT_CATEGORY,
      dataSetActionTypes.CHANGE_DATA_SET
    ],
    updateNotificationCount
  );
}

export default [spawn(notificationsCountUpdateSaga)];
