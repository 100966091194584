//@flow
import type { ConnectivityInterface } from "../connectivity";
import type { RequestType } from "../request-type";
import type { BaseServiceResponse } from "./BaseServiceResponse";
import ServiceError, { errorCodes } from "../error/ServiceError";
import { stringsRepo } from "../../strings";
import * as constants from "../../constants";

const defaultHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export default class BaseService {
  conectivity: ConnectivityInterface;
  fetchDefaultOptiopns: Object;

  constructor(conectivity: ConnectivityInterface) {
    this.conectivity = conectivity;
    this.fetchDefaultOptiopns = {};
  }

  get isConnected(): boolean {
    return this.conectivity.isConnected();
  }

  async commonApiOperation(url: string) {
    // Do something for every API call.
  }

  async makeNetworkRequest(
    requestFunc: Function
  ): Promise<BaseServiceResponse> {
    if (!this.isConnected) {
      return new ServiceError(
        errorCodes.NO_INTERNET,
        null,
        stringsRepo.noInternetConnection
      );
    }
    var response = null;
    try {
      response = await requestFunc();
      if (!response.ok) {
        let errorMessage = await response.json();
        return new ServiceError(
          errorCodes.GENERAL_ERROR,
          response.status,
          errorMessage
        );
      }
    } catch (e) {
      response = new ServiceError(errorCodes.GENERAL_ERROR, null, e.message);
    }
    return response;
  }

  getTokenHeader(token: string) {
    return { "X-API-TOKEN": token || "" };
  }

  createHeaders(token: string) {
    return new Headers({
      ...defaultHeaders,
      ...this.getTokenHeader(token),
    });
  }

  async makeRequest(
    type: RequestType,
    url: string,
    token: string,
    fetchProvider: Object,
    body: ?Object,
    callId: ?string,
    corsOff: ?Boolean
  ): Promise<BaseServiceResponse> {
    let options = {
      ...this.fetchDefaultOptiopns,
      method: type,
      body: body != null ? JSON.stringify(body) : null,
      headers: this.createHeaders(token),
    };
    await this.commonApiOperation(url);
    let corsOffUrl = `https://thingproxy.freeboard.io/fetch/${url}`;
    //Use the corsOffUrl only on web app
    let finalUrl = __DEV__ ? constants.DeviceType.IS_SMARTPHONE_OR_TABLET ? url : corsOffUrl : url;
    let response = await this.makeNetworkRequest(async () => {
      return fetchProvider.getFetch()(finalUrl, options, callId);
    });
    return response;
  }
}
