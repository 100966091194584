//@flow
import type { BaseComponentProps } from "../base-component";
import * as React from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  Image,
  ImageBackground,
} from "react-native";

import { ScreenSize } from "../../data/constants";
import { constants } from "cockpit-shared";

export type BackgroundProps = BaseComponentProps & {};
export type BackgroundState = {};
export default class Background extends React.Component<
  BackgroundProps,
  BackgroundState
> {
  render() {
    
    return (
      <View
        style={{
          flex: 1,
          background: constants.colors.gradientStart,
          background: `linear-gradient(180deg, ${constants.colors.gradientStart} 35%, ${constants.colors.gradientEnd} 100%)`,
          ...this.props.style,
        }}
      >
        {this.props.children}
      </View>
    );
  }
}
