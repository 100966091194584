//@flow
import "chartjs-plugin-datalabels"; //Do not delete -> labels won't show!!
import "chartjs-plugin-zoom"; //Do not delete -> zoom won't work!!
import "chartjs-plugin-style"; //Do not delete 
import { constants } from "cockpit-shared";
import * as chartsCommon from "../common";

let initaialLength = undefined;

//Static bubble chart configurations
const barConfig = {
  fontFamily: "",
  scaleLabel: {
    fontSize: 16,
    fontStyle: "bold",
    fontColor: constants.colors.w_veryDarkBlue
  },
  ticks: {
    fontColor: constants.colors.w_veryDarkBlue,
    tickCount: 20
  },
  zeroLineColor: constants.colors.w_zeroLineColor,
  gridLineColor: constants.colors.gridDashedLineColor,
  gridDashedLine: chartsCommon.gridDashedLineConfig()
};

const getExtraValuesToAddLength = (initialLength, minNrOfColumns) => {
  return minNrOfColumns - initialLength;
};

const addExtraValues = (arrayToExtend, placeHolder, minNrOfColumns) => {
  initaialLength = arrayToExtend.length;
  let extraValues = getExtraValuesToAddLength(arrayToExtend.length, minNrOfColumns);
  if (extraValues < 0) {
    return null;
  }
  for (let i = 0; i < extraValues; i++) {
    arrayToExtend.push(placeHolder);
  }
};

const getData = (values, isInverted, minNrOfColumns) => {
  let data = [];
  values.map((it) => {
    data.push(isInverted ? (-1 * it.y) : it.y);
  });
  //Add extra labels to meet the minBarsToShow criteria
  addExtraValues(data, 0, minNrOfColumns);
  return data;
};

const getLabels = (values, minNrOfColumns) => {
  let labels = [];
  values.map((it) => {
    labels.push("");
  });
  //Add extra labels to meet the minBarsToShow criteria
  addExtraValues(labels, "", minNrOfColumns);
  return labels;
};

const applyColorChange = (color, percent) => {
  var R = parseInt(color.substring(1, 3), 16);
  var G = parseInt(color.substring(3, 5), 16);
  var B = parseInt(color.substring(5, 7), 16);

  R = parseInt((R * (100 + percent)) / 100);
  G = parseInt((G * (100 + percent)) / 100);
  B = parseInt((B * (100 + percent)) / 100);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  var RR = R.toString(16).length == 1 ? "0" + R.toString(16) : R.toString(16);
  var GG = G.toString(16).length == 1 ? "0" + G.toString(16) : G.toString(16);
  var BB = B.toString(16).length == 1 ? "0" + B.toString(16) : B.toString(16);

  return "#" + RR + GG + BB;
};
/**
 * Lightens/darkens a given hex color
 * @param {array} colors
 * @param {number} percent = <0 => darker | >0 => kighter
 */
export const shadeColor = (colors, percent) => {
  let shadedColors = [];
  if (Array.isArray(colors)) {
    colors.map((color) => {
      shadedColors.push(applyColorChange(color, percent));
    });
  } else {
    shadedColors.push(applyColorChange(colors, percent));
  }
  return shadedColors;
};

export const barChartData = (realValues, isInverted, minNrOfColumns) => {
  return {
    labels: getLabels(realValues.values, minNrOfColumns),
    datasets: [{
      maxBarThickness: 40,
      backgroundColor: realValues.config.colors,
      hoverBackgroundColor: shadeColor(realValues.config.colors, -40),
      data: getData(realValues.values, isInverted, minNrOfColumns)
    }]
  };
};

//Ensures that 0 will be shown all the time
const ensureDividable = (value) => {
  return value < 0
    ? Math.round(value) % 2 === -1 ? Math.round(value) - 1 : Math.round(value)
    : Math.round(value) % 2 === 1 ? Math.round(value) + 1 : Math.round(value);
};

export const barChartOptions = (highlightElement, label, isInverted, isPercentageValues) => {
  return {
    maintainAspectRatio: false,
    legend: {
      display: false
    },
    animation: {
      duration: 500,
    },
    scales: {
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: label,
          fontStyle: barConfig.scaleLabel.fontStyle,
          fontSize: barConfig.scaleLabel.fontSize,
          fontColor: barConfig.scaleLabel.fontColor,
          padding: {
            bottom: 0
          }
        },
        stacked: false,
        gridLines: {
          color: "transparent",
          display: true,
          drawBorder: false,
          zeroLineColor: barConfig.zeroLineColor,
          zeroLineWidth: 1
        },
        ticks: {
          beginAtZero: true,
          //ennsures that the ticks on the yAxis are integers
          precision: 0,
          fontColor: barConfig.ticks.fontColor,
          callback: (label, index, labels) => {
            if (label >= 1000 || label <= -1000 && label != 0) {
              return label / 1000 + "k";
            }
            return (isInverted ? -1 * label : label);
          }
        },
        afterFit: function (scale) {
          scale.width = 60;  //<-- set value as you wish 
        }
      }],
      xAxes: [{
        stacked: true,
        gridLines: {
          borderDash: [barConfig.gridDashedLine.lineLength, barConfig.gridDashedLine.spaceLength],
          color: barConfig.gridLineColor,
        }
      }]
    },
    tooltips: {
      callbacks: {
        beforeLabel: (tooltipItem, data) => {
          let tooltipText = isInverted ? (-1 * tooltipItem.value) : tooltipItem.value
          return isPercentageValues ? tooltipText + "%" : tooltipText;
        },
        label: () => {
          return "";
        }
      },
      backgroundColor: constants.colors.spaceDark,
      bodyFontColor: constants.colors.white,
      bodyFontSize: 14,
      displayColors: false,
      cornerRadius: 5,
    },
    plugins: {
      datalabels: {
        display: false
      }
    },
    hover: {
      onHover: (e, element) => {
        if (element.length) {
          let index = element[0]._index;
          if (index <= initaialLength - 1) {
            highlightElement(index);
          }
        } else {
          highlightElement(null);
        }
      }
    }
  };
};
