//@flow
import React from "react";
import { View } from "react-native";
import { NumberText, H5, H3 } from "../texts";
import { constants, strings } from "cockpit-shared";
import { Tappable } from "../tappable";
import { RankBar } from "../rank-bar";
import { RankViewModel } from "cockpit-shared/src/data/rank";

type EntityRankItemProps = {
  onItemTap: (item: Object) => void,
  item: RankViewModel,
  index: number,
  barHeight: number,
  isColorInverted: boolean
};

type EntityRankItemState = {};
const columnsFlex = [1, 1, 6];
export default class EntityRankItem extends React.Component<EntityRankItemProps, EntityRankItemState> {

  get goodBarChartColor() {
    return constants.colors.barChartColor;
  }

  get badBarChartColor() {
    return constants.colors.gray;
  }

  getBarBackgroundColor() {
    return this.isItemPositive ? this.goodBarChartColor : this.badBarChartColor;
  }

  get goodYtdColor() {
    return constants.colors.w_veryDarkBlue;
  }

  get badYtdColor() {
    return constants.colors.gray;
  }

  getYtdColor() {
    //As APP-270, YTD will be colored always as a good value
    //return this.isItemPositive ? this.goodYtdColor : this.badYtdColor;
    return this.goodYtdColor;
  }

  get isItemPositive() {
    let { ytd } = this.props.item
    return ytd === 0
      || (ytd > 0 && !this.props.isColorInverted)
      || (ytd < 0 && this.props.isColorInverted);
  }

  getBackgroundColor(index: number) {
    if (index % 2 == 0) {
      return constants.colors.white;
    } else {
      return constants.colors.w_greyBlue;
    }
  }

  get rankFormatted() {
    let rank = this.props.item.relativeRank;
    if (rank > 9) {
      return `${rank}`;
    } else {
      return `0${rank}`;
    }
  }

  get animationDelay() {
    return 100 * this.props.item.relativeRank;
  }

  render() {
    return (
      <Tappable
        isDisabled={true}
        onTap={() => this.props.onItemTap(this.props.item)}
      >
        <RowContainer
          style={{
            backgroundColor: this.getBackgroundColor(this.props.index),
            flexDirection: "row",
            paddingVertical: 3,
            paddingStart: 40,
            minHeight: 40
          }}
        >
          <TableText
            style={{ flex: columnsFlex[0] }}
            color={constants.colors.black}
          >
            {this.rankFormatted}
          </TableText>
          <TableText
            style={{ flex: columnsFlex[1] }}
            color={constants.colors.black}
          >
            {this.props.item.name}
          </TableText>
          <View
            style={{
              flex: columnsFlex[2],
              flexDirection: "row",
              alignItems: "center"
            }}
          >
            <TableText
              style={{
                flex: 1, alignItems: "flex-end",
                marginRight: 35
              }}
              color={this.getYtdColor()}
            >
              {this.props.item.ytdAbsoluteFormatted}
            </TableText>
            <RankBar
              animationDelay={this.animationDelay}
              style={{
                flex: 5,
                alignSelf: "center"
              }}
              loadedPercent={this.props.item.relativeYtd}
              height={this.props.barHeight}
              barStyle={{
                backgroundColor: this.getBarBackgroundColor()
              }}
            />
          </View>
        </RowContainer>
      </Tappable>
    );
  }
}

const RowContainer = props => {
  return (
    <View
      style={{
        flexDirection: "row",
        paddingHorizontal: 9,
        alignItems: "center",
        ...props.style,
      }}
    >
      {props.children}
    </View>
  );
};

class TableText extends React.PureComponent<{ style: any, color: string, children: any }, {}>
{
  render() {
    return (
      <View style={this.props.style}>
        <NumberText style={{ color: this.props.color }}>
          {this.props.children}
        </NumberText>
      </View>
    );
  }
}

const HeaderText = props => {
  return (
    <View style={props.style}>
      <H3
        numberOfLines={1}
        style={{
          alignItems: "flex-end",
          fontWeight: "bold",
          color: constants.colors.barChartColor.w_veryDarkBlue
        }}
      >
        {props.children}
      </H3>
    </View>
  );
};

export const EntitiesRankingHeader = (props: { year: ?number }) => {
  return (
    <RowContainer
      style={{
        height: 35,
        backgroundColor: constants.colors.w_grey,
        flexDirection: "row",
        alignItems: "center",
        paddingStart: 38,
      }}
    >
      <HeaderText style={{ flex: columnsFlex[0] }}>
        {strings.stringsRepo.struktur}
      </HeaderText>
      <HeaderText style={{ flex: columnsFlex[1] }} />
      <View
        style={{
          flex: columnsFlex[2],
          flexDirection: "row",
          alignItems: "center"
        }}
      >
        <HeaderText style={{ flex: 1, alignItems: "flex-end", marginRight: 35 }}>
          {props.year ? props.year : strings.stringsRepo.ytd.toUpperCase()}
        </HeaderText>
        <HeaderText style={{ flex: 5 }} />
      </View>
    </RowContainer>
  );
};
