//@flow
import { createTransform } from "redux-persist";
import DataSetViewModel from "../../data/data-sets/DataSetViewModel";


// Here we serialize and deserialize the items from data set store, 
// we do this explicitly because we need DataSetViewModel instances in the store.
export const dataSetsTransform = createTransform(
  (inboundState, key) => {
    return inboundState;
  },
  (outboundState, key) => {
    return {
      ...outboundState,
      dataSets: outboundState.dataSets.map(
        item => new DataSetViewModel(item.json)
      )
    };
  },
  { whitelist: ["dataSetStore"] }
);
