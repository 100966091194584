import { LayoutType } from "../bar-chart/BarChart";
import { PositionType } from "../../tab-content/EntityChartCommonTabContent";
export default class HeaderPositioner {

    constructor(renderFunction: any) {
        this.forceRender = renderFunction;
        this.budget = -1;
        this.previous = -1;
        this.ist = -1;
        this.topMargin = -1;
        this.available = false;
    }

    onChartsHeaderLayout(e: LayoutType) {
        let newMargin = e.y + e.height;
        if (newMargin !== this.topMargin) {
            this.topMargin = newMargin;
            this.tryUpdatePosition();
        }
    }

    onBudgetHeaderLayout(e: LayoutType) {
        if (e.y !== this.budget) {
            this.budget = e.y;
            this.tryUpdatePosition();
        }
    }

    onPreviousYearHeaderLayout(e: LayoutType) {
        if (e.y !== this.previous) {
            this.previous = e.y;
            this.tryUpdatePosition();
        }
    }

    onIstHeaderLayout(e: LayoutType) {
        if (e.y !== this.ist) {
            this.ist = e.y;
            this.tryUpdatePosition();
        }
    }

    tryUpdatePosition() {
        if (
            this.budget >= 0
            && this.previous >= 0
            && this.ist >= 0
            && this.topMargin >= 0
        ) {
            this.available = true;
            this.forceRender();
        } else {
            this.available = false;
        }
    }

    getBudgetPosition(): PositionType {
        return {
            available: this.available,
            top: this.available ? this.topMargin + this.budget : 0
        };
    }

    getPreviousYearPosition(): PositionType {
        return {
            available: this.available,
            top: this.available ? this.topMargin + this.previous : 0
        };
    }

    getIstPosition(): PositionType {
        return {
            available: this.available,
            top: this.available ? this.topMargin + this.ist : 0
        };
    }
}