//@flow
import type { BaseComponentProps } from "../../base-component";
import * as React from "react";
import { Text, View, ScrollView, Image } from "react-native";
import { IconTextNotification } from "./index";
import { iconAssets } from "../../icon/index";
import { constants } from "cockpit-shared";

export type ErrorNotificationProps = BaseComponentProps & {
  message: string
};
export type ErrorNotificationState = {};
export default class ErrorNotification extends React.Component<
  ErrorNotificationProps,
  ErrorNotificationState
> {
  get errorStyle() {
    return {
      backgroundColor: constants.colors.errorColor
    };
  }

  render() {
    return (
      <IconTextNotification
        style={this.errorStyle}
        message={this.props.message}
        icon={iconAssets.warning}
      />
    );
  }
}
