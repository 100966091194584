//@flow
import React from "react";
import { StyleSheet, Text, View, ScrollView } from "react-native";
import data from "../../data";
import { Tappable } from "../tappable";
import { constants, store, strings } from "cockpit-shared";
import { H5 } from "../texts";
import * as icon from "../icon";
import { Toggle } from "../toggle";
import { PushNotificationsContainer } from "../app";

export type ProfilePageNotificationToggleProps = {};
export type ProfilePageNotificationToggleState = {
  isNotificationsOn: boolean
};

class ProfilePageNotificationToggle extends React.Component<
  ProfilePageNotificationToggleProps,
  ProfilePageNotificationToggleState
> {
  constructor() {
    super();
    this.state = {
      isNotificationsOn: true
    };
  }

  componentDidMount() {
    this.loadNotificatonsSubscribtionStatus();
  }

  async loadNotificatonsSubscribtionStatus() {
    let isNotificationsOn = await PushNotificationsContainer.isSubscribed();
    this.setState({
      isNotificationsOn
    });
  }

  renderOnCircleContent() {
    return () => (
      <H5 style={{ color: constants.colors.lightBlue, fontWeight: "bold" }}>
        {data.strings.stringsRepo.notificationToggleOnLabel}
      </H5>
    );
  }

  renderOffCircleContent() {
    return () => (
      <H5 style={{ color: constants.colors.lightBlue, fontWeight: "bold" }}>
        {data.strings.stringsRepo.notificationToggleOffLabel}
      </H5>
    );
  }

  get toggleBackgroundColor() {
    return constants.colors.lightBlue;
  }

  get toggleCircleColor() {
    return constants.colors.darkAccentColor;
  }

  onToggle(isOn: boolean) {
    this.setState({
      isNotificationsOn: isOn
    }, () => {
      PushNotificationsContainer.setIsSubscribed(isOn);
    });
  }

  render() {
    return (
      <Toggle
        width={50}
        onToggle={this.onToggle.bind(this)}
        isOn={this.state.isNotificationsOn}
        animationDuration={300}
        style={{}}
        onBackgroundColor={this.toggleBackgroundColor}
        offBackgroundColor={this.toggleBackgroundColor}
        circleOnColor={this.toggleCircleColor}
        circleOffColor={this.toggleCircleColor}
        disabled={false}
        renderOnCircleContent={this.renderOnCircleContent.bind(this)}
        renderOffCircleContent={this.renderOffCircleContent.bind(this)}
      />
    );
  }
}

export default ProfilePageNotificationToggle;
