//@flow
import { BaseManager } from "../base";
import { RequestTypes } from "../request-type/RequestType";
import type { BaseManagerResponse } from "../base/BaseManagerResponse";
import ServiceError from "../error/ServiceError";
import DataSetViewModel from "./DataSetViewModel";

export default class DataSetsManager extends BaseManager {
  async getDataSets(): BaseManagerResponse {
    let url = "datasets/";
    let response: BaseManagerResponse = await this.fetchJSON(RequestTypes.GET, url);
    if (response instanceof ServiceError) {
      return response;
    }
    return response.map(dataSetItemJson => new DataSetViewModel(dataSetItemJson));
  }
}
