//@flow
import React, { useState } from "react";
import { View } from "react-native";
import { constants } from "cockpit-shared";
import { H2 } from "../../texts";
import { Tappable } from "../../tappable";
import { CategoryTab } from "./CategoryTab";
import { ReactImage } from "../../react-image/ReactImage";

export type CategoryContainerTabsProps = {
  onTabPress: (category: Object) => void,
  categoriesState: Object,
  selectedCategoryName: String
};

export const CategoryContainerTabs = (props: CategoryContainerTabsProps) => {
  const commonProps = {
    selectedTextColor: constants.colors.white,
    notSelectedTextColor: constants.colors.white,
    selectedBackgroundColor: constants.colors.w_veryDarkBlue,
    notSelectedBackgroundColor: constants.colors.w_shadedBlue,
    hoverActiveBackgroundColor: constants.colors.white,
    hoverActiveTextColor: constants.colors.w_veryDarkBlue,
  };
  const [isHoverActive, setIsHoverActive] = useState(false);

  const onMouseLeave = (e) => {
    document.getElementById("subcategory-items").classList.remove("subcategory-item-container-show");
    setIsHoverActive(false);
  };

  const onMouseEnter = (e) => {
    document.getElementById("subcategory-items").classList.add("subcategory-item-container-show");
    setIsHoverActive(true);
  };
  const isFlSelected =
    props.categoriesState.categories.length > 0 &&
    "fl" === props.categoriesState.category.id;

  return props.categoriesState.categories.length > 0 ? (
    <div className="category-tab-container">
      <div className="category-tab-width">
        <CategoryTab
          id={"fl"}
          name={props.categoriesState.categories[0].name}
          onTap={() => {
            props.onTabPress(props.categoriesState.categories[0]);
          }}
          iconUrl={props.categoriesState.categories[0].icon}
          isSelected={isFlSelected}
          title={props.selectedCategoryName}
          shouldChangeTitle={false}
          isDisabled={false}
          {...commonProps}
        />
      </div>
      {
        props.categoriesState.categories[1] ?
          <div className="category-tab-width"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={isHoverActive ? onMouseLeave : onMouseEnter}
          >
            <CategoryTab
              id={"pr"}
              name={props.categoriesState.categories[1].name}
              iconUrl={props.categoriesState.categories[1].icon}
              isDisabled={false}
              onTap={() => { }}
              title={props.selectedCategoryName !== props.categoriesState.categories[0].name
                ? isHoverActive ? props.categoriesState.categories[1].name : props.selectedCategoryName
                : props.categoriesState.categories[1].name}
              shouldChangeTitle={true}
              isSelected={
                isHoverActive ||
                props.categoriesState.categories[1].children.some(
                  (e) => e.id === props.categoriesState.category.id
                )
              }
              isHoverActive={isHoverActive}
              {...commonProps}
            />
            <div id="subcategory-items" className="subcategory-item-container">
              {props.categoriesState.categories[1].children.map(
                (subCategoryModel, index) => {
                  return (
                    <SubCategoryTabItem
                      key={subCategoryModel.name}
                      name={subCategoryModel.name}
                      onTap={() => {
                        props.onTabPress(subCategoryModel);
                      }}
                      iconUrl={subCategoryModel.icon}
                      zIndex={100 - index}
                      {...commonProps}
                    />
                  );
                }
              )}
            </div>
          </div>
          : null
      }
    </div>
  ) : null;
};

const SubCategoryTabItem = (props) => {
  const [isHoverActive, setIsHoverActive] = useState(false);
  const textColor = isHoverActive
    ? constants.colors.white
    : constants.colors.black;
  return (
    <View
      style={{ zIndex: props.zIndex, height: 64 }}
      onMouseEnter={() => setIsHoverActive(true)}
      onMouseLeave={() => setIsHoverActive(false)}
      key={props.iconUrl}
    >
      <Tappable onTap={() => props.onTap(props.id)}>
        <View>
          <View
            style={{
              height: 64,
              backgroundColor: isHoverActive
                ? constants.colors.w_blue
                : constants.colors.white,
              alignItems: "center",
              flexDirection: "row",
              paddingLeft: 20,
              ...props.style,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            }}
          >
            <ReactImage
              iconUrl={props.iconUrl}
              isHoverActive={isHoverActive} />
            <H2 style={{ marginStart: 12, color: textColor }}>{props.name}</H2>
          </View>
        </View>
      </Tappable>
    </View>
  );
};
