//@flow
import type { entityJsonType } from "../entity/EntityViewModel";
import type { invertedJsonType } from "../inverted/InvertedViewModel";
import EntityViewModel from "../entity/EntityViewModel";
import { chartValueFormatter as formatter } from "../chart-value-formatter";
import { InvertedViewModel } from "../inverted";
import ChartsAvailability from "./ChartsAvailability";

type chartDataJsonType = {
  fields: invertedJsonType & {
    act_is_m: Array<number>,
    bu_abs: number,
    bu_abs_m: Array<number>,
    bu_perc: number,
    bu_perc_m: Array<number>,
    pre_year_abs: number,
    prea_year_perc: number,
    vj_abs_m: Array<number>,
    vj_perc_m: Array<number>,
    ytd: number,
    ytd_label: string,
    chart_data: string
  },
  children: Array<entityJsonType>,
};

export default class ChartDataViewModel {
  json: chartDataJsonType;
  subEntities: Array<EntityViewModel>;
  budgetMonthsPercentageFormatted: Array<string>;
  previousYearMonthsPercentageFormatted: Array<string>;
  budgetMonthsAbsoluteFormatted: Array<string>;
  previousYearMonthsAbsoluteFormatted: Array<string>;
  actMonthsAbsoluteFormatted: Array<string>;
  invertedStatus: InvertedViewModel;
  chartsAvailability: ChartsAvailability;

  constructor(json: chartDataJsonType) {
    this.json = json;
    this.subEntities = [];
    this.invertedStatus = new InvertedViewModel(this.json.fields);
    for (var jsonEntity of this.json.children) {
      this.subEntities.push(new EntityViewModel(jsonEntity));
    }
    this._formatValues();
    this.chartsAvailability = new ChartsAvailability(this.json.fields.chart_data);
  }

  _formatValues() {
    let formatAbsolute = value => formatter.formatChartValue(formatter.validateValue(value), false);
    let formatPercentage = value =>
      formatter.formatChartValue(formatter.validateValue(value), true);
    this.budgetMonthsPercentageFormatted = this.budgetMonthsPercentage.map(formatPercentage);
    this.previousYearMonthsPercentageFormatted = this.previousYearMonthsPercentage.map(
      formatPercentage
    );
    this.budgetMonthsAbsoluteFormatted = this.budgetMonthsAbsolute.map(formatAbsolute);
    this.previousYearMonthsAbsoluteFormatted = this.previousYearMonthsAbsolute.map(formatAbsolute);
    this.actMonthsAbsoluteFormatted = this.actMonthsAbsolute.map(formatAbsolute);
  }

  get _fields() {
    return this.json.fields;
  }

  get entitiesNames(): Array<string> {
    return this.subEntities.map(entity => entity.name);
  }

  entitiesBudget(isPercentage: boolean): Array<number> {
    return this.subEntities.map(entity => entity.budget(isPercentage) || 0);
  }

  entitiesBudgetFormatted(isPercentage: boolean): Array<string> {
    return this.subEntities.map(entity => entity.budgetFormatted(isPercentage));
  }

  entitiesPreviousYear(isPercentage: boolean): Array<number> {
    return this.subEntities.map(entity => entity.previousYear(isPercentage) || 0);
  }

  entitiesPreviousYearFormatted(isPercentage: boolean): Array<string> {
    return this.subEntities.map(entity => entity.previousYearFormatted(isPercentage));
  }

  entitiesIst(): Array<?number> {
    return this.subEntities.map(entity => entity.ytd);
  }

  entitiesIstFormatted(): Array<string> {
    return this.subEntities.map(entity => entity.ytdFormatted());
  }

  get budgetAbsolute() {
    return this._fields.bu_abs;
  }

  get budgetPercentage() {
    return this._fields.bu_perc;
  }

  get previousYearAbsolute() {
    return this._fields.pre_year_abs;
  }

  get previousYearPercentage() {
    return this._fields.pre_year_perc;
  }

  get budgetMonthsPercentage() {
    return this._fields.bu_perc_m;
  }

  get budgetMonthsAbsolute() {
    return this._fields.bu_abs_m;
  }

  get previousYearMonthsPercentage() {
    return this._fields.vj_perc_m;
  }

  get previousYearMonthsAbsolute() {
    return this._fields.vj_abs_m;
  }

  get actMonthsAbsolute() {
    return formatter.prepareValuesForChart(this._fields.act_is_m);
  }

  actMonthsFormatted() {
    return this.actMonthsAbsoluteFormatted;
  }

  get ytd() {
    return this._fields.ytd;
  }

  get ytdFormatted() {
    return formatter.formatTagValue(formatter.validateValue(this.ytd), false);
  }

  budgetMonths(isPercentage: boolean) {
    return formatter.prepareValuesForChart(isPercentage ? this.budgetMonthsPercentage : this.budgetMonthsAbsolute);
  }

  budgetMonthsFormatted(isPercentage: boolean) {
    return isPercentage ? this.budgetMonthsPercentageFormatted : this.budgetMonthsAbsoluteFormatted;
  }

  previousYearMonths(isPercentage: boolean) {
    return formatter.prepareValuesForChart(isPercentage ? this.previousYearMonthsPercentage : this.previousYearMonthsAbsolute);
  }

  previousYearMonthsFormatted(isPercentage: boolean) {
    return isPercentage
      ? this.previousYearMonthsPercentageFormatted
      : this.previousYearMonthsAbsoluteFormatted;
  }

  budget(isPercentage: boolean) {
    return isPercentage ? this.budgetPercentage : this.budgetAbsolute;
  }

  budgetFormatted(isPercentage: boolean) {
    return formatter.formatTagValue(
      formatter.validateValue(this.budget(isPercentage)),
      isPercentage
    );
  }

  previousYear(isPercentage: boolean) {
    return isPercentage ? this.previousYearPercentage : this.previousYearAbsolute;
  }

  previousYearFormatted(isPercentage: boolean) {
    return formatter.formatTagValue(
      formatter.validateValue(this.previousYear(isPercentage)),
      isPercentage
    );
  }
}
