//@flow
import * as React from "react";
import "../charts.css";
import { View, PixelRatio } from "react-native";
import * as icon from "../../icon";
import { constants, strings } from "cockpit-shared";
import { H3 } from "../../texts";
import { Tappable } from "../../tappable";
import { connect } from "react-redux";
import { colors } from "cockpit-shared/src/constants";


export type ChartsColumnsHeaderProps = {
  onColumnTap?: (index: number) => void,
  isTappable?: boolean,
  isDrillUpAvailable?: boolean,
  onDrillUpTap?: () => void,
  subEntities: Array<Object>,
  minimumNumberOfCollumns: number,
  noChildrenPopup: () => void,
  disabled: boolean,
  onLayout: Function,
  yAxisPosition: ?"left" | "right",
  columnWidthInEms: ?number,
  width: ?string,
  paddingLeft: ?number
};
let headerHeight = 35;
let leftAxisMargins = [17 + 1 / PixelRatio.get(), 45, 14]
let rightAxisMargins = [10, 0, 33 + 1 / PixelRatio.get()]

export function computeSubEntitiesWidth(subEntities: Array<Object>, defaultEms: number): number {
  if (!subEntities) {
    return 0;
  }
  let nameSizes = subEntities.map((s) => { return s.name.length; });
  var ems = 0;
  if (defaultEms) {
    //Column width is set by a prop
    ems = Math.min(16, Math.max(0, defaultEms));
  } else {
    //We compute the column width
    ems = Math.min(14, Math.max(0, ...nameSizes));
  }
  return ems;
}

type ChartsColumnsHeaderState = {};

export let chartColumnsStyle = {
  height: headerHeight,
  backgroundColor: constants.colors.w_grey,
  flexDirection: "row",
  alignItems: "center",
  alignSelf: "flex-start",
  justifyContent: "space-between"
};

class ChartsColumnsHeader extends React.Component<
  ChartsColumnsHeaderProps,
  ChartsColumnsHeaderState
  > {
  get subEntityFontSize() {
    return 14;
  }

  /**
   * This is related to yAxisPosition
   * We adjust the margins to keep subentities vertically
   * aligned with the chart bars.
   */
  get specificMargins(): Array<number> {
    return this.props.yAxisPosition === "right" ? rightAxisMargins : leftAxisMargins;
  }

  render() {
    let subEntities = this.props.subEntities;
    //Add empty column headers to match the number of the bars
    let emptyColumnHeadersNumber =
      Math.max(
        this.props.minimumNumberOfCollumns - subEntities.length,
        0
      ) || 0;
    let emptyColumnHeaders = [];
    let fakeCols = false;
    for (let i = 0; i < emptyColumnHeadersNumber; i++) {
      emptyColumnHeaders.push(" ");
      fakeCols = true;
    }
    //We use a word called "stretcher" to give all the entities the same size in ems
    //Because RN does not have ems as a valable size
    var ems = computeSubEntitiesWidth(subEntities, this.props.columnWidthInEms);
    let stretcher = "m".repeat(ems);
    return (
      <View
        onLayout={(e) => { this.props.onLayout ? this.props.onLayout(e) : null; }}
        style={[chartColumnsStyle, { width: this.props.width }]}
      >
        {this.props.isDrillUpAvailable ? (
          <div className="drillup-back-arrow">
            <Tappable
              onTap={this.props.onDrillUpTap}
              style={{
                alignItems: "center",
                justifyContent: "center",
                height: headerHeight,
                width: this.specificMargins[0]
              }}
            >
              {/**This probably will be deleted when organization drilldown menu is done APP-313*/}
              <icon.Icon
                name={icon.iconAssets.arrowBack}
                color={constants.colors.w_veryDarkBlue}
                size={icon.iconSizes.NANO}
                style={{
                  paddingLeft: 5
                }}
              />
            </Tappable>
          </div>
        ) : (
          <View
            style={{
              width: this.specificMargins[0]
            }}
          />
        )}

        <View
          style={{
            height: headerHeight,
            flexDirection: "row",
            flex: 1,
            alignItems: "stretch",
            alignSelf: "flex-start",
            display: "flex",
            paddingLeft: (this.props.paddingLeft || this.specificMargins[1]) + (this.props.isDrillUpAvailable || this.props.width > 1235 ? 17 : 0)
          }}
        >
          {subEntities.map((subEntity, index) => {
            return (
              <Tappable
                key={index}
                isDisabled={this.props.disabled}
                onTap={() => {
                  if (!subEntity.hasChildren) {
                    this.props.noChildrenPopup();
                    return;
                  }
                  this.props.onColumnTap != null &&
                    this.props.onColumnTap(index);
                }}
                containerStyle={{
                  flex: fakeCols ? 1 : 1,
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  height: headerHeight
                }}
                style={{
                  height: headerHeight,
                  alignItems: "center",
                  justifyContent: "center",
                  alignSelf: "center"
                }}
              >
                <H3
                  style={{
                    color: constants.colors.w_veryDarkBlue,
                    fontSize: this.subEntityFontSize,
                    fontWeight: "bold"
                  }}
                  numberOfLines={1}
                >
                  {subEntity.name}
                </H3>
                {
                  //Render the stretcher word
                }
                <H3
                  style={{
                    color: constants.colors.transparent,
                    fontSize: this.subEntityFontSize,
                    height: 1
                  }}
                  numberOfLines={1}>{stretcher}</H3>
              </Tappable>
            );
          })}

          {emptyColumnHeaders.map((title, index) => {
            return (
              <View
                key={index + 50 + "a"}
                style={{
                  flex: fakeCols ? 1 : 0,
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  height: headerHeight,
                }}
              >
                {
                  //Render the stretcher word
                }
                <H3
                  key={index + emptyColumnHeadersNumber + 1}
                  style={{
                    color: colors.transparent,
                    fontSize: this.subEntityFontSize,
                    fontWeight: "bold"
                  }}
                  numberOfLines={1}
                >{stretcher}</H3>
              </View>
            );
          })}
        </View>
      </View>
    );
  }
}
let mapStateToProps = (state, props) => {
  return {};
};
let mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch,
    noChildrenPopup: () => alert(strings.stringsRepo.deepestLevelReached)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChartsColumnsHeader);
