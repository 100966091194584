//@flow
import data from "../../../../data";

export default function getConfig() {
  return {
    lineLength: 2,
    spaceLength: 4,
    phase: data.constants.PlatformOS.iOS ? 10 : 30 //I don't think we'll use this in bubbleCharts
  };
}
