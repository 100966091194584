//@flow
import { PlatformOS } from "./Platform";
import { ScreenSize } from "./ScreenSize";

let DeviceType = {
  IS_PHONE: false,
  IS_SMARTPHONE: false,
  IS_IPHONE: false,
  IS_IPHONE_4_OR_LESS: false,
  IS_IPHONE_5_OR_LESS: false,
  IS_IPHONE_6: false,
  IS_IPHONE_6P: false,
  IS_IPAD_OR_TABLET: true,
  IS_IPHONE_X: false
};

let Device = {
  DeviceType,
  Locale: "en" //TODO
};

export { Device };
