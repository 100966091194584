//@flow
import * as React from "react";
import { connect } from "react-redux";
import { View } from "react-native";
import EntitiesRankingPage from "../../entity-ranking/EntitiesRankingPage";
import NoDataContainer from "../../no-data/NoDataContainer";
import { RankViewModel } from "cockpit-shared/src/data/rank";
import { ChartDataViewModel } from "cockpit-shared/src/data/chart-data";
import CommonChartHeader from "../charts/common/CommonChartHeader";

export type EntitiesRankingContainerProps = {
  subCategoryIconUrl: string,
  entityName: string,
  rankings: Array<RankViewModel>,
  isLoading: boolean,
  year: ?number,
  chartsReducerData: ChartDataViewModel,
  onColumnTap: (columnIndex: number) => void,
  isDrillUpAvailable: Boolean,
  onDrillUpTap: () => void,
};

export type EntitiesRankingContainerState = {};

//STRUCTURE CHART
class EntitiesRankingContainer extends React.Component<
  EntitiesRankingContainerProps,
  EntitiesRankingContainerState
  > {

  shouldComponentUpdate(props1, props2) {
    return true;
  }

  get chartsReducerData(): ChartDataViewModel {
    return this.props.chartsReducerData;
  }

  get isColorInverted() {
    if (!this.chartsReducerData || !this.chartsReducerData.invertedStatus) {
      return false;
    }
    let result = this.chartsReducerData.invertedStatus.isRankingChartColorInverted();
    return result;
  }

  get isPercentage() {
    return true;
  }

  get chartsReducer() {
    return this.props.chartsReducer;
  }

  render() {
    let noData = this.props.rankings.length === 0;
    return (
      this.props.isLoading ? null : (
        <View style={{ flex: 1, backgroundColor: "white", paddingHorizontal: 12 }}>
          <CommonChartHeader
            chartsReducer={this.chartsReducer}
            isPercentage={this.isPercentage}
          />
          <NoDataContainer noData={noData}>
            <EntitiesRankingPage
              categories={this.props.rankings}
              isColorInverted={this.isColorInverted}
            />
          </NoDataContainer>
        </View>
      )
    );
  }
}

function mapStateToProps(state, props) {
  return {
    subCategoryIconUrl: state.categoriesStore.category.icon,
    isLoading: state.chartsStore.isLoading,
    rankings: state.entitiesRankingStore.rankings || [],
    chartsReducerData: state.chartsStore.data,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EntitiesRankingContainer);
