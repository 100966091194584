//@flow

export const loginPageActionTypes = {
  LOGIN: "LOGIN",
  ON_EMAIL_CHANGE: "ON_EMAIL_CHANGE",
  ON_PASSWORD_CHANGE: "ON_PASSWORD_CHANGE",
  ON_EMAIL_CHECK_RESULT: "ON_EMAIL_CHECK_RESULT",
  ON_PASSWORD_CHECK_RESULT: "ON_PASSWORD_CHECK_RESULT",
};
export type LoginPageActionType = $Keys<typeof loginPageActionTypes>;
