//@flow
import React from "react";
import { View } from "react-native";
import { Background } from "../background";
import { LoginHeader } from "../navigation-header";
import { iconAssets, Icon, iconSizes } from "../icon";
import data from "../../data";
import { connect } from "react-redux";
import { Tappable } from "../tappable";
import { constants, store, strings } from "cockpit-shared";
import { H1, H2, H3, AppVersionText } from "../texts";
import ProfilePageNotificationToggle from "./ProfilePageNotificationToggle";
import { ScreenSize } from "../../data/constants";

export type ProfilePageProps = {
  onLogoutTap: () => void,
  onCloseTap: () => void,
  displayName: string,
  navigateToChangePassword: Function,
};
export type ProfilePageState = {};

class ProfilePage extends React.Component<ProfilePageProps, ProfilePageState> {
  get username() {
    let usrName = this.props.displayName
      ? this.props.displayName.toUpperCase()
      : "";
    return `${usrName},`;
  }

  renderReceiveNotificationsToggle() {
    return () => (
      <View>
        <ProfilePageNotificationToggle />
      </View>
    );
  }

  render() {
    return (
      <Background>
        <LoginHeader
          isGradientBackgroundVisible={false}
          iconName={iconAssets.question}
          rightViewStyle={{ maxWidth: "100%" }}
          rightView={() => null}
          optionsView={() => (
            <ProfilePageNavigationBarOptionsView
              onLogOutTap={this.props.onLogoutTap}
              onCloseTap={this.props.onCloseTap}
            />
          )}
        />
        <ProfilePageUserInfoContainer onLogOutTap={this.props.onLogoutTap}>
          {this.props.displayName}
        </ProfilePageUserInfoContainer>
        <ProfilePageSettingsCategoryHeader>
          {data.strings.stringsRepo.settings}
        </ProfilePageSettingsCategoryHeader>
        <View
          style={{
            flex: 1,
            justifyContent: "flex-end",
            flexDirection: "column",
            paddingBottom: ScreenSize.IPHONE_INDICATOR_SIZE,
          }}
        >
          <AppVersionText />
        </View>
      </Background>
    );
  }
}

/*
  Change password feature was removed, check APP-236 JIRA Task.

   <ProfilePageOption onTap={this.props.navigateToChangePassword}>
    {data.strings.stringsRepo.changePassword}
  </ProfilePageOption>
*/

function matpStateToPros(state) {
  let authStore = state.authenticationStore;
  return {
    displayName: authStore.firstName + " " + authStore.lastName,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onLogoutTap: () => {
      let onConfirm = () => {
        dispatch(store.authentication.authenticationActions.logout());
        // Navigate to login
      };
      if (window.confirm(strings.stringsRepo.logoutConfirmQuestion)) {
        onConfirm();
      }
    },
    onCloseTap: () => ownProps.navigation.goBack(),
    navigateToChangePassword: () => { }
  };
}
export default connect(matpStateToPros, mapDispatchToProps)(ProfilePage);

const ProfilePageNavigationBarOptionsView = (props) => {
  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        height: 30,
      }}
    >
      <Tappable onTap={props.onCloseTap}>
        <Icon
          name={iconAssets.close}
          color={constants.colors.white}
          size={iconSizes.MEDIUM}
        />
      </Tappable>
    </View>
  );
};

const ProfilePageSettingsCategoryHeader = (props) => {
  return (
    <View
      style={{
        padding: 24,
      }}
    >
      <H3 style={{ color: "white" }}>{props.children}</H3>
    </View>
  );
};

const ProfilePageUserInfoContainer = (props: {
  children: string,
  onLogOutTap: Function,
}) => {
  return (
    <View
      style={{
        padding: constants.dimensions.bigMargin,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <View>
        <H2 style={{ color: "white" }}>
          {data.strings.stringsRepo.profileWelcomeMessage}
        </H2>
        <H1 style={{ color: "white", fontWeight: "bold" }}>{props.children}</H1>
      </View>
    </View>
  );
};

/*
  Log out feature was remved, check APP-236 JIRA Task.

  <Tappable onTap={props.onLogOutTap}>
    <Icon
      name={iconAssets.logout}
      color={constants.colors.lightBlue}
      size={iconSizes.MICRO}
    />
  </Tappable>
*/
