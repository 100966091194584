//@flow
import NumeralNumberFormattingService from "./NumeralNumberFormattingService";

export default class LocalizedNumberFormatterProvider {
  getFormatterFunc: ?() => NumeralNumberFormattingService;

  constructor() {
    this.getFormatterFunc = null;
  }

  setNumberFormatter(getFormatter: Function) {
    this.getFormatterFunc = getFormatter;
  }

  getNumberFormatter(): NumeralNumberFormattingService {
    return this.getFormatterFunc ? this.getFormatterFunc() : null;
  }
}

export const localizedNumberFormatterInstance = new LocalizedNumberFormatterProvider();
