//@flow
import { BaseManager } from "../base";
import { RequestTypes } from "../request-type/RequestType";
import type { BaseManagerResponse } from "../base/BaseManagerResponse";
import ServiceError from "../error/ServiceError";
import ChartDataViewModel from "../chart-data/ChartDataViewModel";

export default class ChartsManager extends BaseManager {
  async getChartData(
    dataSetId: string,
    category: string,
    entityId: string,
    dimensionId: string
  ): BaseManagerResponse {
    let url = `${dataSetId}/categories/${category}/entities/${entityId}/dimensions/${dimensionId}/chart-data/`;
    let response: BaseManagerResponse = await this.fetchJSON(RequestTypes.GET, url);
    if(response instanceof ServiceError) {
        return response;
    }
    return new ChartDataViewModel(response);
  }
}
