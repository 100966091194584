//@flow
import type { BaseAction } from "../base/BaseActions";
import type { OrganizationsActionType } from "./OrganizationsActionTypes";
import { organizationsActionTypes } from "./OrganizationsActionTypes";
import { baseReducerHelper } from "../base";
import data from "../../data";
import { commonActionHandler } from "../base/BaseActions";

export type OrganizationReducerState = {
  isLoading: boolean,
  organizations: Array<data.entity.OrganizationEntityModel>,
  selectedOrganization: ?data.entity.OrganizationEntityModel
};

export type OrgnizationsAction = BaseAction & {
  type: OrganizationsActionType
};

export let defaultState: OrganizationReducerState = {
  isLoading: false,
  organizations: [],
  selectedOrganization: { name: "", id: "", children: [] }
};

export const organizationsReducer = baseReducerHelper.createReducer(defaultState, {
  [organizationsActionTypes.LOAD_ORGANIZATIONS]: commonActionHandler,
  [organizationsActionTypes.SELECT_ORGANIZATION]: commonActionHandler,
  [organizationsActionTypes.CLEAR_ORGANIZATIONS]: commonActionHandler,
});
