//@flow

type OnNewCheckResult = (isValid: boolean) => void;

const DEFAULT_CHECK_FREQ_IN_MILLIS = 3000;

export default class TextFieldChecker {
  timerId: any;
  checkFrequencyInMillis: number;

  constructor() {
    this.timerId = null;
    this.checkFrequencyInMillis = DEFAULT_CHECK_FREQ_IN_MILLIS;
  }

  isValidValue(value: ?string, regExp: ?RegExp) {
    if (regExp == null) {
      return true;
    }
    if (value == null) {
      return false;
    }
    let isValid = regExp.test(value);
    return isValid;
  }

  validateValue(value: string, regEx: RegExp) {
    let isValid = this.isValidValue(value, regEx);
    return isValid;
  }

  cancelTimer() {
    clearTimeout(this.timerId);
  }

  async scheduleNewTimer(value: string, regEx: RegExp) {
    let promiveResolver = (resolve, reject) => {
      this.timerId = setTimeout(() => {
        resolve(this.validateValue(value, regEx));
      }, this.checkFrequencyInMillis);
    };
    return new Promise(promiveResolver.bind(this));
  }

  willUnmount() {
    this.cancelTimer();
  }

  async onValueChanged(newValue: string, shouldCheckNow?: boolean, regEx: RegExp) {
    this.cancelTimer();
    if (shouldCheckNow) {
      return this.validateValue(newValue, regEx);
    } else {
      return this.scheduleNewTimer(newValue, regEx);
    }
  }
}
