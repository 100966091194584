//@flow
import type { BaseComponentProps } from "../base-component";
import * as React from "react";
import { Text, View, ScrollView, Image, TextInput } from "react-native";
import TextFieldChecker from "./TextFieldChecker";
import { H2Style } from "../texts";
import { propNotifier } from "../prop-notifier";

export const returnKeyTypes = {
  done: "done",
  go: "go",
  next: "next",
  search: "search",
  send: "send"
};
export type ReturnKeyType = $Keys<typeof returnKeyTypes>;

export const keyboardTypes = {
  default: "default",
  numeric: "numeric",
  emailAddress: "email-address",
  phonePad: "phone-pad"
};
export type KeyboardType = $Keys<typeof keyboardTypes>;

export const autoCapitalizeTypes = {
  characters: "characters",
  words: "words",
  sentences: "sentences",
  none: "none"
};
export type AutoCapitalizeType = $Keys<typeof autoCapitalizeTypes>;

export type TextFieldProps = BaseComponentProps & {
  isValidValue: ?boolean,
  editable?: boolean,
  isPassword?: boolean,
  dismissKeyboardOnSubmit?: boolean,
  currentValue: string,
  placeholder?: string,
  placeHolderTextColor?: string,
  initialTextValue?: ?string,
  selectionColor?: string,
  style?: any,
  onErrorStyle?: any,
  keyboardType?: KeyboardType,
  returnKeyType?: ReturnKeyType,
  autoCapitalize?: AutoCapitalizeType,
  onFocusChanged?: (isFocused: boolean) => void,
  onChangeText?: (newText: string) => void,
  onSubmit?: () => void,
  onErrorCheckResult?: (isValid: boolean) => void
};
export type TextFieldState = {};
export default class TextField extends React.Component<TextFieldProps, TextFieldState> {
  textFieldChecker: TextFieldChecker;

  constructor(props: TextFieldProps) {
    super(props);
    this.textFieldChecker = new TextFieldChecker();
  }

  get style() {
    return this.props.style || {};
  }

  get onErrorStyle() {
    return this.props.onErrorStyle || {};
  }

  get textStyle() {
    return {
      paddingBottom: 0,
      ...H2Style,
      ...this.style,
      ...(this.props.isValidValue ? {} : this.onErrorStyle),
      outline: "none",
    };
  }

  get placeholder() {
    return this.props.placeholder || "";
  }

  get returnKeyType() {
    return this.props.returnKeyType || returnKeyTypes.done;
  }

  get editable() {
    return this.props.editable;
  }

  get placeHolderTextColor() {
    return this.props.placeHolderTextColor || "white";
  }

  get currentValue() {
    return this.props.currentValue;
  }

  get isPassword() {
    return this.props.isPassword || false;
  }

  get keyboardType() {
    return this.props.keyboardType || keyboardTypes.default;
  }

  get autoCapitalize() {
    return this.props.autoCapitalize || autoCapitalizeTypes.none;
  }

  get selectionColor() {
    return this.props.selectionColor || "black";
  }

  get dismissKeyboardOnSubmit() {
    return this.props.dismissKeyboardOnSubmit || true;
  }

  get regEx() {
    return this.props.regEx;
  }

  async onChangeText(newText: string) {
    propNotifier.safeNotify(this.props.onChangeText, newText);
  }

  onFocusChanged(isFocused: boolean) {
    propNotifier.safeNotify(this.props.onFocusChanged, isFocused);
  }

  onSubmit() {
    propNotifier.safeNotify(this.props.onSubmit);
  }

  render() {
    return (
      <TextInput
        style={this.textStyle}
        placeholder={this.props.placeholder}
        returnKeyType={this.returnKeyType}
        editable={this.editable}
        value={this.currentValue}
        underlineColorAndroid={"transparent"}
        placeholderTextColor={this.placeHolderTextColor}
        onChangeText={this.onChangeText.bind(this)}
        secureTextEntry={this.isPassword}
        keyboardType={this.keyboardType}
        onBlur={() => this.onFocusChanged(false)}
        onFocus={() => this.onFocusChanged(true)}
        autoCorrect={false}
        autoCapitalize={this.autoCapitalize}
        onSubmitEditing={this.onSubmit.bind(this)}
        blurOnSubmit={this.dismissKeyboardOnSubmit}
        selectionColor={this.selectionColor}
      />
    );
  }
}
