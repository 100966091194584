//@flow
import * as React from "react";
import { View } from "react-native";
import { store } from "cockpit-shared";
import EntityChartCommonTabContent, { addCommonProps, isGoodColorPredicate } from "./EntityChartCommonTabContent";
import { getRootStore } from "../../app";
import { InvertedViewModel } from "cockpit-shared/src/data/inverted";
import { ChartReducerType } from "../SelectedTabContent";

export type EntityChartTabContentProps = {
  chartsReducer: ChartReducerType,
  isLoading: boolean,
  currentEntityName: string,
  selectOrganization: (orgnization: Object) => void,
  onColumnTap: (columnIndex: number) => void,
  onDrillUpTap?: () => void,
  isDrillUpAvailable?: boolean,
};
export type EntityChartTabContentState = {};
export default class EntityChartTabContent extends React.Component<
  EntityChartTabContentProps,
  EntityChartTabContentState
  > {

  get subEntities() {
    if (this.props.chartsReducer.data == null) {
      return [];
    }
    return this.props.chartsReducer.data.subEntities;
  }

  get isPercentage() {
    return this.chartsReducer.formatType === store.charts.dataFormats.dataFormatTypes.percentage;
  }

  get chartsReducer() {
    return this.props.chartsReducer;
  }

  get budgetValues() {
    if (!this.chartsReducer.data) {
      return [];
    }
    return this.chartsReducer.data.entitiesBudget(this.isPercentage);
  }

  get budgetValuesFormatted() {
    if (!this.chartsReducer.data) {
      return [];
    }
    return this.chartsReducer.data.entitiesBudgetFormatted(this.isPercentage);
  }

  get vorjahrValues() {
    if (!this.chartsReducer.data) {
      return [];
    }
    return this.chartsReducer.data.entitiesPreviousYear(this.isPercentage);
  }

  get vorjahrValuesFormatted() {
    if (!this.chartsReducer.data) {
      return [];
    }
    return this.chartsReducer.data.entitiesPreviousYearFormatted(this.isPercentage);
  }

  get istValues() {
    if (!this.chartsReducer.data) {
      return [];
    }
    return this.chartsReducer.data.entitiesIst();
  }

  get istValuesFormatted() {
    if (!this.chartsReducer.data) {
      return [];
    }
    return this.chartsReducer.data.entitiesIstFormatted();
  }

  get invertedStatus(): InvertedViewModel {
    if (!this.chartsReducer.data) {
      return new InvertedViewModel({});
    }
    return this.chartsReducer.data.invertedStatus;
  }

  get istChartInverted() {
    return this.invertedStatus.isBarChartActualYearInverted();
  }

  get vorjahrChartInverted() {
    return this.invertedStatus.isBarChartDeviationInverted(this.isPercentage);
  }

  get budgetChartInverted() {
    return this.invertedStatus.isBarChartDeviationInverted(this.isPercentage);
  }

  get isPercentage() {
    return this.chartsReducer.formatType === store.charts.dataFormats.dataFormatTypes.percentage;
  }

  budgetValueColorPredicate(value: number, index: number, isInverted: boolean) {
    // We always use the absolute value. The "value" parameter might be a percentage.
    let absoluteValue = this.props.chartsReducer.data.entitiesBudget(false)[index];
    return isGoodColorPredicate(absoluteValue, isInverted);
  }

  vorjahrValueColorPredicate(value: number, index: number, isInverted: boolean) {
    // We always use the absolute value. The "value" parameter might be a percentage.
    let absoluteValue = this.props.chartsReducer.data.entitiesPreviousYear(false)[index];
    return isGoodColorPredicate(absoluteValue, isInverted);
  }

  render() {
    return (
      <View style={{ flex: 1, paddingHorizontal: 12 }}>
        <EntityChartCommonTabContent
          {...addCommonProps.bind(this)()}
          istValueColorPredicate={() => true}
          onColumnTap={this.props.onColumnTap}
          onDrillUpTap={this.props.onDrillUpTap}
          isDrillUpAvailable={this.props.isDrillUpAvailable}
          minimumNumberOfCollumns={13}
          istChartInverted={this.istChartInverted}
          vorjahrChartInverted={this.vorjahrChartInverted}
          budgetChartInverted={this.budgetChartInverted}
          isHistory={false}
        />
      </View>
    );
  }
}
