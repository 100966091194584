//@flow
import type { BaseComponentProps } from "../../base-component";
import * as React from "react";
import { Text, View, ScrollView, Image } from "react-native";
import InAppNotificationCore from "./InAppNotificationCore";
import InAppNotificationManager from "./InAppNotificationManager";
import { constants } from "cockpit-shared";
import { IconTextNotification } from "../notification-views";
import { connect } from "react-redux";

export type InAppNotificationContainerProps = BaseComponentProps & {
  contentView: ?any,
};
export type InAppNotificationContainerState = {
};

class InAppNotificationContainer extends React.Component<
  InAppNotificationContainerProps,
  InAppNotificationContainerState
> {
  manager: InAppNotificationManager;
  constructor(props: InAppNotificationContainerProps) {
    super(props);
    this.manager = new InAppNotificationManager();
  }
  componentDidMount() {
    this.manager.registerMessageBar(this.refs.notificationBar);
  }

  componentWillUnmount() {
    this.manager.unregisterMessageBar();
  }

  UNSAFE_componentWillReceiveProps(newProps: InAppNotificationContainerProps) {
    if(newProps.contentView !== this.props.contentView) {
      this.showNotification();
    }
  }

  async showNotification(onPress: ?() => void) {
    await this.manager.hideAlert();
    await this.manager.showAlert({
      position: "top",
      duration: 6000,
      onTapped: onPress
    });
  }

  async hideNotification() {
    return this.manager.hideAlert();
  }

  render() {
    return (
      <InAppNotificationCore ref="notificationBar">
       {this.props.contentView}
      </InAppNotificationCore>
    );
  }
}

function mapStateToProps(state) {
  return {
    contentView: state.inAppNotificationStore.contentView,
  };
}

export default connect(mapStateToProps)(InAppNotificationContainer);
