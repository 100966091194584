//@flow
import { Provider, Store } from "react-redux";
import React, { Component } from "react";
import { createRootStore } from "../../store/root/RootStore";
import { onStringsRepoUpdated } from "../../strings";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { storeProvider } from "../../store";
import FetchProvider, { fetchProviderInstance } from "../../data/base/FetchProvider";
import {
  NumeralNumberFormattingService,
  localizedNumberFormatterInstance
} from "../../data/number";
import { onTokenExpiredHandlerReceived } from "../../data/base/TokenExpiredHandler";

export type AppContainerProps = {
  fetch: typeof fetch,
  children: any,
  reducers: ?Object,
  middlewares: ?Object,
  strings: Object,
  persistConfig: Object,
  getLocalizedNumberFormatter: () => NumeralNumberFormattingService,
  getOnTokenExpiredHandler: () => Function,
};
export type AppContainerState = {
  isStoreReady: boolean
};

const SHARED_STORE_PROVIDER_SUBSCRIBER_ID = "appContainer";

var rootStore = null;

export default class AppContainer extends React.PureComponent<
  AppContainerProps,
  AppContainerState
> {
  persistor: ?Object;

  constructor(props: AppContainerProps) {
    super(props);
    this.persistor = null;
    this.state = {
      isStoreReady: false
    };
  }

  onStoreCreated(store: Object) {
    rootStore = store;
    this.setState({
      isStoreReady: true
    });
  }

  UNSAFE_componentWillReceiveProps(newProps: AppContainerProps) {
    fetchProviderInstance.setFetch(newProps.fetch);
  }

  get rootStore() {
    return rootStore;
  }

  get isStoreReady() {
    return this.state.isStoreReady;
  }

  async loadStore() {
    let createStoreProps = {
      reducers: this.props.reducers,
      middlewares: this.props.middlewares,
      persistConfig: this.props.persistConfig
    };
    await storeProvider.createStore(createStoreProps);
  }

  async loadApp() {
    await this.loadStore();
  }

  unloadApp() {
    storeProvider.unsubscribe(SHARED_STORE_PROVIDER_SUBSCRIBER_ID);
  }

  UNSAFE_componentWillMount() {
    if (rootStore == null) {
      onTokenExpiredHandlerReceived(this.props.getOnTokenExpiredHandler());
      fetchProviderInstance.setFetch(this.props.fetch);
      storeProvider.subscribe(SHARED_STORE_PROVIDER_SUBSCRIBER_ID, this.onStoreCreated.bind(this));
      onStringsRepoUpdated(this.props.strings);
      localizedNumberFormatterInstance.setNumberFormatter(this.props.getLocalizedNumberFormatter);
      this.loadApp();
    } else {
      this.setState({
        isStoreReady: true
      });
    }
  }

  componentWillUnmount() {
    this.unloadApp();
  }

  render() {
    return this.isStoreReady ? <Provider store={rootStore}>{this.props.children}</Provider> : null;
  }
}
