//@flow
import { localizedNumberFormatterInstance } from "../number";

type rankJsonType = {
  rank: number,
  name: string,
  id: string,
  ytd: number
};

export default class RankViewModel {
  json: rankJsonType;
  relativeYtd: ?number;
  ytdAbsoluteFormatted: ?string;
  relativeRank: ?number;

  constructor(json: rankJsonType) {
    this.json = json;
    this.relativeYtd = null;
    this.ytdAbsoluteFormatted = null;
    this.relativeRank = null;
    this._formatValues();
  }

  _formatValues() {
    this.ytdAbsoluteFormatted = localizedNumberFormatterInstance
      .getNumberFormatter()
      .formatRankingYtd(this.ytd);
  }

  get name() {
    return this.json.name;
  }

  get id() {
    return this.json.id;
  }

  get ytd() {
    return this.json.ytd;
  }

  get rank() {
    return this.json.rank;
  }
}