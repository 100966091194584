//@flow
import type { RootReducerState } from "../root/RootReducer";
import { dimensionsActionTypes } from "./DimensionsActionTypes";
import { createAction } from "../base/BaseActions";
import data from "../../data";

export function loadDimensions() {
  return async function (dispatch: any, getState: () => RootReducerState) {
    let loadingAction = createAction(dimensionsActionTypes.LOAD_DIMENSIONS, {
      isLoading: true
    });
    dispatch(loadingAction);
    let manager = new data.dimensions.DimensionsManager();
    let currentDataSetId = getState().dataSetStore.id;
    let currentCategoryId = getState().categoriesStore.category.id;
    let organizationsStore = getState().organizationsStore;
    let currentEntityId = organizationsStore.selectedOrganization.id;
    let response:
      | Array<data.dimension.DimensionViewModel>
      | data.error.ServiceError = await manager.getDimensions(
        currentDataSetId,
        currentCategoryId,
        currentEntityId
      );
    var actionPayload = {
      dimensions: response,
      isLoading: false
    };
    if (response instanceof data.error.ServiceError) {
      actionPayload = {
        isLoading: false
      };
    }
    await dispatch(createAction(dimensionsActionTypes.LOAD_DIMENSIONS, actionPayload));
    return response;
  };
}

export function selectDimension(newDimension: data.dimension.DimensionViewModel) {
  return async function (dispatch: any, getState: () => RootReducerState) {
    let selectAction = createAction(dimensionsActionTypes.SELECT_DIMENSION, {
      selectedDimension: newDimension
    });
    return dispatch(selectAction);
  }
}

export function selectDefaultDimension() {
  return async function(dispatch: any, getState: () => RootReducerState) {
    let dimensions = getState().dimensionsStore.dimensions;
    if (dimensions && dimensions.length > 0) {
      return dispatch(selectDimension(dimensions[0]));
    }
  };
}

export function clearDimensions() {
  return async function (dispatch: any, getState: () => RootReducerState) {
    return dispatch(createAction(dimensionsActionTypes.CLEAR_DIMENSIONS, {
      dimensions: [],
    }));
  };
}

export function resetState() {
  return async function (dispatch: any, getState: () => RootReducerState) {
    return dispatch(createAction(dimensionsActionTypes.RESET_STATE, {}));
  };
}

export function toggleSpinner(isLoading) {
  return async function (dispatch: any, getState: () => RootReducerState) {
    let loadingAction = createAction(dimensionsActionTypes.SHOW_LOADING_SPINNER, {
      isLoading: isLoading
    });
    await dispatch(loadingAction);
    return;
  };
}
