//@flow
import type { RootReducerState } from "../root/RootReducer";
import { entitiesRankingActionTypes } from "./EntitiesRankingActionTypes";
import { createAction } from "../base/BaseActions";
import data from "../../data";
import { RankViewModel } from "../../data/rank";

export function loadEntitiesRanking() {
  return async function(dispatch: any, getState: () => RootReducerState) {
    let loadingAction = createAction(entitiesRankingActionTypes.LOAD_ENTITIES_RANKING, {
      isLoading: true
    });
    dispatch(loadingAction);
    let s = getState();
    let manager = new data.entitiesRanking.EntitiesRankingManager();
    let currentDataSetId = s.dataSetStore?.id;
    let currentCategoryId = s.categoriesStore.category?.id;
    let dimensionsStore = s.dimensionsStore;
    let currentDimensionId = dimensionsStore.selectedDimension?.id;
    let currentEntityId = s.organizationsStore.selectedOrganization?.id;
    let response:
      | {ytdLabel:string, rankings: Array<RankViewModel>}
      | data.error.ServiceError = await manager.getEntitiesRanking(
      currentDataSetId,
      currentCategoryId,
      currentEntityId,
      currentDimensionId
    );
    var actionPayload = {
      rankings: response.rankings,
      isLoading: false,
      ytdLabel: response.ytdLabel
    };
    if (response instanceof data.error.ServiceError) {
      actionPayload = {
        isLoading: false
      };
    }
    dispatch(createAction(entitiesRankingActionTypes.LOAD_ENTITIES_RANKING, actionPayload));
    return response;
  };
}
