//@flow
import React, { Component } from "react";
import { View } from "react-native";
import { connect } from "react-redux";
import {  store } from "cockpit-shared";
import data from "../../data";
import { InAppNotificationContainer } from "../in-app-notification";
import { LoginPage } from "../../ui/login";
import { Switch, Route } from "react-router-dom";
import AuthAwareRoute from "../../navigation/AuthAwareRoute";
import { ChartsContainer } from "../charts-container";
import ProfilePage from "../profile/ProfilePage";
import { NotFoundPage } from "../not-found";
import "./App.css";

const APP_STORE_PROVIDER_SUBSCRIBER_ID = "appComponentStoreSubscriber";

class App extends Component<
  {
    isAuthenticated: Boolean,
  },
  {}
> {
  constructor() {
    super();
    this.networkConnectionHelper = new data.networkStatus.NetworkConnectionHelper();
    //this.appStateHelper = new data.appState.AppStateHelper();
    this.isAppLoaded = false;

    this.state = {
      //state is by default an object
      barchartData: [],
    };
  }

  async loadApp(store: Object) {
    if (this.isAppLoaded) {
      return;
    }
    this.isAppLoaded = true;
    this.networkConnectionHelper.listenToNetworkConnectionChanges();
    //this.appStateHelper.listenToAppStateChanges();
    //await this.props.initialNavigation();
  }

  async unloadApp() {
    this.networkConnectionHelper.stopListen();
    //this.appStateHelper.stopListen();
    this.isAppLoaded = false;
  }

  UNSAFE_componentWillMount() {
    store.storeProvider.subscribe(
      APP_STORE_PROVIDER_SUBSCRIBER_ID,
      this.loadApp.bind(this)
    );
  }

  UNSAFE_componentWillUnmount() {
    this.unloadApp();
  }

  render() {
    return (
      <View
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
        }}
      >
        <Switch>
          <AuthAwareRoute
            path="/datasets/:dataset/categories/:category/entities/:entity/dimensions/:dimension/chart-data"
            component={ChartsContainer}
          />
          <AuthAwareRoute
            path="/datasets/:dataset/categories/:category/entities/:entity/chart-data"
            component={ChartsContainer}
          />
          <AuthAwareRoute
            path="/datasets/:dataset/categories/:category/chart-data"
            component={(props) => (
              <ChartsContainer timestamp={new Date().toString()} {...props} />
            )}
          />
          <AuthAwareRoute path="/datasets/:dataset" component={ChartsContainer} />
          <AuthAwareRoute path="/me" component={ProfilePage} />
          <Route path="/login" component={LoginPage} />
          <AuthAwareRoute exact path="/" component={ChartsContainer} />
          <Route path="/404" component={NotFoundPage} />
          <Route component={NotFoundPage} />
        </Switch>
        <InAppNotificationContainer />
      </View>
    );
  }
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.authenticationStore.token !== null,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
