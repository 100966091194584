export default class OrganizationEntityModel {
  id: string;
  name: string;
  description: string;
  children: OrganizationEntityModel[];
  parentId: ?string;

  constructor(json: Object, parentId?: string) {
    this.id = json.id;
    this.name = json.name;
    this.description = json.description;
    this.children = json.children
      ? json.children.map(child => new OrganizationEntityModel(child, this.id))
      : [];
    this.parentId = parentId;
  }
}
