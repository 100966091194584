//@flow
import { ColorConverter } from "../data/color";

export const errorColor = "#FA8080";
export const accentColor = "#4A90E2";
export const middleBlue = "#17CFFA";
export const prettyDarkBlue = "#0f5398";
export const middleLightBlue = "#9CEAFD";
export const lightBlue = "#A3EDFF";
export const ligherBlue = "#E3EEF8";
export const naviBlue = "#2270B5";
export const darkAccentColor = "#19539e";
export const spaceDark = "#041D36";
export const loadingIndicatorBackground = ColorConverter.hexToRgba(accentColor, 0.5);
export const white = "#FFFFFF";
export const barChartColor = "#2578C4";
export const gray = "#B5B5B5";
export const bubblePositiveColor = ColorConverter.hexToRgba(barChartColor, 0.5);
export const bubbleNegativeColor = ColorConverter.hexToRgba(gray, 0.5);
export const gridDashedLineColor = "#CADBEB";
export const grayBlue = "#0f5398";
export const blackBlue = "#07355F";
export const gradientStart = "#3872b1";
export const gradientEnd = "#144AA1";
export const gradientHeaderStart = "#2271b4";
export const gradientHeaderEnd = "#216dbc";
export const chartHeaderBackgroundColor = "#E6F3FF";
export const intenseGreen = "#13D065";
export const brightGreen = "#7ED321";
export const intenseRed = "#E35353";
export const chartTagRed = "#E02020";
export const pinkRed = "#FA8080";
export const purple = "#8B689A";
export const darkPurple = "#6E3685";
export const lightPurple = "#D8CADE";
export const lightOceanBlue = "#98CEFF";
export const black = "#000000";
export const transparent = "#00000000";
export const organizationSeparator = "#1E6599";
export const darkBlue = "#002A56";
export const greenGray = "#43565B";
export const popupBackground = ColorConverter.hexToRgba(blackBlue, 0.41);
export const chartMenuBackground = "#0c1a2a";
export const darkGreen = "#10B256";
export const chartTagGreen = "#28C71B";
export const homePageSeparator = "#5a90dc";

//WEB colors
/**
 * Feel free to rename them, maybe use http://chir.ag/projects/name-that-color/#E3EEF8 to do it
 */
export const w_zeroLineColor = "#2477C3";
export const w_veryDarkBlue = "#031D35";
export const w_lightBlue = "#E3EEF8";
export const w_grey = "#E8E8E8";
export const w_lightGrey = "#F7F7F7";
export const w_categoryTabUnselectedColor = "#2A4054";
export const w_blue = "#015DA8";
export const w_greyBlue = "#EFF5FA";
export const w_shadedBlue = "#014C8A";
export const w_inactiveChartTabColor = "#2A4054"