//@flow
import type { BaseAction } from "../base/BaseActions";
import type { CategoriesActionType } from "./CategoriesActionTypes";
import { categoriesActionTypes } from "./CategoriesActionTypes";
import { baseReducerHelper } from "../base";
import CategoryModel from "../../data/category/CategoryModel";
import { createAuthenticationLogOutHandler } from "../authentication/AuthenticationReducer";
import { commonActionHandler } from "../base/BaseActions";
import { chartActionTypes } from "../charts";

export type CategoriesReducerState = {
  categories: Array<CategoryModel>,
  isLoading: boolean,
};

export type CategoriesAction = BaseAction & {
  type: CategoriesActionType
};

let defaultState: CategoriesReducerState = {
  categories: [],
  category: null,
  parentCategoryId: null,
  isLoading: false
};

export const categoriesReducer = baseReducerHelper.createAuthAwareReducer(defaultState, {
  [categoriesActionTypes.LOAD_CATEGORIES]: commonActionHandler,
  [categoriesActionTypes.SAVE_PARENT_CATEGORY]: commonActionHandler,
  [categoriesActionTypes.SELECT_CATEGORY]: (state: CategoriesReducerState, action) => {
    return {
      ...state,
      category: action.payload.category,
      //If the selected category is not a subcategory, parentCategoryId will be removed
      parentCategoryId:
        state.categories.includes(action.payload.category) ? null : state.parentCategoryId
    };
  }
});