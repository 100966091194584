//@flow
import type { LoginPageActionType } from "./LoginPageActionsType";
import { loginPageActionTypes } from "./LoginPageActionsType";
import { store } from "cockpit-shared";

export type LoginPageReducerState = {
  isLoading: boolean,
  email: string,
  password: string
};

export type LoginPageAction = {
  type: LoginPageActionType,
  payload: Object
};

let defaultState: LoginPageReducerState = {
  isLoading: false,
  email: "",
  password: "",
  isEmailValid: true,
  isPasswordValid: true
};

function commonActionHandler(state = defaultState, action: LoginPageAction) {
  return { ...state, ...action.payload };
}

export const loginPageReducer = store.base.baseReducerHelper.createAuthAwareReducer(
  defaultState,
  {
    [loginPageActionTypes.LOGIN]: commonActionHandler,
    [loginPageActionTypes.ON_EMAIL_CHANGE]: commonActionHandler,
    [loginPageActionTypes.ON_PASSWORD_CHANGE]: commonActionHandler,
    [loginPageActionTypes.ON_EMAIL_CHECK_RESULT]: commonActionHandler,
    [loginPageActionTypes.ON_PASSWORD_CHECK_RESULT]: commonActionHandler,
  }
);
