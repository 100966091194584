import { constants } from "cockpit-shared/src";
import { ChartDataViewModel } from "cockpit-shared/src/data/chart-data";

export default class DimensionTableColorHelper {
    static getCommonColor(value: ?number, isInverted: boolean, lightVersion: boolean = false): string {
        if (value === 0) {
            //0 value is always green
            return DimensionTableColorHelper.getGoodColor(false, lightVersion);
        }
        if (value === null) {
            return constants.colors.gray;
        } else if (value > 0) {
            return DimensionTableColorHelper.getGoodColor(isInverted, lightVersion);
        } else {
            return DimensionTableColorHelper.getBadColor(isInverted, lightVersion);
        }
    }

    static getBudgetTagColor(data: ChartDataViewModel, lightVersion: boolean = false) {
        if (data === null) {
            return constants.colors.naviBlue;
        }
        return DimensionTableColorHelper.getCommonColor(
            data.budgetAbsolute,
            //bubble chart is always percentage
            data.invertedStatus.isDimensionTableBuColorInverted(true),
            lightVersion
        );
    }

    static getPreviousYearTagColor(data: ChartDataViewModel, lightVersion: boolean = false) {
        if (data === null) {
            return constants.colors.naviBlue;
        }
        return DimensionTableColorHelper.getCommonColor(
            data.previousYearAbsolute,
            //bubble chart is always percentage
            data.invertedStatus.isDimensionTableVjColorInverted(true),
            lightVersion
        );
    }
    static getGoodColor(inverted: boolean = false, lightVersion: boolean = false) {
        let badColor = lightVersion ? constants.colors.chartTagRed : constants.colors.intenseRed;
        let goodColor = lightVersion ? constants.colors.chartTagGreen : constants.colors.darkGreen;
        return inverted ? badColor : goodColor;
    }

    static getBadColor(inverted: boolean = false, lightVersion: boolean = false) {
        let badColor = lightVersion ? constants.colors.chartTagRed : constants.colors.intenseRed;
        let goodColor = lightVersion ? constants.colors.chartTagGreen : constants.colors.darkGreen;
        return inverted ? goodColor : badColor;
    }
}