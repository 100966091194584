//@flow
import type { InAppNotificationCoreState } from "./InAppNotificationCore";
import * as React from "react";
import InAppNotificationCore from "./InAppNotificationCore";

export default class InAppNotificationManager {
  _currentMessageBarAlert: ?InAppNotificationCore;

  constructor() {
    this._currentMessageBarAlert = null;
  }

  registerMessageBar(messageBar: InAppNotificationCore) {
    this._currentMessageBarAlert = messageBar;
  }

  unregisterMessageBar() {
    this._currentMessageBarAlert = null;
  }

  async showAlert(newState: InAppNotificationCoreState) {
    if (this._currentMessageBarAlert == null) {
      return;
    }
    // Show the new alert if there is a new state, otherwise
    if (newState != null && this._currentMessageBarAlert) {
      // Clear current state
      this._currentMessageBarAlert.setNewState({});
      this._currentMessageBarAlert.setNewState(newState);
      this._currentMessageBarAlert.notifyAlertHiddenCallback = null;
      setTimeout(() => {
        this._currentMessageBarAlert.showMessageBarAlert();
        var durationToShow = this._currentMessageBarAlert.state.durationToShow;
        return new Promise((resolve, reject) => {
          setTimeout(resolve, durationToShow);
        });
      }, 100);
    }
  }

  async hideAlert() {
    if (this._currentMessageBarAlert != null) {
      // Get the current alert's duration to hide
      var durationToHide = this._currentMessageBarAlert.state.durationToHide;
      this._currentMessageBarAlert.hideMessageBarAlert();
      return new Promise((resolve, reject) => {
        setTimeout(resolve, durationToHide);
      });
    }
  }
}
