//@flow

import type { IconSizeType } from "./IconSize";
import type { IconAssetType } from "./assets";
import type { BaseComponentProps } from "../base-component";
import * as React from "react";
import { Text, View, ScrollView, Image } from "react-native";
import { iconSizes } from "./IconSize";
import { iconAssets } from "./assets";
import { createIconSetFromIcoMoon } from "react-native-vector-icons";
import { font as fontShared } from "cockpit-shared";
import cockpitFont from "../../fonts/cockpit.ttf"

function getFontIcon() {
  let fontConfig = fontShared.fontConfigs.cockpitConfig
  let iconFont = createIconSetFromIcoMoon(fontConfig);
  let fontFamily = fontConfig.preferences.fontPref.metadata.fontFamily
  const iconFontStyles = `@font-face {
    src: url(${cockpitFont});
    font-family: ${fontFamily};
      }`;
  const style = document.createElement("style");
  style.type = "text/css";
  if (style.styleSheet) {
    style.styleSheet.cssText = iconFontStyles;
  } else {
    style.appendChild(document.createTextNode(iconFontStyles));
  }
  document.head.appendChild(style);
  return iconFont
}

export type IconProps = BaseComponentProps & {
  name?: IconAssetType,
  size: IconSizeType,
  color?: string
};
export type IconState = {};
export default class Icon extends React.Component<IconProps, IconState> {
  get iconName() {
    return this.props.name;
  }

  get color() {
    return this.props.color || "white";
  }

  render() {
    const FontIcon = getFontIcon();
    return (
      <View style={this.props.style}>
        <FontIcon size={this.props.size} name={this.iconName} color={this.color} />
      </View>
    );
  }
}
