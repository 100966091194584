import "chartjs-plugin-datalabels"; //Do not delete -> labels won't show!!
import "chartjs-plugin-zoom"; //Do not delete -> zoom won't work!!
import "chartjs-plugin-style"; //Do not delete 
import { constants } from "cockpit-shared";
import * as chartsCommon from "../common";

//Static bubble chart configurations
const bubbleConfig = {
  fontFamily: "",
  bubbleBorderWidth: 2,
  bubbleBorderColor: "white",
  label: {
    labelTextSize: 14,
    labelFontWeight: "bold",
  },
  scaleLabel: {
    fontSize: 16,
    fontStyle: "bold",
    fontColor: constants.colors.w_veryDarkBlue
  },
  ticks: {
    fontColor: constants.colors.w_veryDarkBlue,
    tickCount: 20
  },
  shadow: {
    color: "rgba(108, 109, 110, 0.4)",
    offsetX: 0,
    offsetY: 3,
    blur: 5
  },
  zeroLineColor: constants.colors.w_zeroLineColor,
  gridLineColor: constants.colors.gridDashedLineColor,
  gridDashedLine: chartsCommon.gridDashedLineConfig()
}

//Ensures that 0 will be shown all the time
const ensureDividable = (value) => {
  return value < 0
    ? Math.round(value) % 2 === -1 ? Math.round(value) - 1 : Math.round(value)
    : Math.round(value) % 2 === 1 ? Math.round(value) + 1 : Math.round(value);
}

//Ensures that 0 will be shown all the time
const getRoundedStepSize = (axisMin, axisMax) => {
  let roundedAxisMin = ensureDividable(axisMin);
  let roundedAxisMax = ensureDividable(axisMax);
  let stepSize = (-1 * roundedAxisMin + roundedAxisMax) / bubbleConfig.ticks.tickCount;
  return ensureDividable(stepSize);
}

const getBubbleChartData = (values) => {
  let data = [];
  values.map((it) => {
    const item = {
      x: it.x,
      y: it.y,
      r: it.size,
      label: it.label,
      name: it.marker,
    }
    data.push(item);
  })
  return data;
}

export const bubbleChartData = (data) => {
  return {
    datasets: [
      {
        backgroundColor: data.length > 0 ? data[0].config.colors[0] : null,
        borderColor: bubbleConfig.bubbleBorderColor,
        borderWidth: bubbleConfig.bubbleBorderWidth,
        data: data.length > 0 ? getBubbleChartData(data[0].values) : null,
        datalabels: {
          display: true,
          labels: {
            label: {
              color: data.length > 0 ? data[0].config.valueTextColor : null
            }
          },
          font: {
            size: bubbleConfig.label.labelTextSize,
            weight: bubbleConfig.label.labelFontWeight,
          }
        },
        shadowColor: bubbleConfig.shadow.color,
        shadowOffsetX: bubbleConfig.shadow.offsetX,
        shadowOffsetY: bubbleConfig.shadow.offsetY,
        shadowBlur: bubbleConfig.shadow.blur
      },
      {
        backgroundColor: data.length > 1 ? data[1].config.colors[1] : null,
        borderColor: bubbleConfig.bubbleBorderColor,
        borderWidth: bubbleConfig.bubbleBorderWidth,
        data: data.length > 1 ? getBubbleChartData(data[1].values) : null,
        datalabels: {
          display: true,
          labels: {
            label: {
              color: data.length > 1 ? data[1].config.valueTextColor : null,
            }
          },
          font: {
            size: bubbleConfig.label.labelTextSize,
            weight: bubbleConfig.label.labelFontWeight,
          }
        },
        shadowColor: bubbleConfig.shadow.color,
        shadowOffsetX: bubbleConfig.shadow.offsetX,
        shadowOffsetY: bubbleConfig.shadow.offsetY,
        shadowBlur: bubbleConfig.shadow.blur
      }
    ]
  }
};

export const bubbleChartOptions = (xAxis, yAxis) => {
  return {
    maintainAspectRatio: false,
    animation: {
      duration: 1000,
      easing: "easeInQuad"
    },
    legend: {
      display: false
    },
    scales: {
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: yAxis.title,
          fontStyle: bubbleConfig.scaleLabel.fontStyle,
          fontSize: bubbleConfig.scaleLabel.fontSize,
          fontColor: bubbleConfig.scaleLabel.fontColor,
          padding: {
            bottom: -10
          }
        },
        ticks: {
          min: ensureDividable(yAxis.axisMinimum),
          max: ensureDividable(yAxis.axisMaximum),
          stepSize: getRoundedStepSize(yAxis.axisMinimum, yAxis.axisMaximum),
          fontColor: bubbleConfig.ticks.fontColor,
        },
        gridLines: {
          borderDash: [bubbleConfig.gridDashedLine.lineLength, bubbleConfig.gridDashedLine.spaceLength],
          color: bubbleConfig.gridLineColor,
          zeroLineColor: bubbleConfig.zeroLineColor,
        }
      }],
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: xAxis.title,
          fontStyle: bubbleConfig.scaleLabel.fontStyle,
          fontSize: bubbleConfig.scaleLabel.fontSize,
          fontColor: bubbleConfig.scaleLabel.fontColor,
          padding: {
            top: 5,
            bottom: 5
          }
          // fontFamily: bubbleConfig.fontFamily
        },
        ticks: {
          min: ensureDividable(xAxis.axisMinimum),
          max: ensureDividable(xAxis.axisMaximum),
          stepSize: getRoundedStepSize(xAxis.axisMinimum, xAxis.axisMaximum),
          fontColor: bubbleConfig.ticks.fontColor,
          // fontFamily: bubbleConfig.fontFamily
        },
        gridLines: {
          borderDash: [bubbleConfig.gridDashedLine.lineLength, bubbleConfig.gridDashedLine.spaceLength],
          color: bubbleConfig.gridLineColor,
          zeroLineColor: bubbleConfig.zeroLineColor,
        },
      }]
    },
    tooltips: {
      callbacks: {
        beforeLabel: (tooltipItem, data) => {
          return data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].name;
        },
        label: () => {
          return "";
        }
      },
      backgroundColor: constants.colors.spaceDark,
      bodyFontColor: constants.colors.white,
      bodyFontSize: 14,
      displayColors: false,
      cornerRadius: 5,
    },
    plugins: {
      datalabels: {
        display: true,
      },
      zoom: {
        // Container for pan options
        pan: {
          enabled: true,
          mode: "xy",
          rangeMin: {
            x: null,
            y: null
          },
          rangeMax: {
            x: null,
            y: null
          },
          // On category scale, factor of pan velocity
          speed: 20,
          // Minimal pan distance required before actually applying pan
          threshold: 10,
        },
        zoom: {
          enabled: true,
          drag: false,
          mode: "xy",
          rangeMin: {
            x: null,
            y: null
          },
          rangeMax: {
            x: null,
            y: null
          },
          // Speed of zoom via mouse wheel
          // (percentage of zoom on a wheel event)
          speed: 0.1,
          threshold: 2,
          // On category scale, minimal zoom level before actually applying zoom
          sensitivity: 3,
        }
      }
    }
  }
};
