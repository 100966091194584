//@flow
import React, { Component } from "react";
import { connect } from "react-redux";
import "./Contextual.css";
import { H2 } from "../texts/index";
import { Tappable } from "../tappable";
import { InfoManager } from "cockpit-shared/src/data/info";

type ContextualPageProps = {
  content: string,
  close: () => void
}

type ContextualPageState = {}

class ContextualPage extends React.Component<ContextualPageProps, ContextualPageState> {

  constructor(props) {
    super(props);
    this.state = {
      html: null
    };
  }

  onCloseClick = () => {
    this.props.close();
  }

  componentDidMount() {
    this.getHTMLFromExternamUrl();
  }

  componentWillUnmount() {
    this.setState({
      html: null
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.content !== prevProps.content) {
      this.setState({
        html: null
      });
      this.getHTMLFromExternamUrl();
    }
  }

  getHTMLFromExternamUrl() {
    let manager = new InfoManager();
    manager.getContextualContent(this.props.content.url)
      .then(resp => {
        this.setState({
          html: resp
        });
      });
  }

  render() {
    return (
      <div className="contextual-main-container">
        <div className="contextual-overlay" />
        <div className="contextual-content-container">
          <div className="contextual-header">
            <H2
              style={{
                fontSize: 16,
                lineHeight: "64px",
                color: "white"
              }}
            >
              {this.props.title}
            </H2>
            <Tappable onTap={this.onCloseClick}>
              <div className="contextual-close-icon" />
            </Tappable>
          </div>
          <div className="contextual-content">
            <div dangerouslySetInnerHTML={{ __html: this.state.html }} />
          </div>
        </div>

      </div>
    );
  }

}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch, props: ContextualPageProps) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContextualPage);
