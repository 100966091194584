//@flow
import type { BaseComponentProps } from "../base-component";
import * as React from "react";
import { StyleSheet, Text, View, ScrollView, Image } from "react-native";
import { constants } from "cockpit-shared";
import { Tappable } from "../tappable";

import { H5, H5Style } from "../texts";
import * as icon from "../icon";
import * as iconImage from "../icon-image";

type NotificationBadgeProps = {
  notificationsCount: number
};
type NotificationBadgeState = {};
export default class NotificationBadge extends React.Component<
  NotificationBadgeProps,
  NotificationBadgeState
> {
  get formattedNotificationsCount(): string {
    return this.props.notificationsCount > 99
      ? "99+"
      : this.props.notificationsCount + "";
  }

  render() {
    return (
      <RedCircle>
        <H5 style={{ alignSelf: "center", fontWeight: "bold",  color: constants.colors.chartTagRed, }}>
          {this.formattedNotificationsCount}
        </H5>
      </RedCircle>
    );
  }
}

const RedCircle = (props: any) => {
  return (
    <View
      style={{
        minWidth: 16,
        height: 16,
        padding: 2,
        borderRadius: 8,
        backgroundColor: constants.colors.white,
        justifyContent: "center"
      }}
    >
      {props.children}
    </View>
  );
};
