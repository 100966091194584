//@flow

import moment from "moment/moment";

export default class MomentDateFormattingService {
  _localeId: ?string;
  constructor(localeId: string) {
    this._localeId = null;
    this.localeId = localeId;
  }

  set localeId(newLocaleId: string) {
    let localeFormatted = "";
    ["-", "_"].reduce((localeFormatted, forbiddenCharacter) => {
      localeFormatted = newLocaleId.indexOf(forbiddenCharacter) === -1
        ? newLocaleId
        : newLocaleId.substr(0, newLocaleId.indexOf(forbiddenCharacter));
      return localeFormatted;
    });
    this._localeId = localeFormatted[0];
    try {
      moment.locale(localeFormatted[0].toLowerCase());
    } catch (e) {
      moment.locale("en");
    }
  }

  get localeId() {
    return this._localeId;
  }

  getFiscalYearMonths(month: number): Array<string> {
    let currentMonth = moment.monthsShort(month);
    let monthsList = [];
    monthsList.push(currentMonth);
    let monthsCount = 12;
    for (var i = 1; i <= monthsCount; i++) {
      let newMonth = moment.monthsShort(month + i);
      monthsList.push(newMonth);
    }
    return monthsList;
  }
}
