//@flow
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { AppNativeContainer } from "./ui/app";
import registerServiceWorker from "./registerServiceWorker";
// Generate required css
import iconFont from "react-native-vector-icons/Fonts/FontAwesome.ttf";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { NotFoundPage } from "../src/ui/not-found";

Sentry.init({
  dsn: "https://4a468719b15b4c30ae4dcaa4cef1c32a@o206117.ingest.sentry.io/5504480",
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

function FallbackComponent() {
  return NotFoundPage;
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog>
    <BrowserRouter>
      <AppNativeContainer />
    </BrowserRouter>
  </Sentry.ErrorBoundary>
);

registerServiceWorker();

function initVectorialIcons() {
  const iconFontStyles = `@font-face {
        src: url(${iconFont});
        font-family: FontAwesome;
      }`;
  // Create stylesheet
  const style = document.createElement("style");
  style.type = "text/css";
  if (style.styleSheet) {
    style.styleSheet.cssText = iconFontStyles;
  } else {
    style.appendChild(document.createTextNode(iconFontStyles));
  }
  // Inject stylesheet
  document.head.appendChild(style);
}
initVectorialIcons();
