//@flow
export const RequestTypes = {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    DELETE: "DELETE",
    PATCH: "PATCH",
}
export type RequestType = $Keys<typeof RequestTypes>;

