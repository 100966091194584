//@flow
import type { BaseComponentProps } from "../../base-component";
import * as React from "react";
import { Text, View, ScrollView, Image } from "react-native";
import { IconTextNotification } from "./index";
import { iconAssets } from "../../icon/index";
import { constants } from "cockpit-shared";

export type MessageNotificationProps = BaseComponentProps & {
  message: string,
  onTap?: Function,
};
export type MessageNotificationState = {};
export default class MessageNotification extends React.Component<
  MessageNotificationProps,
  MessageNotificationState
> {
  get messageStyle() {
    return {
      backgroundColor: constants.colors.blackBlue
    };
  }

  render() {
    return (
      <IconTextNotification
        onTap={this.props.onTap}
        style={this.messageStyle}
        message={this.props.message}
      />
    );
  }
}
