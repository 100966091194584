//@flow
import * as React from "react";
import { View } from "react-native";
import * as icon from "../../icon";
import { constants, store } from "cockpit-shared";
import data from "../../../data";
import { ChartTab } from "./ChartTab";
import ChartsAvailability from "cockpit-shared/src/data/chart-data/ChartsAvailability";
import "./ChartTab.css";

/* 
Container for the ChartTabs;
Chart type selector (Bubble, Delta, Ranking, Hystory)
*/

export type ChartContainerTabsProps = {
  onTabPress: (id: store.charts.chartTabs.ChartTabType) => void,
  selectedTabId: store.charts.chartTabs.ChartTabType,
  isLastEntityChildSelected: boolean,
  chartsAvailability: ChartsAvailability
};

export const ChartContainerTabs = (props: ChartContainerTabsProps) => {
  let availability = props.chartsAvailability || new ChartsAvailability();

  const commonContainerStyle = {
    width: "auto",
    flex: 1,
    backgroundColor: constants.colors.w_veryDarkBlue
  };

  return (
    <View
      style={{
        width: "100%",
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: constants.colors.w_veryDarkBlue,
      }}
    >
      <ChartTab
        id={store.charts.chartTabs.CHART_TABS_ID.BUBBLE}
        name={data.strings.stringsRepo.bubbleChartTabName}
        onTap={props.onTabPress}
        iconName={icon.iconAssets.bubbleChartTab}
        selectedTabId={props.selectedTabId}
        isDisabled={props.isLastEntityChildSelected || !availability.isBubbleChartAvailable()}
        containerStyle={commonContainerStyle}
        className={"chart-container-tab-icon"}
      />
      <ChartTab
        id={store.charts.chartTabs.CHART_TABS_ID.ENTITY}
        name={data.strings.stringsRepo.deltaChartTabName}
        onTap={props.onTabPress}
        iconName={icon.iconAssets.entityChartTab}
        selectedTabId={props.selectedTabId}
        isDisabled={props.isLastEntityChildSelected || !availability.isEntityChartAvailable()}
        containerStyle={commonContainerStyle}
        className={"chart-container-tab-icon"}
      />
      <ChartTab
        id={store.charts.chartTabs.CHART_TABS_ID.RANKING}
        name={data.strings.stringsRepo.rankingChartTabName}
        onTap={props.onTabPress}
        iconName={icon.iconAssets.ranking}
        selectedTabId={props.selectedTabId}
        isDisabled={props.isLastEntityChildSelected || !availability.isRankingChartAvailable()}
        containerStyle={commonContainerStyle}
        className={"chart-container-tab-icon"}
      />
      <ChartTab
        id={store.charts.chartTabs.CHART_TABS_ID.ENTITY_HISTORY}
        name={data.strings.stringsRepo.historyChartChartTabName}
        onTap={props.onTabPress}
        iconName={icon.iconAssets.entityHistoryChartTab}
        selectedTabId={props.selectedTabId}
        isDisabled={!availability.isHistoryChartAvailable()}
        containerStyle={{ ...commonContainerStyle, marginEnd: 12 }}
        className={"chart-container-tab-icon"}
      />
    </View>
  );
};
