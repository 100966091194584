//@flow
export const authenticationActionTypes = {
  AUTHENTICATE_WITH_EMAIL: "AUTHENTICATE_WITH_EMAIL",
  LOGOUT: "LOGOUT",
  AUTH_RESET_PASSWORD: "AUTH_RESET_PASSWORD",
  AUTH_CHANGE_PASSWORD: "AUTH_CHANGE_PASSWORD",
  AUTHENTICATE_WITH_MICROSOFT: "AUTHENTICATE_WITH_MICROSOFT",
};

export type AuthenticationActionType = $Keys<typeof authenticationActionTypes>;
