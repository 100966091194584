//@flow
import * as React from "react";
import { Text, View, ScrollView, Image } from "react-native";
import data from "../../../../data";
import { H3 } from "../../../texts/index";
import { constants } from "cockpit-shared";

export type BarChartNoDataContainerProps = {
  children: any,
  isDataEmpty: boolean
};
export type BarChartNoDataContainerState = {};
export default class BarChartNoDataContainer extends React.Component<
  BarChartNoDataContainerProps,
  BarChartNoDataContainerState
> {
  render() {
    return this.props.isDataEmpty ? (
      <H3
        style={{
          alignSelf: "center",
          color: constants.colors.errorColor
        }}
      >
        {data.strings.stringsRepo.barChartNoDataLabel}
      </H3>
    ) : (
      this.props?.children || null
    );
  }
}
