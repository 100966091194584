//@flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { View } from "react-native";
import { CategoryList } from "../category-list";
import { constants, store } from "cockpit-shared";
import { app as appNavigation } from "../../navigation";
import DimensionsTableItem, {
  DimensionTableHeader,
} from "./DimensionsTableItem";
import { DimensionViewModel } from "cockpit-shared/src/data/dimension";
import { RouteActions } from "../../navigation";

type DimensionsTablePageProps = {
  categories: Array<Object>,
  isPercentage: boolean,
  isLoading: boolean,
  onItemTap: (item) => void,
  onItemOptionsTap: (item) => void,
  hasChildren: boolean,
};

type DimensionsTablePageState = {};

class DimensionsTablePage extends Component<
  DimensionsTablePageProps,
  DimensionsTablePageState
> {
  constructor() {
    super();
    this.state = {};
  }

  static getDerivedStateFromProps(props, state) {
    return {
      ...state,
      isLoading: props.isLoading,
    };
  }

  render() {
    return (
      <View style={{ flex: 1, marginEnd: 0 }}>
        <CategoryList
          extraData={this.state}
          categories={this.categories}
          renderItem={this.renderItem.bind(this)}
          renderCategoryHeader={this.renderHeader.bind(this)}
          getKey={this.getKey.bind(this)}
        />
      </View>
    );
  }

  get categories() {
    return this.props.categories;
  }

  Separator(props) {
    return (
      <View
        style={{
          display: "flex",
          height: 1,
          marginStart: 12,
          backgroundColor: "#e7e8e7",
          ...props.style,
        }}
      />
    );
  }

  renderHeader({ section }) {
    return (
      <View style={{ display: "flex", flex: 1 }}>
        {section.index > 0 ? (
          <this.Separator
            style={{ backgroundColor: constants.colors.homePageSeparator }}
          />
        ) : null}

        <DimensionTableHeader section={section}
          selectDataSetYear={this.props.selectDataSetYear}
        />
        <this.Separator />
      </View>
    );
  }

  onItemTap(item) {
    this.props.onItemTap(item);
  }

  onItemOptionsTap(item) {
    this.props.onItemOptionsTap(item);
  }

  renderItem({ item, index, section }) {
    if (item.name === null) {
      return null;
    }
    if (!this.props.selectedDimensionId) {
      return null;
    }
    return (
      <DimensionsTableItem
        isPercentage={this.props.isPercentage}
        item={item}
        index={index}
        onItemTap={this.onItemTap.bind(this)}
        onItemOptionsTap={this.onItemOptionsTap.bind(this)}
        selectedDimensionId={this.props.selectedDimensionId}
      />
    );
  }

  getKey(item: CategoryViewModel, index: number): string {
    return "" + index + "item_list" + JSON.stringify(item);
  }
}

function mapStateToProps(state, props) {
  return {
    selectedDimensionId: state.dimensionsStore.selectedDimension?.json?.id,
    selectDataSetYear: state.dataSetStore.currentYear
  };
}

function mapDispatchToProps(dispatch, props: DimensionsTablePageProps) {
  let onItemTapCommon = async (item) => {
    return dispatch(store.dimensions.dimensionsActions.selectDimension(item));
  };
  return {
    onItemTap: async (item: DimensionViewModel) => {
      await onItemTapCommon(item);
      let selectedTabWrapper =
        props.hasChildren && item.chartsAvailability.isBubbleChartAvailable()
          ? store.charts.chartTabs.CHART_TABS_ID.BUBBLE
          : store.charts.chartTabs.CHART_TABS_ID.ENTITY_HISTORY;
      new RouteActions().refreshNavigationOnDimensionChanged(props, item.id);
      return dispatch(store.charts.chartsActions.loadChartData());
    },
    onItemOptionsTap: async (item) => {
      await onItemTapCommon(item);
      dispatch(
        appNavigation.appNavigatorActions.navigateToContextualChartMenu()
      );
    },
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DimensionsTablePage);
