//@flow
import * as React from "react";
import "../charts.css";
import { PixelRatio } from "react-native";
import { BarChart } from "./bar-chart";
import { constants } from "cockpit-shared";
import data from "../../../data";
import { onLayoutFunction } from "./bar-chart/BarChart";

export function getDefaultBarWidth() {
  //calculattes the width of each bar (for barChart) in case if there are more than 13 elements
  const parentWidth = window.innerWidth * 2 / 3 - 24;
  //it's 2/3 of the screen (flex:2) and 2 * 12px as paddings
  //60 = the width of the y-axis label 
  return parentWidth ? (parentWidth - 60) / 13 : 95;
}

export type EntityChartProps = {
  isLoading: boolean,
  budgetValues: Array<Object>,
  vorjahrValues: Array<Object>,
  istValues: Array<Object>,
  minimumNumberOfCollumns: number,
  isPercentageValues: boolean,
  isVjColorInverted: boolean,
  isBuColorInverted: boolean,
  istChartInverted: boolean,
  vorjahrChartInverted: boolean,
  budgetChartInverted: boolean,
  budgetValueColorPredicate: (value: number, index: number) => boolean,
  vorjahrValueColorPredicate: (value: number, index: number) => boolean,
  istValueColorPredicate?: (value: number, index: number) => boolean,
  onBudgetChartLayout: onLayoutFunction,
  onPreviousYearChartLayout: onLayoutFunction,
  onIstChartLayout: onLayoutFunction,
  yAxisPosition: ?"left" | "right",
  columnWidthInEms: number,
  isHistory: boolean
};

export type EntityChartState = {
  highlightedXIndex: ?number
};
class EntityChart extends React.Component<EntityChartProps, EntityChartState> {
  budgetChart: ?BarChart;
  vorjahrChart: ?BarChart;
  istChart: ?BarChart;
  labelWidth: number;

  constructor() {
    super();
    this.budgetChart = null;
    this.vorjahrChart = null;
    this.istChart = null;
    this.labelWidth = PixelRatio.get() * 34;
    this.state = {
      highlightedXIndex: null
    };
  }

  istValueColorPredicate(value: number, index: number) {
    if (this.props.istValueColorPredicate) {
      return this.props.istValueColorPredicate(value, index);
    }
    //Default implementation returns false
    return false;
  }

  onColumnSelect(x: number) {
    this.setState({
      highlightedXIndex: x
    });
  }

  get numberOfColumns() {
    return this.props.budgetValues.length;
  }
  get labelWidthProps() {
    return {
      labelWidthStyle: "fixedInput",
      labelWidth: this.labelWidth
    };
  }

  get yAxisPosition() {
    return this.props.yAxisPosition;
  }

  get numberOfEmptyCharts() {
    let multiplicator = 0;
    const barChartValues = [
      this.props.budgetValues.every((value) => value.marker === "na"),
      this.props.vorjahrValues.every((value) => value.marker === "na"),
      this.props.istValues.every((value) => value.marker === "na")
    ];
    for (let i = 0; i < 3; i++) {
      if (!barChartValues[i]) {
        multiplicator += 1;
      }
    }
    return multiplicator;
  }

  render() {
    return (
      <div className="entityChart-content-container" >
        {/*BUDGET CHART*/}
        <BarChart
          multiplicator={this.numberOfEmptyCharts}
          barWidth={getDefaultBarWidth()}
          isHistory={this.props.isHistory}
          isLoading={this.props.isLoading}
          title={data.strings.stringsRepo.budgetChartTitle.toUpperCase()}
          ref={ref => {
            this.budgetChart = ref;
          }}
          onLayout={this.props.onBudgetChartLayout}
          onColumnSelect={this.onColumnSelect.bind(this)}
          yValues={this.props.budgetValues}
          valueColorPredicate={this.props.budgetValueColorPredicate}
          goodColor={constants.colors.intenseGreen}
          badColor={constants.colors.intenseRed}
          minimumNumberOfCollumns={this.props.minimumNumberOfCollumns}
          isPercentageValues={this.props.isPercentageValues}
          {...this.labelWidthProps}
          axisInverted={this.props.budgetChartInverted}
          highlightedXIndex={this.state.highlightedXIndex}
          yAxisPosition={this.yAxisPosition}
          columnWidthInEms={this.props.columnWidthInEms}
        />
        {/*VORJAHR CHART*/}
        <BarChart
          multiplicator={this.numberOfEmptyCharts}
          barWidth={getDefaultBarWidth()}
          isHistory={this.props.isHistory}
          isLoading={this.props.isLoading}
          title={data.strings.stringsRepo.previousYearChartTitle.toUpperCase()}
          ref={ref => {
            this.vorjahrChart = ref;
          }}
          onLayout={this.props.onPreviousYearChartLayout}
          onColumnSelect={this.onColumnSelect.bind(this)}
          yValues={this.props.vorjahrValues}
          valueColorPredicate={this.props.vorjahrValueColorPredicate}
          goodColor={constants.colors.intenseGreen}
          badColor={constants.colors.intenseRed}
          chartTag={this.props.previousYearChartTag}
          minimumNumberOfCollumns={this.props.minimumNumberOfCollumns}
          isPercentageValues={this.props.isPercentageValues}
          {...this.labelWidthProps}
          axisInverted={this.props.vorjahrChartInverted}
          highlightedXIndex={this.state.highlightedXIndex}
          yAxisPosition={this.yAxisPosition}
          columnWidthInEms={this.props.columnWidthInEms}
        />
        {/*IST CHART*/}
        <BarChart
          multiplicator={this.numberOfEmptyCharts}
          barWidth={getDefaultBarWidth()}
          isHistory={this.props.isHistory}
          isLoading={this.props.isLoading}
          title={data.strings.stringsRepo.istChartTitle.toUpperCase()}
          ref={ref => {
            this.istChart = ref;
          }}
          onLayout={this.props.onIstChartLayout}
          onColumnSelect={this.onColumnSelect.bind(this)}
          yValues={this.props.istValues}
          valueColorPredicate={this.istValueColorPredicate.bind(this)}
          goodColor={constants.colors.barChartColor}
          badColor={constants.colors.lightOceanBlue}
          chartTag={this.props.istChartTag}
          minimumNumberOfCollumns={this.props.minimumNumberOfCollumns}
          isPercentageValues={false}
          {...this.labelWidthProps}
          axisInverted={this.props.istChartInverted}
          highlightedXIndex={this.state.highlightedXIndex}
          yAxisPosition={this.yAxisPosition}
          columnWidthInEms={this.props.columnWidthInEms}
        />
      </div>
    );
  }
}

export default EntityChart;
