//@flow
import { BaseManager } from "../base";
import { RequestTypes } from "../request-type/RequestType";
import type { BaseManagerResponse } from "../base/BaseManagerResponse";
import ServiceError from "../error/ServiceError";
import { DimensionViewModel } from "../dimension";

export default class DimensionsManager extends BaseManager {
  async getDimensions(
    dataSetId: string,
    category: string,
    entityId: string
  ): BaseManagerResponse {
    let url = `${dataSetId}/categories/${category}/entities/${entityId}/dimensions-table-data/`;
    let response: BaseManagerResponse = await this.fetchJSON(RequestTypes.GET, url);
    if(response instanceof ServiceError) {
        return response;
    }
    let json = response;
    let dimensions = json.map(dimensionJson => new DimensionViewModel(dimensionJson));
    return dimensions;
  }
}
