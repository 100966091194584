//@flow

import type { IconImageSizeType } from "./IconImageSize";
import type { IconImageAssetType } from "./assets";
import type { BaseComponentProps } from "../base-component";
import * as React from "react";
import { Text, View, ScrollView, Image } from "react-native";
import { iconImageSizes } from "./IconImageSize";
import { iconImageAssets } from "./assets";

export type IconImageProps = BaseComponentProps & {
  source: IconImageAssetType | { uri: string },
  size: IconImageSizeType,
};
export type IconImageState = {};
export default class Icon extends React.Component<
  IconImageProps,
  IconImageState
> {
  get iconImageSource() {
    return this.props.source;
  }

  render() {
    return (
      <Image
        style={{ ...this.props.size, ...this.props.style }}
        resizeMode={"contain"}
        source={this.iconImageSource}
      />
    );
  }
}
