//@flow
import type { HProps } from "./H";
import type { BaseComponentProps } from "../base-component/BaseComponent";
import * as React from "react";
import { Text, View, ScrollView, Image } from "react-native";
import H from "./H";

export type H2Props = HProps & {};
export type H2State = {};

export const H2Style = {
  fontSize: 15
};

export default class H2 extends React.Component<H2Props, H2State> {
  render() {
    return <H {...this.props} style={{ ...H2Style, ...this.props.style }}>{this.props.children}</H>;
  }
}
