//@flow
import type { RootReducerState } from "../root/RootReducer";
import { authenticationActionTypes } from "./AuthenticationActionTypes";
import { createAction } from "../base/BaseActions";
import data from "../../data";

export function authenticateWithEmail(email: string, password: string) {
  return async function (dispatch: any, getState: () => RootReducerState) {
    let loadingAction = createAction(authenticationActionTypes.AUTHENTICATE_WITH_EMAIL, {
      isLoading: true
    });
    dispatch(loadingAction);
    let manager = new data.authentication.AuthenticationManager();
    let response = await manager.loginWithEmail(email, password);
    if (!(response instanceof data.error.ServiceError)) {
      let jwt = require("jwt-decode");
      let decodedResponse = jwt(response);
      dispatch(
        createAction(authenticationActionTypes.AUTHENTICATE_WITH_EMAIL, {
          token: response,
          email,
          uid: decodedResponse.uid,
          userName: decodedResponse.username,
          firstName: decodedResponse.firstname,
          lastName: decodedResponse.lastname
        })
      );
    }
    return response;
  };
}

export function authenticateWithMicrosoftAzure(hash: string) {
  return async function (dispatch: any, getState: () => RootReducerState) {
    let loadingAction = createAction(authenticationActionTypes.AUTHENTICATE_WITH_MICROSOFT, {
      isLoading: true
    });
    dispatch(loadingAction);
    let manager = new data.authentication.AuthenticationManager();
    let response = await manager.loginWithMicrosoft(hash);
    if (!(response instanceof data.error.ServiceError)) {
      let jwt = require("jwt-decode");
      let decodedResponse = jwt(response);
      dispatch(
        createAction(authenticationActionTypes.AUTHENTICATE_WITH_MICROSOFT, {
          token: response,
          email: decodedResponse.email,
          uid: decodedResponse.uid,
          userName: decodedResponse.username,
          firstName: decodedResponse.firstname,
          lastName: decodedResponse.lastname
        })
      );
    }
    return response;
  };
}

export function logout() {
  return async function (dispatch: any, getState: () => RootReducerState) {
    let logoutAction = createAction(authenticationActionTypes.LOGOUT, {});
    return dispatch(logoutAction);
  };
}

export function resetPassword(email: string) {
  return async function (dispatch: any, getState: () => RootReducerState) {
    let loadingAction = createAction(authenticationActionTypes.AUTH_RESET_PASSWORD, {
      isLoading: true
    });
    dispatch(loadingAction);
    let manager = new data.authentication.AuthenticationManager();
    let response = await manager.resetPassword(email);
    await dispatch(
      createAction(authenticationActionTypes.AUTH_RESET_PASSWORD, {
        isLoading: false
      })
    );
    return response;
  };
}

export function changePassword(
  currentPassword: string,
  newPassword: string,
  newPasswordConfirm: string
) {
  return async function (dispatch: any, getState: () => RootReducerState) {
    let loadingAction = createAction(authenticationActionTypes.AUTH_CHANGE_PASSWORD, {
      isLoading: true
    });
    dispatch(loadingAction);
    let manager = new data.authentication.AuthenticationManager();
    let response = await manager.changePassword(currentPassword, newPassword, newPasswordConfirm);
    await dispatch(
      createAction(authenticationActionTypes.AUTH_CHANGE_PASSWORD, {
        isLoading: false
      })
    );
    return response;
  };
}
