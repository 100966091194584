//@flow
import type { IconAssetType } from "../icon/assets";
import type { IconSizeType } from "../icon";
import type { BaseComponentProps } from "../base-component";
import * as React from "react";
import { StyleSheet, Text, View, ScrollView, Animated } from "react-native";
import { constants } from "cockpit-shared";
import * as icon from "../icon";
import { H4, H } from "../texts";
import { Tappable } from "../tappable";
import { connect } from "react-redux";
import NavigationHeaderBackground from "./NavigationHeaderBackground";

export const NAVIGATION_HEADER_HEIGHT = 80;
export type NavigationHeaderProps = {
  title: string | Object,
  hasBackNavigation: boolean,
  dataSetName: string,
  onBackPressed: () => void,
  backIcon?: IconAssetType,
  backIconSize?: IconSizeType,
  backIconStyle?: Object,
  headerRight?: Function,
  style?: any,
  navigation: Object,
  onBackPressed: Function,
  shouldHandleCustomBack: boolean,
  gradientCenter?: [number, number], //[x,y],
  showDataSetSwitcher: Function,
};
export type NavigationHeaderState = {};
class NavigationHeader extends React.PureComponent<
  NavigationHeaderProps,
  NavigationHeaderState
> {
  onBackPressed() {
    if (
      this.props.shouldHandleCustomBack != null &&
      this.props.shouldHandleCustomBack
    ) {
      if (this.props.onBackPressed) {
        this.props.onBackPressed();
        return;
      }
    }
    this.props.navigation.goBack();
  }

  onDataSetPress() {
   this.props.showDataSetSwitcher();
  }

  renderCustomTitle() {
    let CustomNavTitle = this.props.title;
    return <CustomNavTitle />;
  }

  get shouldRenderCustomNavTitle() {
    return typeof this.props.title !== "string";
  }

  render() {
    return (
      <NavigationHeaderBackground gradientCenter={this.props.gradientCenter}>
        <Animated.View
          style={{
            height: NAVIGATION_HEADER_HEIGHT,
            ...this.props.style
          }}
        >
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: 6,
              marginBottom: constants.dimensions.bigMargin
            }}
          >
            <HeaderLeft
              hasBackNavigation={this.props.hasBackNavigation}
              backIcon={this.props.backIcon}
              backIconSize={this.props.backIconSize}
              onBackPressed={this.onBackPressed.bind(this)}
              onDataSetPress={this.onDataSetPress.bind(this)}
              dataSet={this.props.dataSetName}
              shouldRenderCustomNavTitle={this.shouldRenderCustomNavTitle}
              title={this.props.title}
              navTitle={this.props.navTitle}
              renderCustomTitle={this.renderCustomTitle.bind(this)}
            />
            {this.props.headerRight ? this.props.headerRight() : null}
          </View>
        </Animated.View>
      </NavigationHeaderBackground>
    );
  }
}

function mapStateToPros(state, props) {
  return {
    dataSetName: state.dataSetStore.name
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showDataSetSwitcher: () => { }
  };
}

export default connect(mapStateToPros, mapDispatchToProps)(NavigationHeader);

class HeaderLeft extends React.Component<
  {
    hasBackNavigation: boolean,
    backIcon?: string,
    backIconSize?: number,
    onBackPressed: Function,
    onDataSetPress: Function,
    dataSet: string,
    shouldRenderCustomNavTitle: boolean,
    title: string,
    renderCustomTitle: Function
  },
  {}
> {
  shouldComponentUpdate(newProps, newState) {
    return JSON.stringify(newProps) !== JSON.stringify(this.props);
  }
  render() {
    return (
      <View
        style={{
          flexDirection: "row"
        }}
      >
        <View
          style={{
            justifyContent: "flex-end",
            marginHorizontal: constants.dimensions.defaultMargin
          }}
        >
          {this.props.hasBackNavigation ? (
            <NavIcon
              icon={this.props.backIcon}
              size={this.props.backIconSize}
              onBackPressed={this.props.onBackPressed}
            />
          ) : null}
        </View>
        <View
          style={{
            justifyContent: "flex-start"
          }}
        >
          <DataSet
            onDataSetPress={this.props.onDataSetPress}
            style={{ marginBottom: constants.dimensions.smallMargin }}
          >
            {this.props.dataSet}
          </DataSet>
          {!this.props.shouldRenderCustomNavTitle ? (
            <NavTitle>{this.props.title}</NavTitle>
          ) : (
            this.props.renderCustomTitle()
          )}
        </View>
      </View>
    );
  }
}

const NavIcon = props => {
  return (
    <Tappable onTap={props.onBackPressed}>
      <icon.Icon
        name={props.icon || icon.iconAssets.arrowBack}
        color={"white"}
        size={props.size || icon.iconSizes.TINY}
      />
    </Tappable>
  );
};

const DataSet = props => {
  return (
    <View style={props.style}>
      <Tappable onTap={props.onDataSetPress}>
        <H4
          style={{
            color: constants.colors.middleBlue,
            fontWeight: "bold"
          }}
        >
          {props.children}
        </H4>
      </Tappable>
    </View>
  );
};

export const NavTitle = props => {
  return (
    <H
      numberOfLines={1}
      style={{
        fontSize: 18,
        color: constants.colors.white,
        fontWeight: "bold",
        ...props.style
      }}
    >
      {props.children}
    </H>
  );
};
