//@flow
export default {
  appVersion: "App Version",
  loginEmailPlaceholder: "USZ E-Mail eingeben",
  loginPasswordPlaceholder: "Passwort eingeben",
  forgotPassword: "Passwort vergessen?",
  reset: "Zurücksetzen",
  loginButton: "Login",
  loginPageTitleLine1: "LOGIN",
  loginPageTitlelLine2: "Ihr Account",
  noInternetConnection: "Could not connect!",
  homePageTitlePar1: "USZ",
  homePageTitlePar2: " COCKPIT",
  financesButtonTitle: "Finanzen & Leistungen",
  productivityButtonTitle: "Produktivität",
  processAndQualityButtonTitle: "Prozess & Qualität",
  bookmarksButtonTitle: "Bookmarks",
  notImplemented: "Not implemented.",
  budgetChartTitle: "Budget",
  previousYearChartTitle: "Vorjahr",
  istChartTitle: "IST",
  ytd: "YTD",
  dimensionsHeaderTitle: "Kennzahl",
  budgetShort: "Bu",
  previousYearShort: "Vj",
  profileWelcomeMessage: "Willkommen,",
  yes: "Ja",
  no: "Nein",
  logoutConfirmQuestion: "Möchten Sie sich wirklich ausloggen?",
  popupAlertTitle: "Alert",
  unit: "Einheit",
  rank: "Rang",
  chooseOneOption: "Choose one option",
  percentBubble: "Percent bubble",
  absoluteDelta: "Absolute delta",
  history: "History",
  ranking: "Ranking",
  glossary: "Glossary",
  notifications: "Nachrichten",
  helper: "Hilfe",
  changeDataSet: "Berichtsperiode ändern",
  noDataAvaialble: "No Data available.",
  ok: "Ok",
  deepestLevelReached: "Tiefste Ebene erreicht, kein Drilldown möglich!",
  noDataRanking: "No Data available for this category and dimension.",
  barChartNoDataLabel: "No data.",
  resetPassword: "Passwort zurücksetzen",
  cancelButtonLabel: "Abbrechen",
  resetPasswordPageLine1: "RESET",
  resetPasswordPageLine2: "Ihr Passwort",
  resetPasswordHelp: "Enter your email and we will send you a new password.",
  changePassword: "Passwort ändern",
  changePasswordPageLine1: "PROFIL",
  changePasswordPageLine2: "Passwort ändern",
  changePasswordHelp: "Das Passwort muss mindestens 8 Buchstaben lang sein.",
  done: "Erledigt",
  currentPasswordPlaceholder: "Aktuelles Passwort",
  newPasswordPlaceholder: "Neues Passwort",
  newPasswordConfirmPlaceholder: "Passwort wiederholen",
  receiveNotifications: "Nachrichten erhalten",
  notificationToggleOnLabel: "ON",
  notificationToggleOffLabel: "OFF",
  settings: "Einstellungen",
  logOutAlertTitle: "Logout",
  privacyPolicyDescription: "Mit dem Login akzeptieren Sie die ",
  privacyPolicy: "Datenschutzerklärung",
  bubbleChartTabName: "Bubble Chart",
  deltaChartTabName: "Delta Chart",
  rankingChartTabName: "Structure Chart",
  historyChartChartTabName: "History Chart",
  uszCopyright: "© Copyright 2020 UniversitätsSpital Zürich",
  //String to review
  emailAddress: "E-Mail Addresse",
  userName: "Nutzername",
  loginWithMicrosoft: "Login mit Microsoft",
  struktur: "Struktur"
};
