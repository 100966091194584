//@flow
export type IconImageSize = {
    width: number,
    height: number,
};

function createIconImageSize(width: number, height: number): IconImageSize {
    return {
        width,
        height
    };
}

export const iconImageSizes = {
    TINY: createIconImageSize(20, 20),
    SMALL: createIconImageSize(26, 26),
    MEDIUM: createIconImageSize(33, 33),
    BIG: createIconImageSize(60, 60),
    HUGE: createIconImageSize(103, 103),
};

export type IconImageSizeType = $Values<typeof iconImageSizes>;