//@flow
import { getRootStore } from "cockpit-shared/src/store/root/RootStore";

export default class RouteActions {
  createChartsContainerUrl(
    dataSetId: String,
    categoryId: String,
    entityId: String,
    dimensionId: String
  ) {
    return `/datasets/${dataSetId}/categories/${categoryId}/entities/${entityId}/dimensions/${dimensionId}/chart-data`;
  }

  goToChartsContainer(props, dataSetId, categoryId, entityId, dimensionId) {
    props.history.replace(
      this.createChartsContainerUrl(
        dataSetId,
        categoryId,
        entityId,
        dimensionId
      )
    );
  }

  goToNotFoundPage(props) {
    props.history.push("/404");
  }

  refreshNavigationOnDimensionChanged(
    navProps: Object,
    newDimensionId: String
  ) {
    let navParams = navProps.match.params;
    let datasetId = navParams.dataset;
    let categoryId = navParams.category;
    let entityId = navParams.entity;
    navProps.history.push(
      this.createChartsContainerUrl(
        datasetId,
        categoryId,
        entityId,
        newDimensionId
      )
    );
  }

  refreshNavigationOnOrganizationChanged(
    navProps: Object,
    newEntityId: String
  ) {
    let navParams = navProps.match.params;
    let datasetId = navParams.dataset;
    let categoryId = navParams.category;
    let dimensionId = navParams.dimension;
    navProps.history.push(
      this.createChartsContainerUrl(
        datasetId,
        categoryId,
        newEntityId,
        dimensionId
      )
    );
  }

  refreshNavigationOnCategoryChanged(
    navProps: Object,
    newCategoryId: String
  ) {
    let navParams = navProps.match.params;
    let datasetId = navParams.dataset
    navProps.history.push(
      `/datasets/${datasetId}/categories/${newCategoryId}/chart-data`
    );
  }

  refreshNavigationOnDatasetChanged(
    navProps: Object,
    datasetId: String,
  ) {
    let state = getRootStore().getState();
    let categoryId = state.categoriesStore.category?.id;
    let entityId = state.organizationsStore.selectedOrganization?.id;
    let dimensionId = state.dimensionsStore.selectedDimension?.json?.id;

    if (categoryId && entityId && dimensionId) {
      navProps.history.push(
        `/datasets/${datasetId}/categories/${categoryId}/entities/${entityId}/dimensions/${dimensionId}/chart-data`
      );
      return;
    }
    if (categoryId && entityId) {
      navProps.history.push(
        `/datasets/${datasetId}/categories/${categoryId}/entities/${entityId}/chart-data`
      );
      return;
    }
    if (categoryId) {
      navProps.history.push(
        `/datasets/${datasetId}/categories/${categoryId}/chart-data`
      );
      return;
    }
    navProps.history.push(`/datasets/${datasetId}`);
  }
}
