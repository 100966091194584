//@flow
import type { BaseComponentProps } from "../base-component";
import * as React from "react";
import { Text, View, ScrollView } from "react-native";
import data from "../../data";
import { constants } from "cockpit-shared";
import { NAVIGATION_HEADER_HEIGHT } from ".";

export type NavigationHeaderBackgroundProps = BaseComponentProps & {};
export type NavigationHeaderBackgroundState = {};
export default class NavigationHeaderBackground extends React.PureComponent<
  NavigationHeaderBackgroundProps,
  NavigationHeaderBackgroundState
> {
  render() {
    return (
      <View
        style={{
          flex: 1,
          ...this.props.style,
        }}
      >
        {this.props.children}
      </View>
    );
  }
}
