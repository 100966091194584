//@flow
import { createAuthenticationLogOutHandler } from "../authentication/AuthenticationReducer";

export function createReducer(initialState: Object | Array<any>, handlers: Object, thirdPartyHandlers: ?Object | ?Array<any>) {
  return function reducer(state: Object | Array<any> = initialState, action: Object) {
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action);
    } else if (thirdPartyHandlers && thirdPartyHandlers.hasOwnProperty(action.type)) {
      return thirdPartyHandlers[action.type](state, action);
    } else {
      return state;
    }
  };
}

/**
 * Creates a reducer which will return to its initial state
 * when a log out action is dispatched.
 */
export function createAuthAwareReducer(initialState: Object | Array<any>, handlers: Object) {
  return createReducer(initialState, handlers, createAuthenticationLogOutHandler(initialState));
}
