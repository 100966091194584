//@flow
import type { BaseComponentProps } from "../base-component/BaseComponent";
import * as React from "react";
import { Text, View, ScrollView, Image, PixelRatio } from "react-native";
import data from "../../data";
import H2, { H2Style } from "./H2";

export type NumberTextProps = {
  style?: ?Object,
  children: ?string,
  isUnderline?: boolean,
};
export type NumberTextState = {};

export default class NumberText extends React.Component<
  NumberTextProps,
  NumberTextState
> {
  get initialFontSize() {
    let fontSizeFromProps = this.props.style ? this.props.style.fontSize : null;
    let defaultFontSize = H2Style.fontSize;
    let usedFontSize =
      fontSizeFromProps != null ? fontSizeFromProps : defaultFontSize;
    return usedFontSize;
  }

  render() {
    return (
      <H2
        numberOfLines={1}
        style={{ ...this.props.style, fontSize: this.initialFontSize }}
      >
        {this.props.children}
      </H2>
    );
  }
}
