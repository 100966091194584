//@flow
import * as React from "react";
import { inAppNotificationActionTypes } from "./InAppNotificationActionsTypes";
import * as base from "../base";

export function showInAppNotification(contentView: any) {
  return function(dispatch: any) {
    let action =  base.baseActions.createAction(
      inAppNotificationActionTypes.SHOW_IN_APP_NOTIFICATION,
      { contentView }
    );
    dispatch(action);
  };
}

export function hideInAppNotification() {
  return function(dispatch: any) {
    let action =  base.baseActions.createAction(
      inAppNotificationActionTypes.HIDE_IN_APP_NOTIFICATION,
      { contentView: null }
    );
    dispatch(action);
  };
}


