//@flow
import * as React from "react";
import { View, Image, Text, StyleSheet, Animated, Easing } from "react-native";
type AnimatedEntranceState = {
  opacityValue: Animated.Value,
  isAnimationFinished: boolean
};
type AnimatedEntranceProps = {
  children: any,
  style: any,
  isVisible: boolean
};

export default class AnimatedEntrance extends React.Component<
  AnimatedEntranceProps,
  AnimatedEntranceState
> {
  constructor() {
    super();
    this.state = {
      opacityValue: new Animated.Value(0),
      isAnimationFinished: false,
    };
  }

  get defaultDuration() {
    return 400;
  }

  onAnimationFinished() {
    this.setState({
      isAnimationFinished: true
    });
  }

  async onAnimationStarted() {
    return new Promise((resolve, reject) => {
      this.setState(
        {
          isAnimationFinished: false
        },
        resolve
      );
    });
  }

  async toggleVisibilityAnimated(isVisible: boolean) {
    await this.onAnimationStarted();
    Animated.timing(this.state.opacityValue, {
      toValue: isVisible ? 1 : 0,
      duration: this.defaultDuration,
      easing: Easing.sin
    }).start(this.onAnimationFinished.bind(this));
  }

  componentDidMount() {
    this.toggleVisibilityAnimated(this.props.isVisible);
  }

  UNSAFE_componentWillReceiveProps(newProps: AnimatedEntranceProps) {
    if (newProps.isVisible !== this.props.isVisible) {
      this.toggleVisibilityAnimated(newProps.isVisible);
    }
  }

  render() {
    return (
      <Animated.View
        pointerEvents={"box-none"}
        style={{ opacity: this.state.opacityValue, ...this.props.style }}
      >
        {this.state.isAnimationFinished && this.props.isVisible
          ? this.props.children
          : null}
      </Animated.View>
    );
  }
}
