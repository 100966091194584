//@flow
import * as React from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  Image,
  LayoutAnimation,
} from "react-native";
import { constants, store } from "cockpit-shared";
import { connect } from "react-redux";
import { getRootStore } from "../../app";
import { ChartReducerType } from "../SelectedTabContent";
import { RouteActions } from "../../../navigation";

export type EntitySelectableTabContentProps = {
  chartTabContent: Function,
  chartsReducer: ChartReducerType,
  selectOrganization: (organization: Object) => void,
  searchForFullOrganizationViewModel: (organizationId: string) => ?Object,
  isTheMostTopLevelEntitySelected: boolean,
  drillUp: () => void,
  onEntityColumnTap: (newSelectedOrganization: Object) => void,
  onDrillUp: (organizationEntityParent: Object) => void,
  selectedOrganization: Object,
};
export type EntitySelectableTabContentState = {};
class EntitySelectableTabContent extends React.Component<
  EntitySelectableTabContentProps,
  EntitySelectableTabContentState
> {
  onColumnTap(columnIndex: number) {
    if (this.props.chartsReducer.data == null) {
      return;
    }
    let selectedEntity = this.props.chartsReducer.data.subEntities[columnIndex];
    let fullViewModel = this.props.searchForFullOrganizationViewModel(
      selectedEntity.id
    );
    if (fullViewModel) {
      this.props.onEntityColumnTap(fullViewModel);
      this.props.selectOrganization(fullViewModel);
    }
  }

  onDrillUp() {
    let organizationEntityParent = this.props.searchForFullOrganizationViewModel(
      this.props.selectedOrganization.parentId
    );
    this.props.onDrillUp(organizationEntityParent);
    this.props.drillUp();
  }

  render() {
    let ChartTabContent = this.props.chartTabContent;
    return (
      <ChartTabContent
        onColumnTap={this.onColumnTap.bind(this)}
        onDrillUpTap={this.onDrillUp.bind(this)}
        isDrillUpAvailable={!this.props.isTheMostTopLevelEntitySelected}
      />
    );
  }
}

function mapStateToProps(state, props) {
  let allOrganizations: Array<Object> = state.organizationsStore.organizations;
  let selectedOrganization = state.organizationsStore.selectedOrganization;
  return {
    isTheMostTopLevelEntitySelected:
      allOrganizations.find((item) => item.id === selectedOrganization.id) !=
      null,
    selectedOrganization,
  };
}

function mapDispatchToProps(dispatch, props) {
  return {
    selectOrganization: (organization) => {
      LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
      new RouteActions().refreshNavigationOnOrganizationChanged(props, organization.id);
      return dispatch(
        store.organizations.organizationsActions.selectOrganizationAndRefresh(
          organization
        )
      );
    },
    searchForFullOrganizationViewModel: (organizationId: string) => {
      return dispatch(
        store.organizations.organizationsActions.searchForFullOrganizationViewModel(
          organizationId
        )
      );
    },
    drillUp: () => dispatch(store.organizations.organizationsActions.drillUp()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EntitySelectableTabContent);
