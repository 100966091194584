//@flow
import type { BaseComponentProps } from "../base-component";
import * as React from "react";
import { Text, View, ScrollView, Image } from "react-native";
import { connect } from "react-redux";
import NoInternetView from "./NoInternetView";

export type NoInternetContainerProps = BaseComponentProps & {
  isConnected: boolean,
  message?: string,
};
export type NoInternetContainerState = {};
class NoInternetContainer extends React.Component<
  NoInternetContainerProps,
  NoInternetContainerState
> {
  render() {
    return this.props.isConnected ? (
      this.props.children
    ) : (
      <NoInternetView message={this.props.message} style={this.props.style} />
    );
  }
}

function mapStateToProps(state) {
  return {
    isConnected: state.connectivityStore.isConnected
  };
}

export default connect(mapStateToProps)(NoInternetContainer);
