//@flow
import React, { Component } from "react";
import { connect } from "react-redux";
import MenuColumn from "./MenuColumn";
import { OrganizationEntityModel } from "./../../../../cockpit-shared/src/data/entity/OrganizationEntityModel";
import { store, strings } from "cockpit-shared";
import { HamburgerSqueeze } from "react-animated-burgers";
import "./Menu.css";
import { RouteActions } from "./../../navigation";

type MenuProps = {
  organizations: OrganizationEntityModel[],
  selectedOrganization: OrganizationEntityModel[],
  selectedChartTabId: String,
  changeTab: (tabId: string) => void,
  selectOrganization: Function,
  searchForFullOrganizationViewModel: (organizationId: string) => ?Object,
};

type MenuState = {
  prevDepthLevel: Number,
  menuColumns: [],
  selectedOrganizationId: String
};

class Menu extends Component<MenuProps, MenuState> {
  constructor(props: MenuProps) {
    super(props);

    this.state = {
      prevDepthLevel: -1,
      prevHoveredElementId: "",
      menuColumns: [],
      selectedOrganizationId: this.props.selectedOrganization.id || "",
      isActive: false,
      width: 0
    };

    this.wrapperRef = React.createRef();
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target) && this.state.isActive) {
      this.onMouseLeave();
    }
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedOrganization !== prevProps.selectedOrganization) {
      this.setState({
        selectedOrganizationId: this.props.selectedOrganization.id,
      });
    }
  }

  getSelectedOrganizationPath = (selectedOrgId, name) => {
    let fullViewModel = this.props.searchForFullOrganizationViewModel(selectedOrgId);
    if (!fullViewModel) {
      return name;
    }

    if (name !== "") {
      name = fullViewModel.name + " / " + name;
    }
    else {
      name = fullViewModel.name;
    }

    if (!fullViewModel.parentId) {
      return name;
    }

    return this.getSelectedOrganizationPath(fullViewModel.parentId, name);
  }

  addNewColumn(items) {
    let columns = this.state.menuColumns;
    columns.push(
      <MenuColumn
        items={items}
        onItemClicked={this.onItemClicked.bind(this)}
        onItemHovered={this.onItemHovered.bind(this)}
        marginLeft={columns.length * (this.state.width > 1280 ? 280 : 248)}
        depthLevel={columns.length}
      />
    );
    this.setState({ menuColumns: columns });
  }

  removeColumn() {
    let columns = this.state.menuColumns;
    columns.pop();
    this.setState({ menuColumns: columns });
  }

  removeMultipleColumns(nrOfColsToRemain) {
    let columns = this.state.menuColumns;
    while (columns.length !== nrOfColsToRemain) {
      columns.pop();
    }
    this.setState({ menuColumns: columns });
  }

  onItemClicked(id) {
    let chartTabId = store.charts.chartTabs.CHART_TABS_ID;
    let fullViewModel = this.props.searchForFullOrganizationViewModel(id);
    if (fullViewModel) {
      this.props.selectOrganization(fullViewModel);
    }

    if (
      fullViewModel.children.length === 0 &&
      this.props.selectedChartTabId !== chartTabId.ENTITY_HISTORY
    ) {
      this.props.changeTab(chartTabId.ENTITY_HISTORY);
    }

    this.setState({
      prevDepthLevel: -1,
      menuColumns: [],
      selectedOrganizationId: id,
      isActive: false
    });
    document.getElementById("absolute-overlay").classList.remove("blockDisplay");
  }

  removeHoverState(id) {
    document.getElementById(id).classList.remove("preserve-hover-state");
  }

  addHoverState(id) {
    document.getElementById(id).classList.add("preserve-hover-state");
  }

  verifySameLeverHoverStates(depthLevel) {
    let a = document.getElementsByClassName("menu-item preserve-hover-state");
    for (var i = 0, len = a.length | 0; i < len; i++) {
      if (a[i].id.includes(depthLevel) && depthLevel !== 0) {
        this.removeHoverState(a[i].id);
        break;
      }
    }
  }

  goBack(children, depthLevel, prevDepthLevel) {
    if (depthLevel === 0 && this.state.menuColumns.length > 2) {
      this.removeMultipleColumns(2);
      return;
    }
    if (this.state.menuColumns.length >= depthLevel + prevDepthLevel && depthLevel !== 0) {
      this.removeColumn();
    }
    if (children.length > 0) {
      if (prevDepthLevel - depthLevel > 1) {
        this.removeMultipleColumns(this.state.menuColumns.length - prevDepthLevel + depthLevel);
      } else {
        this.removeColumn()
      }
      this.addNewColumn(children);
    } else {
      this.removeColumn();
    }
  }

  goForward(children) {
    this.addNewColumn(children);
  }

  stayOnTheSameLevel(children, depthLevel) {
    //If the element doesn't have deeper level children
    if (this.state.menuColumns.length !== depthLevel + 1) {
      this.removeColumn();
    }
    //If the element has deeper level children => update state add new col
    if (children.length !== 0) {
      this.addNewColumn(children);
    }
  }

  onItemHovered(children, depthLevel, id) {
    let prevHoveredElementId = this.state.prevHoveredElementId;
    let prevDepthLevel = this.state.prevDepthLevel;
    let prevElementId = prevDepthLevel + "-" + prevHoveredElementId;
    let currentElementId = depthLevel + "-" + id;
    this.setState({ prevDepthLevel: depthLevel, prevHoveredElementId: id });

    /**
     * --
     * Being on the same level
     */
    if (prevDepthLevel === depthLevel) {
      this.removeHoverState(prevElementId);
      this.addHoverState(currentElementId);
      this.stayOnTheSameLevel(children, depthLevel);
      return;
    }

    /**
     * <--
     * Backwards navigation
     */
    if (prevDepthLevel !== -1 && prevDepthLevel > depthLevel) {
      this.removeHoverState(prevElementId);
      this.verifySameLeverHoverStates(depthLevel);
      this.addHoverState(currentElementId);
      this.goBack(children, depthLevel, prevDepthLevel);
      return;
    }

    /**
     * -->
     * Forward navigation
     */
    if (prevDepthLevel < depthLevel && children.length > 0) {
      this.addHoverState(currentElementId);
      this.goForward(children);
      return;
    }
  }

  getMenuColumns() {
    return this.state.menuColumns;
  }

  onMouseLeave() {
    this.setState({
      prevDepthLevel: -1,
      menuColumns: [],
    });
    this.toggleButton();
    document.getElementById("absolute-overlay").classList.remove("blockDisplay");
  }

  onMouseEnter() {
    this.addNewColumn(this.props.organizations);
    this.toggleButton();
    document.getElementById("absolute-overlay").classList.add("blockDisplay");
  }

  toggleButton() {
    this.setState({
      isActive: !this.state.isActive
    });
  }

  toggleMenu() {
    if (this.state.isActive) {
      this.onMouseLeave();
    } else {
      this.onMouseEnter();
    }
  }

  render() {
    return (
      <div className="main-menu-container" ref={this.wrapperRef} >
        <div className="menu-title-container" >
          <HamburgerSqueeze
            style={{ padding: 0 }}
            buttonWidth={20}
            isActive={this.state.isActive}
            barColor="white"
            onClick={this.state.isActive ?
              this.onMouseLeave.bind(this)
              :
              this.onMouseEnter.bind(this)
            }
          />
          <div style={{ marginLeft: 10 }}>{this.getSelectedOrganizationPath(this.state.selectedOrganizationId, "")}</div>
        </div>
        {this.state.isActive &&
          <div className="menu-columns">{this.getMenuColumns()}</div>
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    organizations: state.organizationsStore.organizations,
    selectedOrganization: state.organizationsStore.selectedOrganization,
    selectedChartTabId: state.chartsStore.selectedTabId,
  };
}

function mapDispatchToProps(dispatch, props: MenuProps) {
  return {
    selectOrganization: async (organization) => {
      new RouteActions().refreshNavigationOnOrganizationChanged(
        props,
        organization.id
      );
      return dispatch(
        store.organizations.organizationsActions.selectOrganizationAndRefreshForWeb(
          organization
        )
      );
    },
    searchForFullOrganizationViewModel: (organizationId: string) => {
      return dispatch(
        store.organizations.organizationsActions.searchForFullOrganizationViewModel(
          organizationId
        )
      );
    },
    changeTab: (tabId: string) =>
      dispatch(store.charts.chartsActions.changeTab(tabId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
