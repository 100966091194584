//@flow
import * as React from "react";
import { View } from "react-native";
import { BubblesChart } from "../charts/bubbles-chart";
import data from "../../../data";
import DimensionTableColorHelper from "../../dimension-table-view/DimensionTableColorHelper";
import { ChartReducerType } from "../SelectedTabContent";
import CommonChartHeader from "../charts/common/CommonChartHeader";
import NoDataContainer from "../../no-data/NoDataContainer";

export type BubbleChartTabContentProps = {
  chartsReducer: ChartReducerType,
  isLoading: boolean,
  currentEntityName: string,
  onColumnTap: (columnIndex: number) => void,
  onDrillUpTap: () => void,
  isDrillUpAvailable: boolean
};
export type BubbleChartTabContentState = {};
export default class BubbleChartTabContent extends React.Component<
  BubbleChartTabContentProps,
  BubbleChartTabContentState
  > {
  get subEntities() {
    if (this.props.chartsReducer.data == null) {
      return [];
    }
    return this.props.chartsReducer.data.subEntities;
  }

  get isPercentage() {
    return true;
  }

  get chartsReducer(): ChartReducerType {
    return this.props.chartsReducer;
  }

  get isColorInverted() {
    if (!this.chartsReducer.data || !this.chartsReducer.data.invertedStatus) {
      return false;
    }
    return this.chartsReducer.data.invertedStatus.isBubblesColorInverted();
  }

  get isChartInverted() {
    if (!this.chartsReducer.data || !this.chartsReducer.data.invertedStatus) {
      return false;
    }
    return this.chartsReducer.data.invertedStatus.isBubbleChartInverted();
  }

  get isSpecialAxisInversionSchema() {
    if (!this.chartsReducer.data || !this.chartsReducer.data.invertedStatus) {
      return false;
    }
    return this.chartsReducer.data.invertedStatus.isSpecialAxisInversionSchema();
  }

  get budgetValues() {
    if (!this.chartsReducer.data) {
      return [];
    }
    return this.chartsReducer.data.entitiesBudget(this.isPercentage);
  }

  get budgetValuesFormatted() {
    if (!this.chartsReducer.data) {
      return [];
    }
    return this.chartsReducer.data.entitiesBudgetFormatted(this.isPercentage);
  }

  get vorjahrValues() {
    if (!this.chartsReducer.data) {
      return [];
    }
    return this.chartsReducer.data.entitiesPreviousYear(this.isPercentage);
  }

  get vorjahrValuesFormatted() {
    if (!this.chartsReducer.data) {
      return [];
    }
    return this.chartsReducer.data.entitiesPreviousYearFormatted(
      this.isPercentage
    );
  }

  get istValues() {
    if (!this.chartsReducer.data) {
      return [];
    }
    return this.chartsReducer.data.entitiesIst();
  }

  get istValuesFormatted() {
    if (!this.chartsReducer.data) {
      return [];
    }
    return this.chartsReducer.data.entitiesIstFormatted();
  }

  get budgetTitle() {
    return data.strings.stringsRepo.budgetChartTitle.toUpperCase();
  }

  get vorjahrTitle() {
    return data.strings.stringsRepo.previousYearChartTitle.toUpperCase();
  }

  get ytdTitle() {
    return data.strings.stringsRepo.ytd.toUpperCase();
  }

  get chartValues() {
    let xValues = this.budgetValues;
    let xValuesFormatted = this.budgetValuesFormatted;
    let yValues = this.vorjahrValues;
    let yValuesFormatted = this.vorjahrValuesFormatted;
    let sizeValues = this.istValues;
    let sizeValuesFormatted = this.istValuesFormatted;

    let chartValues: Array<Object> = xValues
      .map((value: number, index: number) => {
        if (sizeValues[index] == null) {
          return null;
        }
        return {
          x: value || 0,
          y: yValues[index] || 0,
          size: sizeValues[index],
          marker: `${this.budgetTitle}: ${xValuesFormatted[index]}\n${this.vorjahrTitle
            }: ${yValuesFormatted[index]}\n${this.ytdTitle}: ${sizeValuesFormatted[index]
            }`,
          label: this.subEntities[index].name
        };
      })
      .filter(item => item != null);
    let sortedValues: Array<Object> = chartValues.sort(
      (a: Object, b: Object) => (a.y < b.y && a.x < b.x ? -1 : 1)
    );
    return sortedValues;
  }

  get budgetTagColor() {
    return DimensionTableColorHelper.getBudgetTagColor(this.chartsReducer.data, true);
  }

  get previousYearTagColor() {
    return DimensionTableColorHelper.getPreviousYearTagColor(this.chartsReducer.data, true);
  }

  render() {
    let noData = this.chartValues.length === 0;
    return (
      <View style={{ flex: 1, paddingHorizontal: 12 }}>
        {/*<ChartsColumnsHeader
          onColumnTap={this.props.onColumnTap}
          isDrillUpAvailable={this.props.isDrillUpAvailable}
          onDrillUpTap={this.props.onDrillUpTap}
          subEntities={this.subEntities}
          minimumNumberOfCollumns={0}
        />*/}
        <CommonChartHeader
          chartsReducer={this.chartsReducer}
          isPercentage={this.isPercentage}
        />
        <NoDataContainer noData={noData}>
          <BubblesChart
            isLoading={this.props.isLoading}
            xAxisTitle={data.strings.stringsRepo.budgetChartTitle}
            yAxisTitle={data.strings.stringsRepo.previousYearChartTitle}
            values={this.chartValues}
            axisInverted={this.isChartInverted}
            budgetTagColor={this.budgetTagColor}
            previousYearTagColor={this.previousYearTagColor}
            isSpecialAxisInversionSchema={this.isSpecialAxisInversionSchema}
            isColorInverted={this.isColorInverted}
          />
        </NoDataContainer>
      </View>
    );
  }
}

