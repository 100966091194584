import React from "react";
import { View, Dimensions, Text } from "react-native";
import { Icon } from "../icon";
import { iconSizes } from "../icon/IconSize";
import { iconAssets } from "../icon/assets/index";
import { H3 } from "../texts";
import data from "../../data";
import { constants } from "cockpit-shared";
export interface NoDataProps {
    noData: boolean
}
export default class NoDataView extends React.Component<> {
    render() {

        return (
            <View
                style={{
                    flex: 1,
                    backgroundColor: "white",
                    justifyContent: "center",
                    alignItems: "center",
                    ...this.props.style
                }}
            >
                <Icon
                    color={constants.colors.errorColor}
                    size={iconSizes.HUGE}
                    name={iconAssets.noData}
                />
                <H3
                    style={{
                        color: constants.colors.errorColor,
                        marginTop: constants.dimensions.defaultMargin,
                        maxWidth: (Dimensions.get("window").width) / 2,
                        textAlign:"center", alignSelf:"center"
                    }}
                    numberOfLines={2}>
                    {data.strings.stringsRepo.noDataRanking}
                </H3>
            </View>
        )
    }
}