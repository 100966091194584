//@flow
import { BaseManager } from "../base";
import { RequestTypes } from "../request-type/RequestType";
import type { BaseManagerResponse } from "../base/BaseManagerResponse";
import ServiceError from "../error/ServiceError";

export default class AuthenticationManager extends BaseManager {
  async loginWithEmail(email: string, password: string): Promise<ServiceError | string> {
    let payload = {
      username: email,
      password
    };
    let response: BaseManagerResponse = await this.fetchJSON(RequestTypes.POST, "login/", payload);
    return response;
  }

  async loginWithMicrosoft(hash: string): Promise<ServiceError | string> {
    let payload = {
      hash: hash
    };
    let response: BaseManagerResponse = await this.fetchJSON(RequestTypes.POST, "login/", payload);
    return response;
  }

  async logout() {
    throw "Not implemented";
  }

  async resetPassword(email: string) {
    let payload = {
      email
    };
    let response: BaseManagerResponse = await this.fetchJSON(
      RequestTypes.POST,
      "password/reset/",
      payload
    );
    return response;
  }

  async changePassword(currentPassword: string, newPassword: string, newPasswordConfirm: string) {
    let payload = {
      old_password: currentPassword,
      new_password1: newPassword,
      new_password2: newPasswordConfirm
    };
    let response: BaseManagerResponse = await this.fetchJSON(
      RequestTypes.POST,
      "password/change/",
      payload
    );
    return response;
  }
}
