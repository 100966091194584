//@flow
import ServiceError from "../error/ServiceError";
import type { RequestType } from "../request-type";
import type { BaseServiceResponse } from "./BaseServiceResponse";
import { BaseServiceInterface } from "./BaseServiceInterface";
import { TokenServiceInterface } from "../token";

export interface BaseManagerInterface {
  service: BaseServiceInterface;
  mainUrl: string;
  tokenService: TokenServiceInterface;
  fetchJSON: (requestType: RequestType, path: string) => Promise<BaseServiceResponse>;
}
