//@flow
import type { BaseAction } from "../base/BaseActions";
import type { DataSetActionType } from "./DataSetActionTypes";
import { dataSetActionTypes } from "./DataSetActionTypes";
import { baseReducerHelper } from "../base";
import { commonActionHandler } from "../base/BaseActions";
import data from "../../data";

export type DataSetReducerState = {
  id: ?string,
  name: ?string,
  currentMonth: ?number,
  currentYear: ?number,
  dataSets: Array<data.dataSets.DataSetViewModel>,
  isLoading: boolean,
};

export type DataSetAction = BaseAction & {
  type: DataSetActionType
};

let defaultState: DataSetReducerState = {
  id: null, 
  name: null,
  currentMonth: null,
  currentYear: null,
  dataSets: [],
  isLoading: false,
};

export const dataSetReducer = baseReducerHelper.createAuthAwareReducer(defaultState, {
  [dataSetActionTypes.UPDATE_DATA_SET]: commonActionHandler,
  [dataSetActionTypes.LOAD_DATA_SETS]: commonActionHandler,
  [dataSetActionTypes.CHANGE_DATA_SET]: commonActionHandler
});
