//@flow
//
// Heavily inspired from:
// https://github.com/KBLNY/react-native-message-bar
//
import * as React from "react";
import {
  Text,
  View,
  ScrollView,
  Image,
  Animated,
  Dimensions,
  TouchableOpacity
} from "react-native";

export type InAppNotificationCoreProps = {
  shouldHideAfterDelay?: boolean,
  duration?: number,
  shouldHideOnTap?: boolean,
  onTapped?: ?Function,
  onShow?: Function,
  onHide?: Function,
  durationToShow?: number,
  durationToHide?: number,
  position?: string,
  animationType?: string,
  animationTypeTransform?: string,
  children?: any
};
export type InAppNotificationCoreState = InAppNotificationCoreProps;
let windowWidth = Dimensions.get("window").width;
let windowHeight = Dimensions.get("window").height;
export default class InAppNotificationCore extends React.Component<
  InAppNotificationCoreProps,
  InAppNotificationCoreState
> {
  animatedValue: Animated.Value;
  notifyAlertHiddenCallback: ?Function;
  alertShown: boolean;
  timeoutHide: any;
  animationTypeTransform: ?Array<Object>;

  constructor(props: InAppNotificationCoreProps) {
    super(props);

    this.animatedValue = new Animated.Value(0);
    this.notifyAlertHiddenCallback = null;
    this.alertShown = false;
    this.timeoutHide = null;
    this.animationTypeTransform = null;
    this.state = this.getStateByProps(props);
  }

  getDurationStateByProps(props: InAppNotificationCoreProps) {
    return {
      duration: props.duration || 3000,
      durationToShow: props.durationToShow || 350,
      durationToHide: props.durationToHide || 350
    };
  }

  getStateByProps(props: InAppNotificationCoreProps) {
    return {
      animationTypeTransform: "SlideFromTop", // default value
      /* Hide setters */
      shouldHideAfterDelay: props.shouldHideAfterDelay || true,
      shouldHideOnTap: props.shouldHideOnTap || true,
      /* Callbacks method on Alert Tapped, on Alert Show, on Alert Hide */
      onTapped: props.onTapped,
      onShow: props.onShow,
      onHide: props.onHide,
      /* Position of the alert and Animation Type the alert is shown */
      position: props.position || "top",
      animationType: props.animationType,
      ...this.getDurationStateByProps(props)
    };
  }

  get animationType() {
    let position = this.state.position;
    let animationType = this.state.animationType;
    if (animationType == null) {
      if (position === "bottom") {
        animationType = "SlideFromBottom";
      } else {
        // Top by default
        animationType = "SlideFromTop";
      }
    }
    return animationType;
  }

  /*
  * Set the animation transformation depending on the chosen animationType, or depending on the state's position if animationType is not overridden
  */
  _apllyAnimationTypeTransformation() {
    var animationY = [];
    switch (this.animationType) {
      case "SlideFromTop":
        animationY = this.animatedValue.interpolate({
          inputRange: [0, 1],
          outputRange: [-windowHeight, 0]
        });
        this.animationTypeTransform = [{ translateY: animationY }];
        break;
      case "SlideFromBottom":
        animationY = this.animatedValue.interpolate({
          inputRange: [0, 1],
          outputRange: [windowHeight, 0]
        });
        this.animationTypeTransform = [{ translateY: animationY }];
        break;
    }
  }

  /*
  * Show the alert
  */
  showMessageBarAlert() {
    // If an alert is already shonw, do nothing
    if (this.alertShown) {
      return;
    }

    // Set the data of the alert in the state
    this.alertShown = true;

    // Display the alert by animating it from the top of the screen
    // Auto-Hide it after a delay set in the state
    Animated.timing(this.animatedValue, {
      toValue: 1,
      duration: this.state.durationToShow
    }).start(this._showMessageBarAlertComplete());
  }

  /*
  * Hide the alert after a delay, typically used for auto-hidding
  */
  _showMessageBarAlertComplete() {
    // Execute onShow callback if any
    this._onShow();

    // If the duration is null, do not hide the
    if (this.state.shouldHideAfterDelay) {
      this.timeoutHide = setTimeout(() => {
        this.hideMessageBarAlert();
      }, this.state.duration);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: InAppNotificationCoreProps) {
    this.setNewState(nextProps);
  }

  setNewState(state: InAppNotificationCoreState | Object) {
    // Set the new state, this is triggered when the props of this MessageBar changed
    this.setState(this.getStateByProps(state));
  }

  /*
  * Hide the alert, typically used when user tap the alert
  */
  hideMessageBarAlert() {
    // Hide the alert after a delay set in the state only if the alert is still visible
    if (!this.alertShown) {
      return;
    }

    clearTimeout(this.timeoutHide);

    // Animate the alert to hide it to the top of the screen
    Animated.timing(this.animatedValue, {
      toValue: 0,
      duration: this.state.durationToHide
    }).start(this._hideMessageBarAlertComplete());
  }

  /*
  * Callback executed when alert is shown
  */
  _onShow() {
    if (this.state.onShow) {
      this.state.onShow();
    }
  }

  /*
  * Callback executed when alert is hidden
  */
  _onHide() {
    if (this.state.onHide) {
      this.state.onHide();
    }
  }

  _hideMessageBarAlertComplete() {
    // The alert is not shown anymore
    this.alertShown = false;
    // Execute onHide callback if any
    this._onHide();
  }

  /*
  * Callback executed when the user tap the alert
  */
  _alertTapped() {
    // Hide the alert
    if (this.state.shouldHideOnTap) {
      this.hideMessageBarAlert();
    }

    // Execute the callback passed in parameter
    if (this.state.onTapped) {
      this.state.onTapped();
    }
  }

  render() {
    // Set the animation transformation depending on the chosen animationType, or depending on the state's position if animationType is not overridden
    this._apllyAnimationTypeTransformation();

    return (
      <Animated.View
        style={{
          transform: this.animationTypeTransform,
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          paddingRight: 0
        }}
      >
        <TouchableOpacity
          onPress={() => {
            this._alertTapped();
          }}
          style={{ flex: 1 }}
        >
          {this.props.children}
        </TouchableOpacity>
      </Animated.View>
    );
  }
}
