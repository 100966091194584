//@flow
import type { IconAssetType } from "../../icon/assets";
import type { BaseComponentProps } from "../../base-component";
import * as React from "react";
import { Text, View, ScrollView, Image } from "react-native";
import { H3 } from "../../texts";
import { Icon, iconAssets } from "../../icon";
import { iconSizes } from "../../icon/IconSize";
import data from "../../../data";
import { Tappable } from "../../tappable";
import { colors } from "cockpit-shared/src/constants";

export type IconTextNotificationProps = BaseComponentProps & {
  message: string,
  icon?: IconAssetType,
  onTap?: Function,
};
export type IconTextNotificationState = {};
export default class IconTextNotification extends React.Component<
  IconTextNotificationProps,
  IconTextNotificationState
> {

  render() {
    return (
      <Tappable style={{flex: 1}} onTap={this.props.onTap}>
        <View
          style={{
            flex: 1,
            paddingVertical: 25,
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
            elevation: 10,
            shadowOpacity: 1,
            shadowColor: colors.darkBlue,
            shadowRadius: 10,
            shadowOffset: { width: 0, height: 1 },
            marginBottom: 15,
            ...this.props.style,
          }}
        >
          <H3 style={{ color: "white" }}>{this.props.message}</H3>
          {this.props.icon ? (
            <Icon
              style={{ marginLeft: 37 }}
              size={iconSizes.TINY}
              name={this.props.icon}
            />
          ) : null}
        </View>
      </Tappable>
    );
  }
}
