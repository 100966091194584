//@flow
import React, { Component } from "react";
import { connect } from "react-redux";
import type { BaseComponentProps } from "../base-component";
import { iconAssets } from "../icon";
import { Logo } from "../logo";
import { HeaderNavigator } from "../header-navigator";
import { DataSetSwitcher } from "../data-set-switcher";
import "./Header.css";
import { Link } from "react-router-dom";
import { ContextualPage } from "../contextual-page";
import { removeAllExtraStyle } from "../header-navigator/HeaderNavigator";
import { CategoryContainerTabs } from "../charts-container/tab/CategoryContainerTabs";

export type HeaderProps = BaseComponentProps & {
  title?: string,
  displayName: string,
  fromHome: boolean,
  style: any,
  categoryTabPress: (category: Object) => void
};
export type HeaderState = {};

class Header extends Component<HeaderProps, HeaderState> {

  constructor(props) {
    super(props);

    this.state = {
      showContextuals: false,
      content: "",
      title: ""
    };
  }

  onCategoryPressed(category: Object) {
    this.props.categoryTabPress(category)
  }

  renderLeftView() {
    return (
      <div className="header-left-view">
        <CategoryContainerTabs
          categoriesState={this.props.categoriesState}
          onTabPress={this.onCategoryPressed.bind(this)}
          selectedCategoryName={this.props.title}
        />
      </div>
    );
  }

  renderDropdown() {
    return (
      <DataSetSwitcher {...this.props} />
    );
  }

  renderLogo() {
    return (
      <Link to="/">
        <Logo />
      </Link>
    );
  }

  onItemClick = (content, title) => {
    if (title === "profile") {
      //This case is treated in HeaderNavigator class
      return;
    }
    if (this.state.title === title) {
      removeAllExtraStyle();
    }
    this.setState({
      showContextuals: this.state.title !== title ? true : false,
      content: this.state.title !== title ? content : "",
      title: this.state.title !== title ? title : ""
    });
  }

  closeContextual = () => {
    removeAllExtraStyle();
    this.setState({
      showContextuals: false,
      content: "",
      title: ""
    });
  }

  renderOptionsView() {
    return <HeaderNavigator
      profileIcon={iconAssets.userProfile}
      userName={this.props.displayName}
      onItemClick={this.onItemClick}
      isGlossaryDisabled={this.props.fromHome}
    />;
  }

  renderRightView() {
    return (
      <div className="header-right-view">
        {this.renderDropdown()}
        <div className="header-options">
          {this.renderOptionsView()}
        </div>
        {this.renderLogo()}
      </div>
    );
  }

  render() {
    return (
      <div  style={{ flexDirection: "column", zIndex: 1, ...this.props.style }} >
        <div className="header-container">
          {this.renderLeftView()}
          {this.renderRightView()}
        </div>
        {this.state.showContextuals &&
          <ContextualPage
            content={this.state.content}
            title={this.state.title}
            close={this.closeContextual}
          />
        }
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  let authStore = state.authenticationStore;
  return {
    displayName: authStore.firstName + " " + authStore.lastName,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
