//@flow

var getNearestPowerOfTen = function(arr) {
  var max = arr.reduce(function(p, c) {
    // is the previous less than the current item?
    // if so, then return the current item
    // otherwise, return the previous item
    return p < c ? c : p;
  }, 0);
  // to find the next highest power of 10,
  // we should return 10 raised to the number of digits
  // in the integer part of our number
  return Math.pow(10, (max | 0).toString().length);
};

export function convertNumberArrayToPercentages(array: Array<number>) {
  var max = getNearestPowerOfTen(array);
  let percentages: Array<number> = array.map(function(d, i) {
    return (100 * d / max) | 0;
  });
  return percentages;
}
