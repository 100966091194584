//@flow
import type { BaseComponentProps } from "../base-component";
import * as React from "react";
import { Text, View, ScrollView, Image, TouchableOpacity } from "react-native";
import { propNotifier } from "../prop-notifier/index";

export type TappableProps = BaseComponentProps & {
  onTap?: () => void,
  isDisabled?: boolean,
  containerStyle?: any
};
export type TappableState = {};
export default class Tappable extends React.Component<TappableProps, TappableState> {

  onPress() {
    propNotifier.safeNotify(this.props.onTap);
  }

  get isDisabled() {
    return this.props.isDisabled || false;
  }

  render() {
    return (
      <TouchableOpacity disabled={this.isDisabled} activeOpacity={0.5} onPress={this.onPress.bind(this)} style={this.props.containerStyle}>
        <View style={this.props.style}>{this.props.children}</View>
      </TouchableOpacity>
    );
  }
}
