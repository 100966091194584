//@flow

type dataSetJsonType = {
  id: string,
  name: string,
  current_month: string,
  default: boolean,
};

export default class DataSetViewModel {
  json: dataSetJsonType;

  constructor(json: dataSetJsonType) {
    this.json = json;
  }

  get id() {
    return this.json.id;
  }

  get name() {
    return this.json.name;
  }

  get currentMonth() {
    //Current month starts from 1 in the api
    return parseInt(this.json.current_month) - 1;
  }

  get isDefault() {
    return this.json.default;
  }
}
