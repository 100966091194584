//@flow
import type { BaseComponentProps } from "../base-component/BaseComponent";
import * as React from "react";
import { Text, View, ScrollView, Image } from "react-native";
import data from "../../data";

export type HProps = {
  style?: ?Object,
  children: ?string,
  isUnderline?: boolean,
  adjustsFontSizeToFit?: boolean,
  numberOfLines?: number
};
export type HState = {};
export default class H extends React.Component<HProps, HState> {
  get style() {
    return { ...this.props.style, fontFamily: "-apple-system, BlinkMacSystemFont, sans-serif"};
  }

  get textDecorationLine() {
    return this.props.isUnderline ? "underline" : "none";
  }

  get optionalProps() {
    return this.props.numberOfLines
      ? { numberOfLines: this.props.numberOfLines }
      : null;
  }

  render() {
    return (
      <Text
        adjustsFontSizeToFit={this.props.adjustsFontSizeToFit}
        {...this.optionalProps}
        textDecorationLine={this.textDecorationLine}
        style={this.style}
      >
        {this.props.children}
      </Text>
    );
  }
}
