//@flow
import * as React from "react";
import data from "../../data";
import { H4 } from ".";
import {version} from "../../../package.json";

export default class AppVersionText extends React.Component<> {
    get appVersion() {
        var splitPjson = version.split(".");
        return `${splitPjson[0]}.${splitPjson[1]}`;
    }

    get buildNumber() {
        return version.split(".")[2];
    }

    render() {
        return (<H4 style={{ color: "white", alignSelf: "center" }}>
            {data.strings.stringsRepo.appVersion} {this.appVersion} ({this.buildNumber})
        </H4>);
    }
}