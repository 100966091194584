//@flow
import * as React from "react";
import { View } from "react-native";
import { constants } from "cockpit-shared";
import data from "../../../../data";
import * as chartData from "./BubbleChartData"
import Chart from "chart.js";
import "../../../app/App.css";

type ChartBubbleEntry = {
  x: number,
  y: number,
  size: number,
  marker: string,
  isNegativeValue: boolean
};

export type BubblesChartProps = {
  values: Array<ChartBubbleEntry>,
  yAxisTitle: string,
  xAxisTitle: string,
  isLoading: boolean,
  isColorInverted: boolean,
  axisInverted: boolean,
  budgetTagColor: string,
  previousYearTagColor: string,
  isSpecialAxisInversionSchema: boolean
};

type ComputedChartValuesModel = {
  minX: number,
  maxX: number,
  minY: number,
  maxY: number,
  computedValuesWhite: ChartBubbleEntry[],
  computedValuesBlack: ChartBubbleEntry[]
};

export type BubblesChartState = {
  data: Object,
  yAxis: Object,
  xAxis: Object
};

export default class BubblesChart extends React.Component<BubblesChartProps, BubblesChartState> {
  chartRef;

  constructor(props: BubblesChartProps) {
    super(props);
    this.chartRef = React.createRef();
    this.state = {
      data: {
        dataSets: [
          {
            values: [],
            config: {
              valueTextColor: "white",
            },
            label: "Some label"
          },
          {
            values: [],
            config: {
              valueTextColor: "black",
            },
            label: "Some label"
          }
        ]
      },
      yAxis: {
        title: this.yAxisTitle,
        // inverted : this.props.axisInverted
      },
      xAxis: {
        title: this.xAxisTitle,
        // valueFormatter: this.props.axisInverted ? "inverted" : undefined
      }
    };
  }

  componentDidMount() {
    const myChartRef = this.chartRef.current;
    this.props.isLoading ? null :
      window.myLine = new Chart(myChartRef, {
        type: "bubble",
        data: chartData.bubbleChartData(this.state.data.dataSets),
        options: chartData.bubbleChartOptions(this.state.xAxis, this.state.yAxis)
      });
  }

  static createColors(
    values: Array<Object>,
    props: BubblesChartProps
  ): Array<?number> {
    return (values || []).map((value: ChartBubbleEntry) => {
      let goodColor = constants.colors.bubblePositiveColor;
      let badColor = constants.colors.bubbleNegativeColor;
      let getGoodColor = (isInverted: boolean) => {
        return isInverted ? badColor : goodColor;
      };
      let getBadColor = (isInverted: boolean) => {
        return isInverted ? goodColor : badColor;
      };
      //At one point in the algorithm, sizes are made absolute values and
      // the field isNegativeValue is set to retain the needed information about their size
      let isNegative =
        typeof value.isNegativeValue !== "undefined"
          ? value.isNegativeValue
          : value.size < 0;
      let colorString = isNegative
        ? getBadColor(props.isColorInverted)
        : getGoodColor(props.isColorInverted);
      return colorString;
    });
  }

  //This method assures that bubbles do not overlay and are in the range of axes
  static computeChartValues(
    values: ChartBubbleEntry[]
  ): ComputedChartValuesModel {
    let defaultConfig = {
      minX: -10,
      maxX: +10,
      minY: -10,
      maxY: +10,
      computedValuesWhite: [],
      computedValuesBlack: []
    };
    let numberOfValues = (values || []).length;
    if (numberOfValues === 0) {
      return defaultConfig;
    }
    //Sort array depending on x
    let sortedValues: ChartBubbleEntry[] =
      values.slice(0, numberOfValues) || [];
    sortedValues = sortedValues.map((value, index) => {
      return {
        ...value,
        size:
          typeof value.size === "undefined" || value.size === null
            ? 0
            : value.size,
        x: typeof value.x === "undefined" || value.x === null ? 0 : value.x,
        y: typeof value.y === "undefined" || value.y === null ? 0 : value.y
      };
    });
    sortedValues.sort((v1, v2) => {
      return v1.x === v2.x ? v1.size - v2.size : v1.x - v2.x;
    });
    //Make sizes absolute
    sortedValues = sortedValues.map((v, index) => {
      return {
        ...v,
        size: Math.abs(v.size),
        isNegativeValue: v.size < 0
      };
    });

    //Get some basic data about the data set
    let minXIndex = 0,
      maxXIndex = numberOfValues - 1,
      minYIndex = 0,
      maxYIndex = 0,
      minSizeIndex = 0,
      maxSizeIndex = 0;
    sortedValues.forEach((v, index) => {
      //Save data
      if (sortedValues[minYIndex].y > v.y) {
        minYIndex = index;
      }
      if (Math.abs(sortedValues[minSizeIndex].size) > Math.abs(v.size)) {
        minSizeIndex = index;
      }
      if (sortedValues[maxYIndex].y < v.y) {
        maxYIndex = index;
      }
      if (Math.abs(sortedValues[maxSizeIndex].size) < Math.abs(v.size)) {
        maxSizeIndex = index;
      }
    });
    //Compute the axis length: (one half of the total axis)
    let maxOnX = Math.max(
      Math.abs(sortedValues[minXIndex].x), // absolute value of minimum x
      Math.abs(sortedValues[maxXIndex].x) // absolute value of maximum x
    );
    let maxOnY = Math.max(
      Math.abs(sortedValues[minYIndex].y), // absolute value of minimum y
      Math.abs(sortedValues[maxYIndex].y) // absolute value of maximum y
    );
    let mininumAcceptableMax = 10;
    let axisLength = Math.max(mininumAcceptableMax, maxOnX, maxOnY);
    let maxSize = Math.abs(sortedValues[maxSizeIndex].size) || 1;
    //Set the maximum accepted size
    let extraSize = 30;
    let maxAcceptableSize = 55;
    //Normalization function
    //After applying the normalization:
    // - the biggest size will be equal to maxAcceptableSize
    // - all sizes will be relative to maxAcceptableSize
    let normalizeSize = size => {
      let result = Math.ceil(
        maxAcceptableSize * (Math.abs(size) / Math.abs(maxSize)) || 1
      );
      return Math.max(result, 5);
    };
    //Normalize sizes
    let computedValues: ChartBubbleEntry[] = sortedValues.map(v => {
      return {
        ...v,
        size: normalizeSize(v.size)
      };
    });
    //increas axisLength, so all the bubbles can be drawn inside the chart
    let extraAxisLength = axisLength / 2;
    axisLength += extraAxisLength;
    let xLength = axisLength;
    let yLength = axisLength;
    let minimumPredicate = (v: ChartBubbleEntry) => v.size > extraSize;
    let response = {
      maxX: xLength,
      maxY: yLength,
      minX: -xLength,
      minY: -yLength,
      computedValuesWhite: computedValues.filter(minimumPredicate),
      computedValuesBlack: computedValues.filter(
        value => !minimumPredicate(value)
      )
    };
    return response;
  }

  static equalArray(array1, array2) {
    return (
      array1.length === array2.length && array1.every((v, i) => v === array2[i])
    );
  }

  static getDerivedStateFromProps(
    newProps: BubblesChartProps,
    state: BubblesChartState
  ): ?BubblesChartState {
    let newValues = newProps.values;
    //The chart is rendered too many times either when opening the bubble chart,
    //or when choosing a new dimension from the spinner(it renders two times with the old
    //values and one time with the new one).
    //Do not change the state if the app is loading
    //There are more causes for these renders and should be investigated
    if (newProps.isLoading) {
      return null;
    }
    let {
      minX,
      maxX,
      minY,
      maxY,
      computedValuesWhite,
      computedValuesBlack
    } = BubblesChart.computeChartValues(newValues);

    /**
     * If the x axis must be inverted, we revers bubble's values
     * Along with the "invert" axis formatter, the x axis will look
     * like it is inverted
     */
    var inverseFunction = null;
    if (newProps.axisInverted) {
      inverseFunction = v => {
        return { ...v, x: -v.x };
      };
    } else if (newProps.isSpecialAxisInversionSchema) {
      /**
       * If axis_inversion_schema is "special" and YTD value is >= 0,
       * we invert X and Y coords for that entry.
       * Requested in APP-215.
       */
      let invertCoordWithPositiveYTD = (isPositiveYtd, value) =>
        isPositiveYtd ? -value : value;
      inverseFunction = v => {
        return {
          ...v,
          y: invertCoordWithPositiveYTD(!v.isNegativeValue, v.y),
          x: invertCoordWithPositiveYTD(!v.isNegativeValue, v.x)
        };
      };
    }
    if (inverseFunction != null) {
      computedValuesWhite = computedValuesWhite.map(inverseFunction);
      computedValuesBlack = computedValuesBlack.map(inverseFunction);
      computedValuesWhite.sort((a, b) => a.x - b.x);
      computedValuesBlack.sort((a, b) => a.x - b.x);
    }

    //Only add the dataSets with some values
    let dataSets = [];
    if (computedValuesWhite.length) {
      dataSets.push({
        ...state.data.dataSets[0],
        values: computedValuesWhite,
        config: {
          ...state.data.dataSets[0].config,
          colors: BubblesChart.createColors(computedValuesWhite, newProps),
          valueTextColor: "white"
        },
        label: "Label"
      });
    }
    if (computedValuesBlack.length) {
      dataSets.push({
        ...state.data.dataSets[1],
        values: computedValuesBlack,
        config: {
          ...state.data.dataSets[0].config,
          colors: BubblesChart.createColors(computedValuesBlack, newProps),
          valueTextColor: constants.colors.grayBlue
        },
        label: "Label"
      });
    }

    return {
      ...state,
      data: {
        dataSets
      },
      yAxis: {
        ...state.yAxis,
        axisMinimum: minY,
        axisMaximum: maxY,
        // inverted: newProps.axisInverted
      },
      xAxis: {
        ...state.xAxis,
        axisMinimum: minX,
        axisMaximum: maxX
      }
    };
  }

  computeMaximumAbsoluteChartValue(chartValues: Array<Object>) {
    let xAbsMax = Math.max(...chartValues.map(value => Math.abs(value.x)));
    let yAbsMax = Math.max(...chartValues.map(value => Math.abs(value.y)));
    return Math.max(xAbsMax, yAbsMax);
  }

  get xAxisTitle() {
    return this.props.xAxisTitle && this.props.xAxisTitle.toUpperCase();
  }

  get yAxisTitle() {
    return this.props.yAxisTitle && this.props.yAxisTitle.toUpperCase();
  }

  shouldComponentUpdate(
    nextProps: BubblesChartProps,
    nextState: BubblesChartState
  ) {
    return nextProps.isLoading !== this.props.isLoading;
  }

  handleDoubleClick = () => {
    window.myLine.resetZoom();
  }

  render() {
    return (
      <View style={{ flex: 1, backgroundColor: "white" }}>
        <View style={{ flex: 1 }} >
          {this.props.isLoading ? null : (
            <div className="chart-container" onDoubleClick={this.handleDoubleClick}>
              <canvas
                id="myChart"
                ref={this.chartRef}
              />
            </div>
          )}
        </View>
      </View>
    );
  }
}
