//@flow
import { localizedNumberFormatterInstance } from "../number";

export const NO_VALUE = "na";
export type noValueType = "na";
export type valueType = number | noValueType;

export function formatChartValue(value: ?number, isPercentage: boolean) {
  if (value == null) {
    return NO_VALUE;
  }
  return localizedNumberFormatterInstance.getNumberFormatter().formatChartMarker(value, isPercentage);
}

export function formatTagValue(value: ?number, isPercentage: boolean) {
  if (value == null) {
    return NO_VALUE;
  }
  return localizedNumberFormatterInstance.getNumberFormatter().formatChartTag(value, isPercentage);
}

export function validateValue(value: string | number) {
  if (typeof value === "string") {
    return null;
  }
  return value;
}


export function prepareValuesForChart(values: Array<number | string>): Array<number> {
  return values.map((value: number | string) => {
    if (typeof value !== "number") { 
      return 0;
    }
    return value;
  });
}
