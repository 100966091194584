//@flow

export default class FetchProvider {
  fetch: ?Function;

  constructor() {
    this.fetch = null;
  }

  setFetch(fetch: Function) {
    this.fetch = fetch;
  }

  getFetch(): ?Function {
    return this.fetch;
  }
}

export const fetchProviderInstance = new FetchProvider();
