//@flow
import type { BaseComponentProps } from "../base-component";
import * as React from "react";
import { View, SectionList, FlatList } from "react-native";

export type CategoryListProps = {
  categories: Array<Object>,
  renderItem: Function,
  renderCategoryHeader: Object,
  getKey: (item: any, index: number) => string,
  extraData?: Object,
};
export type CategoryListState = {};

export default class CategoryList extends React.PureComponent<
  CategoryListProps,
  CategoryListState
> {
  render() {
    return (
      <SectionList
        extraData={this.props.extraData}
        sections={this.props.categories}
        renderItem={this.props.renderItem}
        renderSectionHeader={this.props.renderCategoryHeader}
        keyExtractor={this.props.getKey}
        stickySectionHeadersEnabled={true}
      />
    );
  }
}
