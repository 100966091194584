//@flow
import { combineReducers } from "redux";
import type { FetchReducerState } from "../fetch/FetchReducer";
import type { AuthenticationReducerState } from "../authentication/AuthenticationReducer";
import type { InAppNotificationReducerState } from "../in-app-notification/InAppNotificationReducer";
import type { PersistConfig } from "redux-persist/es/types";
import { Store } from "redux";
import { authenticationReducer } from "../authentication";
import { inAppNotificationReducer } from "../in-app-notification";
import { connectivityReducer } from "../connectivity";
import { dataSetReducer, dataSetsTransform } from "../data-set";
import { persistStore, persistReducer } from "redux-persist";
import { categoriesReducer } from "../categories";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import { dimensionsReducer } from "../dimensions";
import { organizationsReducer } from "../organizations";
import { chartsReducer } from "../charts";
import { entitiesRankingReducer } from "../entities-ranking";
import { notificationsReducer } from "../notifications";

export type RootReducerState = Store & {
  dispatch: Function,
  authenticationStore: AuthenticationReducerState,
  inAppNotificationStore: InAppNotificationReducerState
};

const localReducers = {
  authenticationStore: authenticationReducer,
  inAppNotificationStore: inAppNotificationReducer,
  connectivityStore: connectivityReducer,
  categoriesStore: categoriesReducer,
  dataSetStore: dataSetReducer,
  dimensionsStore: dimensionsReducer,
  organizationsStore: organizationsReducer,
  chartsStore: chartsReducer,
  entitiesRankingStore: entitiesRankingReducer,
  notificationsStore: notificationsReducer
};

export function createRootReducer(
  reducers: ?Object,
  persistConfig: PersistConfig
): RootReducerState {
  let _persistConfig = {
    key: "root",
    blacklist: [
      "inAppNotificationStore",
      "navigationStore",
      "homeNavigatorStore",
      "categoriesStore",
      "organizationsStore",
      "organizationDrilldownNavigatorStore",
      "dimensionsStore",
      ...(persistConfig.blacklist || [])
    ],
    transforms: [dataSetsTransform],
    storage: persistConfig.storage,
    version: 1,
    stateReconciler: autoMergeLevel2
  };
  let rootReducer = {
    ...localReducers,
    ...reducers
  };
  let combinedReducer = combineReducers(rootReducer);
  let persistedReducer = persistReducer(_persistConfig, combinedReducer);
  return persistedReducer;
}
