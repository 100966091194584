//@flow
const iconAssets = {
  noInternet: "connection-lost",
  email: "mail",
  lock: "password",
  question: "help",
  userProfile: "account",
  settings: "prozess",
  productivity: "productivitat",
  bookmarks: "bookmarks",
  bookmarked: "bookmarked",
  arrowBack: "angle-left",
  home: "home2",
  arrowRight: "angle-right",
  date: "date",
  glossary: "bookmarks2",
  notification: "bell",
  warning: "warning",
  entityChartTab: "absolute-big",
  entityHistoryChartTab: "tab3-active",
  bubbleChartTab: "tab1-active",
  percentage: "percentage",
  absolute: "absolute",
  angleDownCircle: "angle-down-circle",
  angleUpCircle: "angle-up-circle",
  close: "close",
  arrowDown: "angle-down",
  logout: "logout",
  chartMenuPercent: "percent-big",
  ranking: "ranking-big",
  noData:"no-data-found-v2",
  check:"check",
  fullScreen:"full-screen-icon"
};

export { iconAssets };
export type IconAssetType = $Values<typeof iconAssets>;
