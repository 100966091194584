//@flow
import type { ChartActionType } from "./ChartActionTypes";
import type { DataFormatType } from "./DataFormats";
import { chartActionTypes } from "./ChartActionTypes";
import { CHART_TABS_ID } from "./ChartTabs";
import { dataFormatTypes } from "./DataFormats";
import { baseReducerHelper } from "../base";
import { commonActionHandler } from "../base/BaseActions";

export type ChartReducerState = {
  isLoading: boolean,
  spinnerEnabled: boolean,
  category: any,
  data: any,
  formatType: DataFormatType
};

export type ChartAction = {
  type: ChartActionType,
  payload: Object
};

let defaultState: ChartReducerState = {
  isLoading: true,
  spinnerEnabled: false,
  data: null,
  formatType: dataFormatTypes.percentage,
  selectedTabId: CHART_TABS_ID.BUBBLE,
};

export const chartsReducer = baseReducerHelper.createAuthAwareReducer(
  defaultState,
  {
    [chartActionTypes.CHOOSE_METRIC]: commonActionHandler,
    [chartActionTypes.CHOOSE_ORGANISATION]: commonActionHandler,
    [chartActionTypes.SWITCH_TAB]: commonActionHandler,
    [chartActionTypes.LOAD_HISTORY_CHART_DATA]: commonActionHandler,
    [chartActionTypes.CHANGE_FORMAT_TYPE]: commonActionHandler,
    [chartActionTypes.TOGGLE_SPINNER]: commonActionHandler,
    [chartActionTypes.RESET_STATE]: () => {
      return defaultState;
    }
  }
);
