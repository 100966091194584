//@flow
export default class CategoryModel {
  id: string;
  name: string;
  icon: string;
  children: Array<CategoryModel>;

  constructor(id: string, name: string, icon: string, children: Array<CategoryModel>) {
    this.id = id;
    this.name = name;
    this.icon = icon;
    this.children = children;
  }
}
