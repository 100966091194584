//@flow
import React, { Component } from "react";
import { Image, Animated, View } from "react-native";
import { Tappable } from "../tappable";
import * as icon from "../icon";
import { constants } from "cockpit-shared";
import { H2 } from "../texts";
import "./MenuButton.css";

export type MenuButtonProps = {
  iconName?: string,
  iconUrl?: string,
  title: string,
  backgroundColor?: string,
  onPress: () => void,
  isDisabled: boolean,
  style?: any,
  categoryChildren: Object,
};
export type MenuButtonState = {
  opacityValue: Animated.Value
};
export const MENU_BUTTON_HEIGHT = 154;

export default class MenuButton extends Component<
  MenuButtonProps,
  MenuButtonState
  > {

  constructor(props: MenuButtonProps) {
    super(props);
    this.state = {
      opacityValue: new Animated.Value(0),
      showExtra: false,
      categoryClicked: false
    };
    this.props.categoryChildren.map((item) => {
      Image.prefetch(item.icon);
    });
  }

  onButtonPress = () => {
    //Abort this action if category was clicked
    if (this.state.categoryClicked) {
      return;
    }

    if (this.props.categoryChildren.length !== 0) {
      //to show/hide subcategories of a category if one has them
      this.setState({ showExtra: !this.state.showExtra });
      return;
    }

    if (this.props.onPress) {
      this.props.onPress();
    }
  }

  onCategoryClicked = (id) => {
    this.setState({ categoryClicked: true });
    this.props.onPress(id);
    return;
  }

  static getBorderStyle() {
    return {
      borderTopWidth: 2,
      borderTopColor: constants.colors.homePageSeparator
    };
  }

  componentDidMount() {
    setTimeout(() => {
      Animated.timing(this.state.opacityValue, {
        toValue: 1,
        duration: 1000
      }).start();
    }, 200);
  }

  classicView() {
    return (
      <Tappable
        isDisabled={this.props.isDisabled}
        style={{
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Animated.View
          style={{
            opacity: this.state.opacityValue, //this.props.isDisabled ? 0.3 : 1,
            alignItems: "center",
            justifyContent: "center",
            transform: [
              {
                translateX: this.state.opacityValue.interpolate({
                  inputRange: [0, 1],
                  outputRange: [-10, 0]
                })
              }
            ]
          }}
        >
          {this.props.iconName ? (
            <icon.Icon
              name={this.props.iconName}
              color={"black"}
              size={icon.iconSizes.S46}
              style={{
                alignSelf: "center"
              }}
            />
          ) : (
              <NetworkImage iconUrl={this.props.iconUrl} />
            )}
          <div className="category-text" >
            {this.props.title}
          </div>
        </Animated.View>
      </Tappable>
    );
  }

  categoryCildrenView() {
    return (
      <div className="category-container" >
        <div className="category-column">
          {this.props.categoryChildren.map(
            (subCategoryModel, index) => {
              return (
                <div className="category-item-h">
                  <Tappable onTap={() => this.onCategoryClicked(subCategoryModel)}>
                    <div className="category-item">
                      <NetworkImage
                        size={icon.iconSizes.TINY}
                        resizeMode={"center"}
                        color={"white"}
                        iconUrl={subCategoryModel.icon} />
                      <H2 style={{ marginStart: 12, color: "white" }}>{subCategoryModel.name}</H2>
                    </div>
                  </Tappable>
                </div>
              );
            }
          )}
        </div>
      </div>
    );
  }

  onMouseEnterEvent = (e) => {
    if (this.props.categoryChildren.length !== 0) {
      this.setState({ showExtra: true });
      document.getElementById(this.props.id).classList.add("content-hover");
    } else {
      document.getElementById(this.props.id).classList.add("content-hover");
      this.setState({ showExtra: false });
    }
  }

  onMouseLeave = (e) => {
    this.setState({ showExtra: false });
    document.getElementById(this.props.id).classList.remove("content-hover");
  }

  render() {
    return (
      <div className="content" id={this.props.id}
        onMouseEnter={this.onMouseEnterEvent}
        onMouseLeave={this.onMouseLeave}
        onClick={this.onButtonPress}
      >
        <div className="content-to-hover">
          {!this.state.showExtra ? this.classicView() : this.categoryCildrenView()}
        </div>
      </div>
    );
  }
}

export const NetworkImage = props => {
  return props.iconUrl != null && props.iconUrl.length > 0 ? (
    <Image
      resizeMode={props.resizeMode}
      source={{ uri: props.iconUrl }}
      style={{
        alignSelf: "center",
        width: props.size || 46,
        height: props.size || 46,
        tintColor: props.color || null,
        ...props.style,
      }}
    />
  ) : null;
};
