//@flow
import numeral from "numeral";

let deLocaleCommon = {
  abbreviations: {
    thousand: "k",
    million: "m",
    billion: "b",
    trillion: "t"
  },
  ordinal: number => {
    return ".";
  }
};

let localeCodes = {
  en: "en",
  deDE: "de-de",
  deCH: "de-ch"
};

let defaultLocale = {
  code: localeCodes.en,
  numeralConfig: {
    delimiters: {
      thousands: ",",
      decimal: "."
    },
    currency: {
      symbol: "USD"
    }
  },
  isCurrencySymbolAtStart: true
};

let supportedLocales = [
  {
    code: localeCodes.deCH,
    numeralConfig: {
      delimiters: {
        thousands: "'",
        decimal: "."
      },
      currency: {
        symbol: "CHF"
      },
      ...deLocaleCommon
    },
    isCurrencySymbolAtStart: true
  },
  {
    code: localeCodes.deDE,
    numeralConfig: {
      delimiters: {
        thousands: ".",
        decimal: ","
      },
      currency: {
        symbol: "EUR"
      },
      ...deLocaleCommon
    },
    isCurrencySymbolAtStart: false
  },
  defaultLocale
];

function findLocaleConfig(languageCode: string) {
  let result = supportedLocales.find(
    item => item.code.toLowerCase() === languageCode.toLowerCase()
  );
  return result || defaultLocale;
}
var isNumeralConfigsRegistered = false;
function registerNumeralConfigs() {
  if(isNumeralConfigsRegistered) {
    return;
  }
  // Here we register some custom locales to match the ones from Native iOS and Android.
  let registerType = "locale";
  numeral.register(
    registerType,
    localeCodes.deCH,
    findLocaleConfig(localeCodes.deCH).numeralConfig
  );
  numeral.register(
    registerType,
    localeCodes.deDE,
    findLocaleConfig(localeCodes.deDE).numeralConfig
  );
  isNumeralConfigsRegistered = true;
}


export default class NumeralNumberFormattingService {
  _localeId: ?string;

  constructor(localeId: string) {
    registerNumeralConfigs();
    this._localeId = null;
    this.localeId = localeId;
  }

  set localeId(newLocaleId: string) {
    var finalLocaleId = newLocaleId;
    try {
      numeral.localeData(finalLocaleId);
    } catch (e) {
      finalLocaleId = localeCodes.en;
    } finally {
      this._localeId = finalLocaleId;
      numeral.locale(finalLocaleId);
    }
  }

  get localeId() {
    return this._localeId;
  }

  get isCurrencySymbolAtStart() {
    let _value = findLocaleConfig(this.localeId).isCurrencySymbolAtStart;
    return _value;
  }

  formatPercentValue(value: number, numberOfFractionDigits: number, includeNumberSign: ?boolean) {
    let formatConfig = this._createNumberFormatConfig(numberOfFractionDigits, "%");
    var stringValue = numeral(value).format(formatConfig);
    stringValue = this.addOptionalNumberSign(value, stringValue, includeNumberSign);
    return stringValue;
  }

  formatCurrency(
    value: number,
    currencySymbol: ?string,
    numberOfFractionDigits: ?number,
    includeNumberSign: ?boolean
  ) {
    return this.formatNumber(
      value,
      numberOfFractionDigits,
      currencySymbol,
      this.isCurrencySymbolAtStart,
      includeNumberSign
    );
  }

  arrangeStringWithSymbol(stringValue: string, symbol: string, isSymbolAtStart: boolean) {
    if (symbol) {
      let delimiter = "";
      let defaultIsSymbolAtStart = false;
      let _isSymbolAtStart = isSymbolAtStart != null ? isSymbolAtStart : defaultIsSymbolAtStart;
      return _isSymbolAtStart ? symbol + delimiter + stringValue : stringValue + delimiter + symbol;
    } else {
      return stringValue;
    }
  }

  addOptionalNumberSign(numberValue: number, stringValue: string, includeNumberSign: ?boolean) {
    let numberSign = includeNumberSign && numberValue && numberValue > 0 ? "+" : "";
    return numberSign + stringValue;
  }

  formatNumber(
    value: number,
    numberOfFractionDigits: ?number,
    symbol: ?string,
    isSymbolAtStart: ?boolean,
    includeNumberSign: ?boolean
  ) {
    let formatConfig = this._createNumberFormatConfig(numberOfFractionDigits);
    var stringValue = numeral(value).format(formatConfig);
    stringValue = this.addOptionalNumberSign(value, stringValue, includeNumberSign);
    return this.arrangeStringWithSymbol(stringValue, symbol, isSymbolAtStart);
  }

  _createNumberFormatConfig(numberOfFractionDigits: number, configSymbol: ?string) {
    var formatConfig = "0,0";
    if (numberOfFractionDigits && numberOfFractionDigits > 0) {
      formatConfig += "." + "0".repeat(numberOfFractionDigits);
    }
    if (configSymbol) {
      return (formatConfig += configSymbol);
    }
    return formatConfig;
  }

  getNumberOfFractionDigits(value: number) {
    return Number.isInteger(value) ? 0 : (value + "").split(".")[1].length;
  }

  formatChartValueCommon(value: number, isPercentage: boolean) {
    let numberOfFractionDigits = this.getNumberOfFractionDigits(value);
    return isPercentage
      ? this.formatNumber(value, numberOfFractionDigits, "%", false, false)
      : this.formatNumber(value, numberOfFractionDigits, "", false, false);
  }

  formatChartTag(value: number, isPercentage: boolean) {
    return this.formatChartValueCommon(value, isPercentage);
  }

  formatChartMarker(value: number, isPercentage: boolean) {
    return this.formatChartValueCommon(value, isPercentage);
  }

  formatRankingYtd(value: number) {
    let numberOfFractionDigits = this.getNumberOfFractionDigits(value);
    return this.formatNumber(value, numberOfFractionDigits, "", false, false);
  }
}
