//@flow
import type { NotificationsActionType } from "./NotificationsActionTypes";
import { notificationsActionTypes } from "./NotificationsActionTypes";
import { baseReducerHelper } from "../base";
import { commonActionHandler } from "../base/BaseActions";

export type NotificatonsReducerState = {
  isLoading: boolean,
  numberOfUnreadNotifications: number,
};

export type NotificationAction = {
  type: NotificationsActionType,
  payload: Object
};

let defaultState: NotificatonsReducerState = {
  isLoading: true,
  numberOfUnreadNotifications: 0,
};

export const notificationsReducer = baseReducerHelper.createAuthAwareReducer(
  defaultState,
  {
    [notificationsActionTypes.LOAD_NUMBER_OF_NEW_NOTIFICATIONS]: commonActionHandler,
    [notificationsActionTypes.UPDATE_LAST_NOTIFICATIONS_VISIT_TIME]: commonActionHandler,
  }
);
