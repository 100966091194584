//@flow
import React from "react";
import "./ReactImage.css";

/**
 * An <img/> component that loads an image from an URL
 * On hover it inverts the image's color (black -> white)
 * Using this element the browser will automatically cache the loaded image =>
 *    => no flickering issues
 */
export const ReactImage = props => {
  return props.iconUrl != null && props.iconUrl.length > 0 ? (
    <img id="subcategory-image"
      className={props.isHoverActive ? "image" : "invert-image"}
      src={props.iconUrl}
    />
  ) : null;
};