//@flow
import type { BaseComponentProps } from "../base-component";
import * as React from "react";
import { Text, View, ScrollView, Image } from "react-native";
import { Icon } from "../icon";
import { iconSizes } from "../icon/IconSize";
import { iconAssets } from "../icon/assets/index";
import { H3 } from "../texts";
import data from "../../data";
import { constants } from "cockpit-shared";

export type NoInternetViewProps = BaseComponentProps & {
  message?: string
};
export type NoInternetViewState = {};
export default class NoInternetView extends React.Component<
  NoInternetViewProps,
  NoInternetViewState
> {
  render() {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: "white",
          justifyContent: "center",
          alignItems: "center",
          ...this.props.style
        }}
      >
        <Icon
          color={constants.colors.errorColor}
          size={iconSizes.HUGE}
          name={iconAssets.noInternet}
        />
        <H3 style={{ color: constants.colors.errorColor, marginTop: constants.dimensions.defaultMargin }}>
          {data.strings.stringsRepo.noInternetConnection}
        </H3>
        <H3 style={{ color: constants.colors.accentColor, marginTop: 4 }}>{this.props.message}</H3>
      </View>
    );
  }
}
