//@flow
import React, { Component } from "react";
import { connect } from "react-redux";
import { View } from "react-native";
import { constants, data as sharedData, strings } from "cockpit-shared";
import { H4 } from "../../../texts"; 
import DimensionTableColorHelper from "../../../dimension-table-view/DimensionTableColorHelper";
import { ChartReducerType } from "../../SelectedTabContent";

/**
 * A common header used by all the charts
 * It containes the parent category name, the selected dimension and it's YTD/BU/VJ values
 */
export type CommonChartHeaderProps = {
  chartsReducer: ChartReducerType,
  isPercentage: Boolean
};
export type CommonChartHeaderState = {};

class CommonChartHeader extends Component<CommonChartHeaderProps, CommonChartHeaderState> {

  constructor(props: CommonChartHeaderProps) {
    super(props);
  }

  get chartsReducer(): ChartReducerType {
    return this.props.chartsReducer;
  }

  get currentEntityNameAndSelectedMetric() {
    let organizationName = this.props.selectedOrganizationLongName || this.props.entityName;
    let metricName = this.props.selectedDimensionName ? " / " + this.props.selectedDimensionName : "";
    return organizationName + metricName;
  }

  get istChartTag() {
    if (!this.chartsReducer.data) {
      return "";
    }
    return (
      strings.stringsRepo.ytd.toUpperCase() +
      ": " +
      this.chartsReducer.data.ytdFormatted
    );
  }

  get budgetChartTag() {
    if (!this.chartsReducer.data) {
      return "";
    }
    return (
      strings.stringsRepo.budgetShort.toUpperCase() +
      ": " +
      this.chartsReducer.data.budgetFormatted(this.props.isPercentage)
    );
  }

  get budgetTagColor() {
    return DimensionTableColorHelper.getBudgetTagColor(this.chartsReducer.data, true);
  }

  get previousYearTagColor() {
    return DimensionTableColorHelper.getPreviousYearTagColor(this.chartsReducer.data, true);
  }

  get previousYearChartTag() {
    if (!this.chartsReducer.data) {
      return "";
    }
    return (
      strings.stringsRepo.previousYearShort.toUpperCase() +
      ": " +
      this.chartsReducer.data.previousYearFormatted(this.props.isPercentage)
    );
  }

  render() {
    return (
      <View
        style={{
          height: 51,
          backgroundColor: constants.colors.white,
          flexDirection: "row",
          alignItems: "center",
          paddingStart: 34
        }}
      >
        <H4
          style={{
            color: constants.colors.black,
            fontWeight: "bold",
            fontSize: 16
          }}
        >
          {this.currentEntityNameAndSelectedMetric}
        </H4>
        <View
          style={{
            flexDirection: "row",
            marginStart: 120,
          }}
        >
          <H4
            style={{
              color: constants.colors.black,
              fontSize: 14,
              marginEnd: 30
            }}
          >
            {this.istChartTag}
          </H4>
          <H4
            style={{
              color: this.budgetTagColor,
              fontSize: 14,
              marginEnd: 30
            }}
          >
            {this.budgetChartTag}
          </H4>
          <H4
            style={{
              color: this.previousYearTagColor,
              fontSize: 14,
              marginEnd: 30
            }}
          >
            {this.previousYearChartTag}
          </H4>
        </View>
      </View >
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedOrganizationLongName: state.organizationsStore.selectedOrganization.description,
    selectedDimensionName: state.dimensionsStore.selectedDimension?.json?.name
  };
}
function mapDispatchToProps(dispatch, props: CommonChartHeaderProps) {
  return {
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CommonChartHeader);