//@flow
import type { RootReducerState } from "./RootReducer";
import { createStore, applyMiddleware, compose } from "redux";
import { createLogger } from "redux-logger";
import thunkMiddleware from "redux-thunk";
import { createRootReducer } from "./RootReducer";
import createSagaMiddleware from "redux-saga";
import { rootSaga } from "./RootSaga";

var rootStore = null;

export function getRootStore() {
  return rootStore;
}

// Used to log actions made in redux
const loggerMiddleware = createLogger({
  predicate: (getState, action) => false // __DEV__
});

const sagaMiddleware = createSagaMiddleware();

function configureStore(
  initialState,
  reducers,
  middlewares,
  persistConfig
): RootReducerState {
  /* An ehancer is a way of composing a bunch of middleware functions
  that run in each transformation of state of your application. */
  const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const enhancer = composeEnhancer(
    applyMiddleware(
      loggerMiddleware,
      sagaMiddleware,
      thunkMiddleware,
      ...middlewares,
    )
  );
  return createStore(
    createRootReducer(reducers, persistConfig),
    initialState,
    enhancer
  );
}

export function createRootStore(
  initialState: Object,
  reducers: Object,
  middlewares: Object,
  persistConfig: Object
) {
  rootStore = configureStore(
    initialState,
    reducers,
    middlewares,
    persistConfig
  );
  sagaMiddleware.run(rootSaga);
  return rootStore;
}
