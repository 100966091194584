//@flow
import type { CategoriesAction } from "./CategoriesReducer";
import type { RootReducerState } from "../root/RootReducer";
import { categoriesActionTypes } from "./CategoriesActionTypes";
import { createAction } from "../base/BaseActions";
import data from "../../data";
import * as dataSet from "../data-set";

export function loadCategoriesForTheCurrentDataSet() {
  return async function (dispatch: any, getState: any) {
    dispatch(
      createAction(categoriesActionTypes.LOAD_CATEGORIES, {
        isLoading: true,
      })
    );
    var currentDataSetId = getState().dataSetStore.id;
    if (currentDataSetId == null) {
      await dispatch(dataSet.dataSetActions.loadDefaultDataSet());
      currentDataSetId = getState().dataSetStore.id;
    }
    let manager = new data.categories.CategoriesManager();
    let response:
      | Array<data.category.CategoryModel>
      | data.error.ServiceError = await manager.getCategories(currentDataSetId);
    var actionPayload = {
      categories: response,
      isLoading: false,
    };
    if (response instanceof data.error.ServiceError) {
      actionPayload = {
        isLoading: false,
      };
    }
    dispatch(
      createAction(categoriesActionTypes.LOAD_CATEGORIES, actionPayload)
    );
    return response;
  };
}

export function selectParentCategory(categoryId: String) {
  return function (dispatch: any, getState: any) {
    var actionPayload = {
      parentCategoryId: categoryId,
    };
    dispatch(
      createAction(categoriesActionTypes.SAVE_PARENT_CATEGORY, actionPayload)
    );
  };
}

export function selectCategory(newCategory: Object) {
  return async function (dispatch: Function, getState: Function) {
    let action = createAction(categoriesActionTypes.SELECT_CATEGORY, {
      category: newCategory,
    });
    return dispatch(action);
  };
}
