//@flow
export type IconSize = number;

function createIconSize(size: number): IconSize {
  return size;
}

export const iconSizes = {
  NANO: createIconSize(12),
  MICRO: createIconSize(16),
  TINY: createIconSize(24),
  SMALL: createIconSize(26),
  MEDIUM: createIconSize(29),
  BIG: createIconSize(35),
  BIGGER: createIconSize(60),
  HUGE: createIconSize(100),

  S46: createIconSize(46)
};

export type IconSizeType = $Values<typeof iconSizes>;
