//@flow
import React, { Component } from "react";
import { connect } from "react-redux";
import "./DataSetSwitcher.css";
import { H3 } from "./../texts/index";
import * as icon from "../icon";
import { constants } from "cockpit-shared";
import { CategoryModel } from "cockpit-shared/src/data/category";
import { getRootStore } from "cockpit-shared/src/store/root/RootStore";
import { store } from "cockpit-shared";
import { RouteActions } from "../../navigation";
import { DimensionViewModel } from "cockpit-shared/src/data/dimension";
import ChartsAvailability from "cockpit-shared/src/data/chart-data/ChartsAvailability";

type DataSetSwitcherProps = {
  selectedDataSetName: string,
  dataSets: Array<Object>,
  selectedDataSedId: string,
  selectDataSet: (id: string) => void
}

type DataSetSwitcherState = {}

class DataSetSwitcher extends React.Component<DataSetSwitcherProps, DataSetSwitcherState> {

  constructor(props: DataSetSwitcherProps) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  toggle = () => {
    let arrow = document.getElementById("arrow");
    if (this.state.isOpen) {
      arrow.className = "switcher-arrow rotate-arrow";
    } else {
      arrow.className = "switcher-arrow rotate-arrow open";
    }
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  handleClickOutside = () => {
    if (this.state.isOpen) {
      this.toggle();
    }
  }

  onItemClicked = (item) => {
    this.setState({ selectedItemName: item.name, selectedItemId: item.id });
    this.props.selectDataSet(item.id);
  }

  render() {
    return (
      <div className="switcher-main-container">
        {this.state.isOpen &&
          <div className="switcher-overlay" onClick={this.handleClickOutside} />
        }
        <div className="switcher-container" onClick={this.toggle}>
          <div className="switcher-title-container">
            <H3
              style={{
                marginLeft: 10,
                fontWeight: "bold"
              }}
            >
              {this.props.selectedDataSetName || ""}
            </H3>
            <div style={{ flex: 1 }} />
            <div id="arrow" className="switcher-arrow rotate-arrow" />
          </div>
          {this.state.isOpen &&
            <div className="switcher-show">
              {
                this.props.dataSets.map((item, index) => {
                  return (
                    <div className="switcher-item" onClick={() => this.onItemClicked(item)}>
                      {index !== 0 && <div className="switcher-item-separator" />}
                      <div className="switcher-item-text-container" style={{
                        fontWeight: (this.props.selectedDataSetId === item.id ? "bold" : "regular")
                      }}>
                        {item.name}
                      </div>
                    </div>
                  );
                })
              }
            </div>
          }
        </div>
        <div className="switcher-icon">
          <icon.Icon
            style={{ marginLeft: 10 }}
            name={icon.iconAssets.date}
            color={constants.colors.lightBlue}
            size={icon.iconSizes.TINY}
          />
        </div>
      </div>
    );
  }

}

function mapSateToProps(state) {
  return {
    dataSets: state.dataSetStore.dataSets,
    selectedDataSetId: state.dataSetStore.id,
    selectedDataSetName: state.dataSetStore.name
  };
}

function findOrganization(organizations, orgId) {
  let orgs = [];
  let addOrgs = org => {
    orgs.push(org);
    if (org.children) {
      org.children.forEach(addOrgs);
    }
  };
  organizations?.forEach(addOrgs);
  let solution = orgs.find(item => item.id === orgId);
  return solution;
}

function mapDispatchToProps(dispatch, props: DataSetSwitcherProps) {
  return {
    selectDataSet: async (id: string) => {
      let routeStoreState = getRootStore().getState();
      let routeActions = new RouteActions();
      let oldState = getRootStore().getState();
      let selectedCategoryId = oldState.categoriesStore.category.id;
      let selectedCategory: CategoryModel = oldState.categoriesStore.category;
      let selectedOrganization: CategoryModel = oldState.organizationsStore.selectedOrganization;
      let selectedDimension: DimensionViewModel = oldState.dimensionsStore.selectedDimension;
      await dispatch(store.dimensions.dimensionsActions.toggleSpinner(true));

      //Load the new data sets
      await dispatch(store.dataSet.dataSetActions.changeDataSet(id));

      setTimeout(async () => {
        let newCategories = await dispatch(store.categories.categoriesActions.loadCategoriesForTheCurrentDataSet());

        //Check if the saved entities are also available in the new data set
        let categoryError = (selectedCategory != null) && (findOrganization(newCategories, selectedCategory.id) == null);
        let selectedCategoryError = selectedCategoryId != null && !newCategories.some((category) => category.id === selectedCategoryId);
        let organizationError = false;
        let dimensionError = false;
        let chartError = false;

        //If oldSelectedCategory exists 
        if (!categoryError && selectedCategory != null) {
          await dispatch(store.organizations.organizationsActions.loadOrganizations());
          organizationError = selectedOrganization != null &&
            findOrganization(routeStoreState.organizationsStore.organizations, selectedOrganization.id) == null;

          //If oldSelectedOrganization exists (entity in the url!!!)
          if (selectedOrganization != null && !organizationError) {
            await dispatch(store.dimensions.dimensionsActions.loadDimensions());
            dimensionError = selectedDimension != null &&
              !routeStoreState.dimensionsStore.dimensions.some((d) => d.id === selectedDimension.id);

            //If oldSelectedDimension exists
            if (selectedDimension != null && !dimensionError) {
              let tabId = routeStoreState.chartsStore.selectedTabId;
              await dispatch(store.charts.chartsActions.loadChartData());
              if (routeStoreState.chartsStore.data) {
                let chartsAvailability: ChartsAvailability = routeStoreState.chartsStore.data.chartsAvailability;
                chartError = !chartsAvailability.isTabAvailable(tabId);
              }
            } else {
              await dispatch(store.dimensions.dimensionsActions.selectDefaultDimension());
            }
          } else {
            await dispatch(store.organizations.organizationsActions.selectDefaultOrganization());
          }
        } else if (selectedCategoryError) {
          let defCategory = newCategories[0].children.length === 0 ? newCategories[0] : newCategories[0].children[0];
          await dispatch(store.categories.categoriesActions.selectCategory(defCategory));
          await dispatch(store.organizations.organizationsActions.loadOrganizations());
          await dispatch(store.organizations.organizationsActions.selectDefaultOrganization());
          await dispatch(store.dimensions.dimensionsActions.loadDimensions());
          await dispatch(store.dimensions.dimensionsActions.selectDefaultDimension());
        }
        await dispatch(store.charts.chartsActions.loadChartData());
        routeActions.refreshNavigationOnDatasetChanged(props, id);
        await dispatch(store.dimensions.dimensionsActions.toggleSpinner(false));
      }, 200);
    }
  };
}

export default connect(
  mapSateToProps,
  mapDispatchToProps
)(DataSetSwitcher);