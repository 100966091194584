//@flow
import type { BaseComponentProps } from "../base-component";
import type { IconAssetType } from "../icon/assets";
import type { LineTextFieldProps } from "../line-text-field/LineTextField";
import * as React from "react";
import { Text, View, ScrollView, Image } from "react-native";
import { LineTextField } from "../line-text-field/index";
import { Icon, iconSizes } from "../icon/index";
import { iconAssets } from "../icon/assets/index";
import { constants } from "cockpit-shared";

export type IconLineTextFieldProps = BaseComponentProps & {
  leftIconName: IconAssetType,
  lineTextFieldProps?: LineTextFieldProps
};
export type IconLineTextFieldState = {};
export default class IconLineTextField extends React.Component<
  IconLineTextFieldProps,
  IconLineTextFieldState
> {
  constructor(props: IconLineTextFieldProps) {
    super(props);
  }

  get lineTextFieldErrorStyle() {
    return {
      backgroundColor: constants.colors.errorColor
    };
  }

  get textFieldErrorStyle() {
    return {
      color: constants.colors.errorColor
    };
  }

  get textFieldProps() {
    if (this.props.lineTextFieldProps) {
      return this.props.lineTextFieldProps.textFieldProps;
    } else {
      return {};
    }
  }

  get lineTextFieldProps() {
    return {
      ...this.props.lineTextFieldProps,
      textFieldProps: {
        ...this.textFieldProps,
        onErrorStyle: this.textFieldErrorStyle
      },
      onErrorStyle: this.lineTextFieldErrorStyle
    };
  }

  get iconColor() {
    return this.lineTextFieldProps.textFieldProps.isValidValue
      ? "white"
      : constants.colors.errorColor;
  }

  render() {
    let LeftIcon = () => (
      <View
        style={{
          marginHorizontal: constants.dimensions.defaultMargin,
          marginBottom: -1,
          width: 28,
          alignItems: "center",
         
        }}
      >
        <Icon size={iconSizes.TINY} color={this.iconColor} name={this.props.leftIconName} />
      </View>
    );
    return (
      <View style={this.props.style}>
        <LineTextField leftView={<LeftIcon />} {...this.lineTextFieldProps} />
      </View>
    );
  }
}
