//@flow
import { Store } from "redux";
import { store } from "cockpit-shared";

var rootStore: Store = null;

const NATIVE_STORE_PROVIDER_SUBSCRIBER_ID = "appWeb";

function onStoreCreated(store: Object) {
  rootStore = store;
}
store.storeProvider.subscribe(NATIVE_STORE_PROVIDER_SUBSCRIBER_ID, onStoreCreated);

export function getRootStore() {
  return rootStore;
}