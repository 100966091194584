//@flow
import type { LoginPageReducerState } from "./LoginPageReducer";
import { store } from "cockpit-shared";
import { loginPageActionTypes } from "./LoginPageActionsType";
import { store as inAppNotificationStore } from "../../in-app-notification";
import { data } from "cockpit-shared";
import TextFieldChecker from "../../text-field/TextFieldChecker";
import { constants } from "cockpit-shared";

let emailTextFieldChecker = new TextFieldChecker();
let passwordTextFieldChecker = new TextFieldChecker();

export function login() {
  return async function (dispatch: any, getState: any) {
    let loginPageStore: LoginPageReducerState = getState().loginPageStore;
    let action = store.base.baseActions.createAction(
      loginPageActionTypes.LOGIN,
      {
        isLoading: true,
      }
    );
    await dispatch(action);
    let response = await dispatch(
      store.authentication.authenticationActions.authenticateWithEmail(
        loginPageStore.email,
        loginPageStore.password
      )
    );
    let finishedAction = store.base.baseActions.createAction(
      loginPageActionTypes.LOGIN,
      {
        isLoading: false,
      }
    );
    await dispatch(finishedAction);
    if (response instanceof data.error.ServiceError) {
      dispatch(
        inAppNotificationStore.inAppNotificationActions.showInAppNotificationError(
          response.message
        )
      );
    }
  };
}

export function loginWithMicrosoftAzure(hash) {
  return async function (dispatch: any, getState: any) {
    let action = store.base.baseActions.createAction(
      loginPageActionTypes.LOGIN,
      {
        isLoading: true,
      }
    );
    await dispatch(action);
    let response = await dispatch(
      store.authentication.authenticationActions.authenticateWithMicrosoftAzure(
        hash,
      )
    );
    let finishedAction = store.base.baseActions.createAction(
      loginPageActionTypes.LOGIN,
      {
        isLoading: false,
      }
    );
    await dispatch(finishedAction);
    if (response instanceof data.error.ServiceError) {
      dispatch(
        inAppNotificationStore.inAppNotificationActions.showInAppNotificationError(
          response.message
        )
      );
    }
  };
}

export function onEmailCheckResult(isValid: boolean) {
  return async function (dispatch: any) {
    let action = store.base.baseActions.createAction(
      loginPageActionTypes.ON_EMAIL_CHECK_RESULT,
      {
        isEmailValid: isValid,
      }
    );
    return dispatch(action);
  };
}

export function onPasswordCheckResult(isValid: boolean) {
  return async function (dispatch: any) {
    let action = store.base.baseActions.createAction(
      loginPageActionTypes.ON_PASSWORD_CHECK_RESULT,
      {
        isPasswordValid: isValid,
      }
    );
    return dispatch(action);
  };
}

function checkEmail(checkNow?: boolean) {
  return async function (dispatch: any, getState: any) {
    let loginPageStore: LoginPageReducerState = getState().loginPageStore;
    let shouldCheckNow = checkNow || !getState().loginPageStore.isEmailValid;
    let isValid = await emailTextFieldChecker.onValueChanged(
      loginPageStore.email,
      shouldCheckNow,
      constants.regularExpressions.emailCheck
    );
    await dispatch(onEmailCheckResult(isValid));
  };
}

function checkPassword(checkNow?: boolean) {
  return async function (dispatch: any, getState: any) {
    let loginPageStore: LoginPageReducerState = getState().loginPageStore;
    let shouldCheckNow = checkNow || !getState().loginPageStore.isPasswordValid;
    let isValid = await passwordTextFieldChecker.onValueChanged(
      loginPageStore.password,
      shouldCheckNow,
      constants.regularExpressions.passwordCheck
    );
    await dispatch(onPasswordCheckResult(isValid));
  };
}

export function onEmailChange(newEmail: string) {
  return async function (dispatch: any, getState: any) {
    let action = store.base.baseActions.createAction(
      loginPageActionTypes.ON_EMAIL_CHANGE,
      {
        email: newEmail,
      }
    );
    await dispatch(action);
    dispatch(checkEmail(false));
  };
}

export function onPasswordChange(newPassword: string) {
  return async function (dispatch: any, getState: any) {
    let action = store.base.baseActions.createAction(
      loginPageActionTypes.ON_PASSWORD_CHANGE,
      {
        password: newPassword,
      }
    );
    await dispatch(action);
    dispatch(checkPassword(false));
  };
}

export function checkLoginFields() {
  return async function (dispatch: any, getState: any) {
    await dispatch(checkEmail(true));
    await dispatch(checkPassword(true));
  };
}
