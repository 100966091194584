//@flow
import type { BaseComponentProps } from "../base-component";
import * as React from "react";
import { StyleSheet, Text, View, ScrollView, Image } from "react-native";
import { ScreenSize } from "../../data/constants";
import { constants, store } from "cockpit-shared";

import EntityHistoryTabContent from "./tab-content/EntityHistoryTabContent";
import EntityChartTabContent from "./tab-content/EntityChartTabContent";
import BubbleChartTabContent from "./tab-content/BubbleChartTabContent";
import RankingChartTabContent from "./tab-content/RankingChartTabContent";
import EntitySelectableTabContent from "./tab-content/EntitySelectableTabContent";
import { ChartDataViewModel } from "cockpit-shared/src/data/chart-data";
export type ChartReducerType = { data: ChartDataViewModel };
export type SelectedTabContentProps = {
  tabId: string,
  chartsReducer: { data: ChartDataViewModel },
  isLoading: boolean,
  currentEntityName: string,
  onEntityColumnTap: (newSelectedOrganization: Object) => void,
  onDrillUp: (organizationEntityParent: Object) => void,
  currenDataSetMonth: number,
};
export type SelectedTabContentState = {};

const BubblesChartContent = (props) => (
  <EntitySelectableTabContent
    match={props.match}
    history={props.history}
    chartsReducer={props.chartsReducer}
    onEntityColumnTap={props.onEntityColumnTap}
    onDrillUp={props.onDrillUp}
    chartTabContent={(parentProps) => (
      <BubbleChartTabContent {...{ ...props, ...parentProps }} />
    )}
  />
);

const EntityChartContent = (props) => (
  <EntitySelectableTabContent
    match={props.match}
    history={props.history}
    chartsReducer={props.chartsReducer}
    onEntityColumnTap={props.onEntityColumnTap}
    onDrillUp={props.onDrillUp}
    chartTabContent={(parentProps) => (
      <EntityChartTabContent {...{ ...props, ...parentProps }} />
    )}
  />
);

const RankingContent = (props) => (
  <EntitySelectableTabContent
    match={props.match}
    history={props.history}
    chartsReducer={props.chartsReducer}
    onEntityColumnTap={props.onEntityColumnTap}
    onDrillUp={props.onDrillUp}
    chartTabContent={(parentProps) => (
      <RankingChartTabContent {...{ ...props, ...parentProps }} />
    )}
  />
);

const renderTabFuncs = {
  [store.charts.chartTabs.CHART_TABS_ID.ENTITY_HISTORY]: (props) => (
    <EntityHistoryTabContent {...props} />
  ),
  [store.charts.chartTabs.CHART_TABS_ID.ENTITY]: (props) => (
    <EntityChartContent {...props} />
  ),
  [store.charts.chartTabs.CHART_TABS_ID.BUBBLE]: (props) => (
    <BubblesChartContent {...props} />
  ),
  [store.charts.chartTabs.CHART_TABS_ID.RANKING]: (props) => (
    <RankingContent {...props} />
  ),
};

export default class SelectedTabContent extends React.Component<
  SelectedTabContentProps,
  SelectedTabContentState
> {
  render() {
    let TabContent = renderTabFuncs[this.props.tabId];
    return (
      <TabContent
        match={this.props.match}
        history={this.props.history}
        chartsReducer={this.props.chartsReducer}
        isLoading={this.props.isLoading}
        currentEntityName={this.props.currentEntityName}
        onEntityColumnTap={this.props.onEntityColumnTap}
        onDrillUp={this.props.onDrillUp}
        currenDataSetMonth={this.props.currenDataSetMonth}
      />
    );
  }
}
