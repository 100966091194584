//@flow
import { localizedNumberFormatterInstance } from "../number";
import * as common from "../budget-previous-year";

export type entityJsonType = common.budgetPreviousYearBase.budgetPreviousYearJsonType & {
  id: string,
  name: string, 
  has_children: boolean,
};

export default class EntityViewModel extends common.budgetPreviousYearBase.default {
  json: entityJsonType;

  constructor(json: entityJsonType) {
    super(json);
    this.json = json;
  }

  get id() {
    return this.json.id;
  }

  get name() {
    return this.json.name;
  }

  get hasChildren() {
    return this.json.has_children;
  }
}
