//@flow
import * as React from "react";
import { notificationsActionTypes } from "./NotificationsActionTypes";
import * as base from "../base";
import data from "../../data";

export function _getFormattedParams(
  currentDataSetId: ?string,
  currentCategoryId: ?string,
  currentEntityId: ?string
) {
  return {
    currentDataSetId: currentDataSetId || "null",
    currentCategoryId: currentCategoryId || "null",
    currentEntityId: currentEntityId || "null"
  };
}

export function _getKeyFor(
  currentDataSetId: string,
  currentCategoryId: string,
  currentEntityId: string
): string {
  return currentDataSetId + "/" + currentCategoryId + "/" + currentEntityId;
}

function _loadNumberOfNotifications(
  currentDataSetId: string,
  currentCategoryId: ?string,
  currentEntityId: ?string
) {
  return async function(dispatch: any, getState: any) {
    let createNotificationReducerAction = payload =>
      base.baseActions.createAction(
        notificationsActionTypes.LOAD_NUMBER_OF_NEW_NOTIFICATIONS,
        payload
      );
    let loadingAction = createNotificationReducerAction({
      isLoading: true
    });
    dispatch(loadingAction);
    let manager = new data.notifications.NotificationsManager();
    let formattedParams = _getFormattedParams(currentDataSetId, currentCategoryId, currentEntityId);
    let lastVisitKey = _getKeyFor(
      formattedParams.currentDataSetId,
      formattedParams.currentCategoryId,
      formattedParams.currentEntityId
    );
    let store = getState().notificationsStore;
    let lastNotificationsVisitTime = getState().notificationsStore[lastVisitKey];
    let response = await manager.getUnreadNotificationsCount(
      formattedParams.currentDataSetId,
      formattedParams.currentCategoryId,
      formattedParams.currentEntityId,
      lastNotificationsVisitTime
    );
    let endActionPayload = {
      isLoading: false
    };
    if (response instanceof data.error.ServiceError) {
      return dispatch(createNotificationReducerAction(endActionPayload));
    }
    var numberOfNotifications: number;
    try {
      numberOfNotifications = parseInt(response.count);
    } catch (e) {
      numberOfNotifications = 0;
    }
    return dispatch(
      createNotificationReducerAction({
        ...endActionPayload,
        numberOfUnreadNotifications: numberOfNotifications
      })
    );
  };
}

export function loadNumberOfNewNotifications() {
  return async function(dispatch: any, getState: any) {
    let currentDataSetId = getState().dataSetStore.id;
    let currentCategory = getState().categoriesStore.category;
    let currentCategoryId = currentCategory != null ? currentCategory.id : null;
    let organizationsStore = getState().organizationsStore;
    let selectedOrganization = organizationsStore.selectedOrganization;
    let currentEntityId = selectedOrganization.id.length > 0 ? selectedOrganization.id : null;
    return dispatch(
      _loadNumberOfNotifications(currentDataSetId, currentCategoryId, currentEntityId)
    );
  };
}

export function loadNumberOfNewNotificationsInHomePage() {
  return async function(dispatch: any, getState: any) {
    let currentDataSetId = getState().dataSetStore.id;
    return dispatch(_loadNumberOfNotifications(currentDataSetId, null, null));
  };
}

export function updateLastNotificationsPageVisitTime(
  currentDataSetId: ?string,
  currentCategoryId: ?string,
  currentEntityId: ?string
) {
  return async function(dispatch: any, getState: any) {
    let params = _getFormattedParams(currentDataSetId, currentCategoryId, currentEntityId);
    let key = _getKeyFor(params.currentDataSetId, params.currentCategoryId, params.currentEntityId);
    let nowUnix = Math.floor(Date.now() / 1000);
    let action = base.baseActions.createAction(
      notificationsActionTypes.UPDATE_LAST_NOTIFICATIONS_VISIT_TIME,
      { [key]: nowUnix }
    );
    dispatch(action);
  };
}

export function clearNumberOfUnreadNotifications() {
  return async function(dispatch: any, getState: any) {
    dispatch(
      base.baseActions.createAction(notificationsActionTypes.LOAD_NUMBER_OF_NEW_NOTIFICATIONS, {
        numberOfUnreadNotifications: 0
      })
    );
  };
}
