//@flow
import * as React from "react";
import "./Menu.css";
import MenuItem from "./MenuItem";

export type MenuColumnProps = {
  items: OrganizationEntityModel[],
  onItemHovered: (children, depthLevel, id) => void,
  onItemClicked: (id) => void,
  marginLeft: Number,
  depthLevel: Number,
};

export type MenuColumnState = {
  childrens: OrganizationEntityModel[],
};

export default class MenuColumn extends React.Component<
  MenuColumnProps,
  MenuColumnState
> {
  constructor(props: MenuColumnProps) {
    super(props);

    this.state = {
      childrens: this.props.items,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ childrens: nextProps.items });
  }

  getItems = () => {
    return this.state.childrens.map((it) => {
      return (
        <MenuItem
          key={it.id}
          title={it.name}
          children={it.children}
          description={it.description}
          id={it.id}
          depthLevel={this.props.depthLevel}
          onItemHovered={this.onItemHovered}
          onItemClicked={this.onItemClicked.bind(this)}
        />
      );
    });
  };

  onItemHovered = (children, depthLevel, id) => {
    this.props.onItemHovered(children, depthLevel, id);
  }

  onItemClicked(id) {
    this.props.onItemClicked(id);
  }

  render() {
    return (
      <div
        className="menu-column"
        style={{ marginLeft: this.props.marginLeft + "px" }}
      >
        <div className="dropdown-menu">{this.getItems()}</div>
      </div>
    );
  }
}
