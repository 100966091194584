//@flow
import type { BaseComponentProps } from "../base-component";
import type { TextFieldProps } from "../text-field/TextField";
import * as React from "react";
import { Text, View, ScrollView, Image } from "react-native";
import { TextField } from "../text-field/index";
import data from "../../data";
import { constants } from "cockpit-shared";

export type LineTextFieldProps = BaseComponentProps & {
  leftView?: any,
  onErrorStyle?: any,
  textFieldProps?: TextFieldProps
};
export type LineTextFieldState = {};
export default class LineTextField extends React.Component<LineTextFieldProps, LineTextFieldState> {
  constructor(props: LineTextFieldProps) {
    super(props);
  }
  get topSpacing() {
    return constants.dimensions.smallMargin;
  }

  get accentColor() {
    return "white";
  }

  get errorStyle() {
    return this.props.onErrorStyle || {};
  }

  get isValidValue() {
    if (this.props.textFieldProps) {
      return this.props.textFieldProps.isValidValue;
    }
    return false;
  }

  get lineStyle() {
    let possibleErrorStyle = this.isValidValue ? {} : this.errorStyle;
    return {
      height: 1,
      backgroundColor: this.accentColor,
      marginTop: this.topSpacing,
      ...possibleErrorStyle
    };
  }

  get androidMarginBottom() {
    return data.constants.PlatformOS.iOS ? 0 : -3;
  }

  render() {
    return (
      <View style={{ paddingTop: this.topSpacing }}>
        <View style={{ flexDirection: "row", alignItems: "flex-end" }}>
          {this.props.leftView}
          <View style={{ flex: 1, minWidth: 250 }}>
            <TextField
              style={{
                color: this.accentColor,
                marginLeft: constants.dimensions.smallMargin,
                marginBottom: this.androidMarginBottom
              }}
              selectionColor={this.accentColor}
              {...this.props.textFieldProps}
            />
          </View>
        </View>

        <View style={this.lineStyle} />
      </View>
    );
  }
}
