//@flow
import * as React from "react";
import { Text, View, ScrollView, Image, Animated } from "react-native";
import { ScreenSize } from "../../data/constants";
import { constants } from "cockpit-shared";

export type RankBarProps = {
  style?: any,
  barStyle?: any,
  loadedPercent: number,
  animationDelay?: number,
  height: number
};
export type RankBarState = {
  animatedWidth: Animated.Value
};
export default class RankBar extends React.Component<
  RankBarProps,
  RankBarState
> {
  containerWidth: ?number;

  constructor() {
    super();
    this.containerWidth = null;
    this.state = {
      animatedWidth: new Animated.Value(0)
    };
  }

  get loadedPercentAsWidth() {
    if (this.containerWidth) {
      let width = (this.props.loadedPercent / 100) * this.containerWidth;
      return width;
    }
    return 0;
  }

  onLayoutChanged({
    nativeEvent: {
      layout: { x, y, width, height }
    }
  }: Object) {
    if (this.containerWidth == null) {
      this.containerWidth = width;
      this.refreshAnimation();
    }
  }

  UNSAFE_componentWillReceiveProps(newProps: Object) {
    this.refreshAnimation();
  }

  refreshAnimation() {
    if (this.props.animationDelay) {
      setTimeout(() => {
        setTimeout(this.animateProgress.bind(this), 500);
      }, this.props.animationDelay);
    }
  }

  animateProgress() {
    Animated.spring(this.state.animatedWidth, {
      toValue: this.loadedPercentAsWidth,
      duration: 1000
    }).start();
  }

  render() {
    return (
      <View
        style={{ ...this.props.style }}
        onLayout={this.onLayoutChanged.bind(this)}
      >
        <Animated.View
          style={{
            width: this.state.animatedWidth,
            height: this.props.height || 29,
            borderRadius: 3,
            backgroundColor: "black",
            ...this.props.barStyle
          }}
        />
      </View>
    );
  }
}
