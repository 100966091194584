//@flow
import type { RootReducerState } from "../root/RootReducer";
import { connectivityActionTypes } from "./ConnectivityActionTypes";
import { createAction } from "../base/BaseActions";

export function onNetworkConnectionChanged(isConnected: boolean) {
  return async function (dispatch: any, getState: () => RootReducerState) {
    let action = createAction(connectivityActionTypes.ON_NETWORK_CONNECTION_CHANGED, {
      isConnected
    });
    dispatch(action);
  };
}
