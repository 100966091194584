//@flow
import { localizedNumberFormatterInstance } from "../number";
import * as common from "../budget-previous-year";
import { InvertedViewModel } from "../inverted";
import type { invertedJsonType } from "../inverted/InvertedViewModel";
import ChartsAvailability from "../chart-data/ChartsAvailability";

type dimensionJsonType =
  common.budgetPreviousYearBase.budgetPreviousYearJsonType &
  invertedJsonType & {
    name: string,
    id: string,
    inverted: boolean,
    ytd_label: string,
    chart_data: string
  };

export default class DimensionViewModel extends common.budgetPreviousYearBase.default {
  json: dimensionJsonType;
  invertedStatus: InvertedViewModel;
  chartsAvailability: ChartsAvailability;
  constructor(json: dimensionJsonType) {
    super(json);
    this.json = json;
    this.invertedStatus = new InvertedViewModel(json);
    this.chartsAvailability = new ChartsAvailability(json.chart_data);
  }

  get name() {
    return this.json.name;
  }

  get id() {
    return this.json.id;
  }

  get ytdLabel() {
    return this.json.ytd_label;
  }
}