//flow
class CategoryViewModel {
    id: string;
    name: string;
    ytd: string;
    bu: string;
    vj: string;
    isHeader: boolean;
}

const categoryViewStates = {
    OK: "OK",
    NOT_OK: "NOT_OK",
    DISABLE: "DISABLED"
}

type CategoryViewState = $Values<typeof categoryViewStates>;

export {
    CategoryViewModel,
    categoryViewStates
}
export type { CategoryViewState };