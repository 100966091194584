//@flow
import * as React from "react";
import { store } from "cockpit-shared";
import { inAppNotificationActionTypes } from "./InAppNotificationActionsTypes";
import { ErrorNotification, MessageNotification, SuccessNotification } from "../notification-views";


export function showInAppNotificationError(message: string) {
  return function(dispatch: any) {
    let contentView = (<ErrorNotification message={message} />);
    dispatch(store.inAppNotification.inAppNotificationActions.showInAppNotification(contentView));
  };
}

export function showInAppNotificationSuccess(message: string) {
  return function(dispatch: any) {
    let contentView = (<SuccessNotification message={message} />);
    dispatch(store.inAppNotification.inAppNotificationActions.showInAppNotification(contentView));
  };
}

export function showInAppNotificationMessage(message: string, onTap: Function) {
  return function(dispatch: any) {
    let _onTap = () => {
      dispatch(store.inAppNotification.inAppNotificationActions.hideInAppNotification());
      if(onTap) {
        onTap();
      }
    };
    let contentView = (<MessageNotification message={message} onTap={_onTap} />);
    dispatch(store.inAppNotification.inAppNotificationActions.showInAppNotification(contentView));
  };
}


