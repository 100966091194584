//@flow
export type BaseAction = {
  type: string,
  payload: Object
};

export function createAction(type: string, payload: Object): BaseAction {
  return {
    type: type,
    payload: payload
  };
}

export function commonActionHandler(state: Object, action: Object) {
  return { ...state, ...action.payload };
}

