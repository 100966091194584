//@flow
import * as React from "react";
import "./Menu.css";

export type MenuItemProps = {
  id: string,
  title: string,
  description: string,
  children: OrganizationEntityModel[],
  onItemHovered: (children, depthLevel, id) => void,
  onItemClicked: (id) => void,
  depthLevel: Number,
};

export type MenuItemState = {};

export default class MenuItem extends React.Component<
  MenuItemProps,
  MenuItemState
  > {
  constructor(props: MenuColumnProps) {
    super(props);
  }

  getTitle() {
    return this.props.title ? (
      <div className="menu-item-title">
        {this.props.title.toUpperCase()}
      </div>
    ) : null;
  }

  getDescription() {
    return this.props.description ? (
      <div className="menu-item-description">
        {this.props.description}
      </div>
    ) : null;
  }

  getNextIcon() {
    return this.props.children.length === 0 ? null : (
      <div className="next-icon" onClick={this.onMouseEnterEvent.bind(this)}>
        <div className="arrow right" />
      </div>
    );
  }

  onMouseEnterEvent(e) {
    this.props.onItemHovered(this.props.children, this.props.depthLevel, this.props.id);
  }

  handleClick(e) {
    this.props.onItemClicked(this.props.id);
  }

  //Add an unique id for helping preserve the "hover state" => where the user hovered/clicked
  //basically show the previous steps
  getUniqueId() {
    return this.props.depthLevel + "-" + this.props.id;
  }

  render() {
    return (
      <div className="menu-item" id={this.getUniqueId()} onMouseEnter={this.onMouseEnterEvent.bind(this)}>
        <div className="menu-item-main-container">
          <div className="menu-item-container">
            <div className="menu-item-text-container"
              onClick={this.handleClick.bind(this)}
            >
              {this.getTitle()}
              {this.getDescription()}
            </div>
            {this.getNextIcon()}
          </div>
          <div className="menu-item-separator" />
        </div>
      </div>
    );
  }
}
