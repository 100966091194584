//@flow
import type { BaseAction } from "../base/BaseActions";
import { baseReducerHelper } from "../base";
import data from "../../data";
import { commonActionHandler } from "../base/BaseActions";
import { RankViewModel } from "../../data/rank";
import { entitiesRankingActionTypes } from "./EntitiesRankingActionTypes";

export type EntitiesRankingState = {
  isLoading: boolean,
  rankings: Array<RankViewModel>
};

export type EntitiesRankingAction = BaseAction & {
  type: Object
};

let defaultState: EntitiesRankingState = {
  isLoading: false,
  rankings: []
};

export const entitiesRankingReducer = baseReducerHelper.createAuthAwareReducer(defaultState, {
  [entitiesRankingActionTypes.LOAD_ENTITIES_RANKING]: commonActionHandler
});