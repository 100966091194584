//@flow
import { BaseManager } from "../base";
import { RequestTypes } from "../request-type/RequestType";
import type { BaseManagerResponse } from "../base/BaseManagerResponse";
import ServiceError from "../error/ServiceError";
import { OrganizationEntityModel } from ".";

export default class OrganizationEntityManager extends BaseManager {
  constructor() {
    super();
  }

  //todo - 
  async getOrganizationEntities(
    dataSetId: string,
    categoryId: string
  ): Promise<Array<CategoryModel>> {
    let url = `${dataSetId}/categories/${categoryId}/entities/`;
    let response: BaseManagerResponse = await this.fetchJSON(RequestTypes.GET, url);
    if (response instanceof ServiceError) {
      return response;
    }
    let entities: OrganizationEntityModel[] = response.map(
      item => new OrganizationEntityModel(item)
    );
    return entities;
  }
}
