//@flow
import type { HProps } from "./H";
import type { BaseComponentProps } from "../base-component/BaseComponent";
import * as React from "react";
import { Text, View, ScrollView, Image } from "react-native";
import H from "./H";

export type H3Props = HProps & {};
export type H3State = {};

export const H3Style = {
  fontSize: 14
};

export default class H3 extends React.Component<H3Props, H3State> {
  render() {
    return <H {...this.props} style={{ ...H3Style, ...this.props.style }}>{this.props.children}</H>;
  }
}
