//@flow
export const chartActionTypes = {
    CHOOSE_ORGANISATION: "CHOOSE_ORGANISATION",
    CHOOSE_METRIC: "CHOOSE_METRIC",
    SWITCH_TAB: "SWITCH_TAB",
    LOAD_HISTORY_CHART_DATA: "LOAD_HISTORY_CHART_DATA",
    CHANGE_FORMAT_TYPE: "CHANGE_FORMAT_TYPE",
    RESET_STATE: "RESET_STATE_CHART",
    TOGGLE_SPINNER: "TOGGLE_SPINNER_CHART"
};
export type ChartActionType = $Keys<typeof chartActionTypes>;
