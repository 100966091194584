//@flow
import React, { Component } from "react";
import { View } from "react-native";
import * as icon from "../icon";
import { constants } from "cockpit-shared";
import data from "../../data";
import { getHelperPath } from "../../data/info/HelpRoutesHelper";
import { Tappable } from "../tappable";
import type { IconAssetType } from "../icon/assets";
import { connect } from "react-redux";
import "./HeaderNavigator.css";
import { InfoManager } from "cockpit-shared/src/data/info";
import BottomIcon from "./BottomIcon";
import { Logout } from "../logout";

const FullScreenIcon = require("../app/full-screen.svg")

export type HeaderNavigatorPropTypes = {
  isGlossaryDisabled: boolean,
  numberOfUnreadNotifications: number,
  dataSetId: string,
  categoryId: string,
  entityId: string,
  profileIcon?: IconAssetType,
  userName?: String,
  onItemClick: (id) => void
};

export function addExtraStyle(id) {
  document.getElementById(id).classList.add("selected-header-navigation-element");
}

export function removeAllExtraStyle() {
  document.getElementById("glossary").classList.remove("selected-header-navigation-element");
  document.getElementById("help").classList.remove("selected-header-navigation-element");
  document.getElementById("notification").classList.remove("selected-header-navigation-element");
}

class HeaderNavigator extends Component<HeaderNavigatorPropTypes> {

  constructor() {
    super();
    this.state = {
      isFullScreen: false,
      showProfileContent: false
    };
  }

  onElementTap = (type) => {
    removeAllExtraStyle();
    let infoManager = new InfoManager();
    let response = "";
    let title = "";
    switch (type) {
      case "glossary":
        title = data.strings.stringsRepo.glossary;
        response = infoManager.getGlossaryRequest(this.props.dataSetId, this.props.categoryId);
        addExtraStyle("glossary");
        break;
      case "help":
        title = data.strings.stringsRepo.helper;
        response = infoManager.getHelperRequestForDesktop(getHelperPath());
        addExtraStyle("help");
        break;
      case "notification":
        title = data.strings.stringsRepo.notifications;
        response = infoManager.getMessagesRequest(this.props.dataSetId, this.props.categoryId, this.props.entityId);
        addExtraStyle("notification");
        break;
      case "profile":
        title = type;
        this.setState({
          showProfileContent: !this.state.showProfileContent,
        });
        return;
      default:
        break;
    }
    this.props.onItemClick(response, title);
  }
  onFullScreenIconTap = () => {
    if (!this.state.isFullScreen) {
      var elem = document.documentElement;
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) { /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) { /* IE11 */
        elem.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) { /* Safari */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { /* IE11 */
        document.msExitFullscreen();
      }
    }
    this.setState({
      isFullScreen: !this.state.isFullScreen,
    });
  }

  handleClickOutsideProfileContent = () => {
    if (this.state.showProfileContent) {
      this.setState({
        showProfileContent: false
      });
    }
  }

  render() {
    return (
      <div className="main-header-navigator-container">
        {this.state.showProfileContent &&
          <div className="nav-header-wrapper-overlay" onClick={this.handleClickOutsideProfileContent} />
        }
        <BottomIcon
          id={"glossary"}
          text={data.strings.stringsRepo.glossary}
          iconName={icon.iconAssets.glossary}
          size={icon.iconSizes.TINY + 5}
          isDisabled={this.props.isGlossaryDisabled}
          onTap={() => this.onElementTap("glossary")}
        />
        <BottomIcon
          id={"help"}
          text={data.strings.stringsRepo.helper}
          iconName={icon.iconAssets.question}
          onTap={() => this.onElementTap("help")}
        />
        <View>
          <BottomIcon
            id={"notification"}
            unreadNotificationCount={this.props.numberOfUnreadNotifications}
            text={data.strings.stringsRepo.notifications}
            iconName={icon.iconAssets.notification}
            onTap={() => this.onElementTap("notification")}
          />
        </View>

        <div className="header-navigator-profile-icon">
          <Tappable
            onTap={() => this.onElementTap("profile")}>
            <div className="header-navigator-icon">
              <icon.Icon
                name={this.props.profileIcon}
                color={constants.colors.lightBlue}
                size={icon.iconSizes.TINY}
              />
            </div>
          </Tappable>
          {this.state.showProfileContent &&
            <Logout />
          }
        </div>
        <Tappable
          onTap={this.onFullScreenIconTap}
          style={{
            width: 20,
            height: 20,
            alignItems: "center",
            justifyContent: "center",
            marginLeft: 10
          }}
        >
          <img src={FullScreenIcon} />
        </Tappable>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    categoryId: state.categoriesStore?.category?.id,
    dataSetId: state.dataSetStore?.id,
    entityId: state.organizationsStore?.selectedOrganization?.id,
    numberOfUnreadNotifications:
      state.notificationsStore.numberOfUnreadNotifications
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderNavigator);
