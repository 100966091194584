import React from "react";
import { View } from "react-native";
import NoDataView, { NoDataProps } from "./NoDataView";
export default class NoDataContainer extends React.Component<NoDataProps> {
    render() {
        if (this.props.noData) {
            return (
                <NoDataView style={this.props.style} />
            );
        } else {
            return this.props.children;
        }
    }
}