//@flow
import * as React from "react";
import { View } from "react-native";
import { store } from "cockpit-shared";
import ChartsColumnsHeader, { computeSubEntitiesWidth } from "../charts/ChartsColumnsHeader";
import EntityChart, { getDefaultBarWidth } from "../charts/EntityChart";
import { stringsRepo } from "cockpit-shared/src/strings";
import HeaderPositioner from "../charts/header-positioner/HeaderPositioner";
import { ChartReducerType } from "../SelectedTabContent";
import DimensionTableColorHelper from "../../dimension-table-view/DimensionTableColorHelper";
import CommonChartHeader from "../charts/common/CommonChartHeader";

export type EntityChartCommonTabContentProps = {
  chartsReducer: ChartReducerType,
  isLoading: boolean,
  budgetValues: Array<number>,
  budgetValuesFormatted: Array<string>,
  vorjahrValues: Array<number>,
  vorjahrValuesFormatted: Array<string>,
  istValues: Array<?number>,
  istValuesFormatted: Array<string>,
  istValueColorPredicate?: Function,
  budgetValueColorPredicate: Function,
  vorjahrValueColorPredicate: Function,
  subEntities: Array<Object>,
  onColumnTap?: (columnIndex: number) => void,
  currentEntityName: string,
  onDrillUpTap?: () => void,
  isDrillUpAvailable?: boolean,
  minimumNumberOfCollumns?: number,
  headersDisabled?: boolean,
  istChartInverted: boolean,
  vorjahrChartInverted: boolean,
  budgetChartInverted: boolean,
  yAxisPosition: ?"left" | "right",
  scrollChartHorizontallyToEnd?: boolean,
  isHistory?: boolean
};
export type EntityChartCommonTabContentState = {};
export default class EntityChartCommonTabContent extends React.Component<
  EntityChartCommonTabContentProps,
  EntityChartCommonTabContentState
> {
  headerPositioner: HeaderPositioner;
  headers: React.Component[];
  constructor() {
    super();
    this.headers = [];
    this.headerPositioner = new HeaderPositioner(() => {
      this.headers
        .filter((e) => e)
        .forEach((h) => {
          if (h) { h.forceUpdate(); }
        });
    });
  }

  get chartValusMarkerSuffix() {
    return this.isPercentage ? "%" : "";
  }

  formatChartValues(
    values: Array<?number>,
    formattedValues: Array<string>
  ): Array<Object> {
    return values.map((value, index) => {
      return { y: value || 0, marker: `${formattedValues[index]}` };
    });
  }

  get isPercentage() {
    return (
      this.chartsReducer.formatType ===
      store.charts.dataFormats.dataFormatTypes.percentage
    );
  }

  get chartsReducer() {
    return this.props.chartsReducer;
  }

  get isVjColorInverted() {
    if (!this.chartsReducer.data) {
      return false;
    }
    return this.chartsReducer.data.invertedStatus.isBarChartDeviationColorInverted(
      this.isPercentage
    );
  }

  get isBuColorInverted() {
    if (!this.chartsReducer.data) {
      return false;
    }
    return this.chartsReducer.data.invertedStatus.isBarChartDeviationColorInverted(
      this.isPercentage
    );
  }

  get budgetValues() {
    return this.formatChartValues(
      this.props.budgetValues,
      this.props.budgetValuesFormatted
    );
  }

  //Precedent
  get vorjahrValues() {
    return this.formatChartValues(
      this.props.vorjahrValues,
      this.props.vorjahrValuesFormatted
    );
  }

  //Current year
  get istValues() {
    return this.formatChartValues(
      this.props.istValues,
      this.props.istValuesFormatted
    );
  }

  formatTag(tagPrefix, value: number) {
    return [`${tagPrefix}: `, `${value}`];
  }

  get isVjColorInverted() {
    if (!this.chartsReducer.data) {
      return false;
    }
    return this.chartsReducer.data.invertedStatus.isBarChartDeviationColorInverted(
      this.isPercentage
    );
  }

  get isBuColorInverted() {
    if (!this.chartsReducer.data) {
      return false;
    }
    return this.chartsReducer.data.invertedStatus.isBarChartDeviationColorInverted(
      this.isPercentage
    );
  }

  budgetValueColorPredicate(value: number, index: number) {
    return this.props.budgetValueColorPredicate(value, index, this.isBuColorInverted);
  }

  vorjahrValueColorPredicate(value: number, index: number) {
    return this.props.vorjahrValueColorPredicate(value, index, this.isVjColorInverted);
  }

  splitNumber(text: string) {
    return text.split(": ");
  }

  onScrollViewLayoutReady() {
    if (this.props.scrollChartHorizontallyToEnd != null
      && this.props.scrollChartHorizontallyToEnd) {
      this.scrollRef.scrollToEnd({ animated: true, duration: 1200 })
    }
  }

  getColumnWidthInEms(): number {
    return computeSubEntitiesWidth(this.props.subEntities);
  }

  getMaximumNumberOfValues(): number {
    //get's the maximum number of columns out of the 3 bar charts
    return Math.max(this.budgetValues.length, this.vorjahrValues.length, this.istValues.length);
  }

  render() {
    let hp = this.headerPositioner;
    return (
      this.props.isLoading ? (
        <div style={{ width: "100%", height: "100%", backgroundColor: "white" }} />
      ) : (
        <View
          style={{
            flex: 1,
            flexDirection: "row"
          }}
        >
          <View
            style={{
              height: "100%",
              flexDirection: "row",
              width: "100%"
            }}
          >
            <View
              style={{
                flexDirection: "column",
                alignSelf: "flex-start",
                justifyContent: "flex-start",
                height: "100%",
                width: "100%",
                backgroundColor: "white"
              }}
            >
              <CommonChartHeader
                chartsReducer={this.chartsReducer}
                isPercentage={this.isPercentage}
              />
              <div id="EntityChart-container" className="entityChart-scrollable-container">
                <ChartsColumnsHeader
                  onLayout={(e) => { hp.onChartsHeaderLayout(e.nativeEvent.layout); }}
                  disabled={this.props.headersDisabled}
                  onColumnTap={this.props.onColumnTap}
                  isDrillUpAvailable={this.props.isDrillUpAvailable}
                  onDrillUpTap={this.props.onDrillUpTap}
                  subEntities={this.props.subEntities}
                  minimumNumberOfCollumns={13}
                  yAxisPosition={this.props.yAxisPosition}
                  columnWidthInEms={this.getColumnWidthInEms()}
                  width={!this.props.isHistory && this.getMaximumNumberOfValues() > 13 ? this.getMaximumNumberOfValues() * getDefaultBarWidth() : "100%"} //todo
                  paddingLeft={this.props.isHistory ? 55 : 0}

                />
                <EntityChart
                  isHistory={this.props.isHistory}
                  onBudgetChartLayout={hp.onBudgetHeaderLayout.bind(hp)}
                  onPreviousYearChartLayout={hp.onPreviousYearHeaderLayout.bind(hp)}
                  onIstChartLayout={hp.onIstHeaderLayout.bind(hp)}
                  style={{ flex: 1 }}
                  budgetValues={this.budgetValues}
                  vorjahrValues={this.vorjahrValues}
                  istValues={this.istValues}
                  isLoading={this.props.isLoading}
                  istValueColorPredicate={this.props.istValueColorPredicate}
                  budgetValueColorPredicate={this.budgetValueColorPredicate.bind(this)}
                  vorjahrValueColorPredicate={this.vorjahrValueColorPredicate.bind(this)}
                  minimumNumberOfCollumns={this.props.minimumNumberOfCollumns || 0}
                  isPercentageValues={this.isPercentage}
                  isVjColorInverted={this.isVjColorInverted}
                  isBuColorInverted={this.isBuColorInverted}
                  istChartInverted={this.props.istChartInverted}
                  vorjahrChartInverted={this.props.vorjahrChartInverted}
                  budgetChartInverted={this.props.budgetChartInverted}
                  //There is a bug in the Charts library. When the chart is rerendered,
                  //the axis range increase indefinitely. We overcome this by forcing the charts
                  //to be recreated on each render
                  key={Math.random() * 1000 + ""}
                  yAxisPosition={this.props.yAxisPosition}
                  columnWidthInEms={this.getColumnWidthInEms()}
                />
              </div>
            </View>
          </View>
        </View>
      )
    );
  }
}

export function addCommonProps() {
  // "this" is not the one from this file is the one which is received in bind function.
  return {
    subEntities: this.subEntities,
    budgetValues: this.budgetValues,
    budgetValuesFormatted: this.budgetValuesFormatted,
    vorjahrValues: this.vorjahrValues,
    vorjahrValuesFormatted: this.vorjahrValuesFormatted,
    istValues: this.istValues,
    istValuesFormatted: this.istValuesFormatted,
    isLoading: this.props.isLoading,
    chartsReducer: this.chartsReducer,
    currentEntityName: this.props.currentEntityName,
    budgetValueColorPredicate: this.budgetValueColorPredicate.bind(this),
    vorjahrValueColorPredicate: this.vorjahrValueColorPredicate.bind(this)
  };
}

export function isGoodColorPredicate(value: number, isInverted: boolean) {
  return (value >= 0 && !isInverted) || (value < 0 && isInverted);
}
export type PositionType = {
  left: number,
  right: number,
  top: number,
  bottom: number,
  available: boolean // default value is TRUE
}
