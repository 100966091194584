//@flow
import H from "./H";
import H1, { H1Style } from "./H1";
import H2, { H2Style } from "./H2";
import H3, { H3Style } from "./H3";
import H4, { H4Style } from "./H4";
import H5, { H5Style } from "./H5";
import NumberText from "./NumberText";
import AppVersionText from "./AppVersionText";
export {
  H,
  H1,
  H1Style,
  H2,
  H2Style,
  H3,
  H3Style,
  H4,
  H4Style,
  H5,
  H5Style,
  NumberText,
  AppVersionText
};
