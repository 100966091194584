//@flow
import * as React from "react";
import { View } from "react-native";
import * as icon from "../../icon";
import { constants } from "cockpit-shared";
import { H2 } from "../../texts";
import { Tappable } from "../../tappable";
import { NetworkImage } from "../../menu-button/MenuButton";
import "./ChartTab.css";

export type CategoryTabProps = {
  id: string,
  iconName: string,
  iconUrl: string,
  selectedTabId: string,
  isDisabled?: boolean,
  name: string,
  style: any,
  selectedTextColor: string,
  notSelectedTextColor: string,
  selectedBackgroundColor: string,
  notSelectedBackgroundColor: string,
  isSelected: Boolean,
  style?: any,
  title?: String,
  shouldChangeTitle: Boolean,
  isHoverActive: Boolean
};

export const CategoryTab = (props: CategoryTabProps) => {
  let isSelected = (props.isSelected != null && props.isSelected) || props.id === props.selectedTabId;
  let title = (isSelected && props.shouldChangeTitle) ? props.title : props.name;
  const textColor = isSelected
    ? props.isHoverActive ? props.hoverActiveTextColor : props.selectedTextColor || constants.colors.w_veryDarkBlue
    : props.notSelectedTextColor || constants.colors.w_shadedBlue;
  const containerBackgroundColor = isSelected
    ? props.isHoverActive ? props.hoverActiveBackgroundColor : props.selectedBackgroundColor || constants.colors.white
    : props.notSelectedBackgroundColor || constants.colors.w_shadedBlue;
  return (
    <Tappable
      isDisabled={props.isDisabled}
      key={props.iconName || props.iconUrl}
      onTap={() => props.onTap(props.id)}
      containerStyle={{ ...props.containerStyle }}
    >
      <View
        style={{
          flex: 1,
          backgroundColor: containerBackgroundColor,
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        <div className="category-tab-content">
          {props.iconUrl != null ? (
            <NetworkImage
              style={props.isDisabled ? { opacity: 0.2 } : {}, { "image-rendering": "-webkit-optimize-contrast" }}
              size={icon.iconSizes.MICRO}
              iconUrl={props.iconUrl}
              color={textColor}
            />
          ) : (
              <icon.Icon
                color={textColor}
                style={props.isDisabled ? { opacity: 0.2 } : {}}
                name={props.iconName}
                size={icon.iconSizes.MICRO}
              />
            )}
          <H2 style={{ marginStart: 12, color: textColor }}>{title}</H2>
        </div>
      </View>
    </Tappable>
  );
};
