//@flow
import { BaseManager } from "../base";
import { RequestTypes } from "../request-type/RequestType";
import type { BaseManagerResponse } from "../base/BaseManagerResponse";
import Morphism from "morphism";
import ServiceError from "../error/ServiceError";
import { CategoryModel } from "../category";

export default class CategoriesManager extends BaseManager {

  async getCategories(dataSetId: string): Promise<Array<CategoryModel>> {
    let response: BaseManagerResponse = await this.fetchJSON(RequestTypes.GET, `${dataSetId}/categories/`);
    let categories: CategoryModel[] = Morphism.map(CategoryModel, response);
    return categories;
  }
}
