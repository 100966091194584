//@flow
import type { BaseComponentProps } from "../base-component";
import * as React from "react";
import { StyleSheet, View, Image } from "react-native";
import Lottie from "react-lottie";
import data from "../../data";
import { ui, constants } from "cockpit-shared";
import { AnimatedEntrance } from "../animated-entrance";

const styles = {
  containerStyle: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    justifyContent: "center",
    alignSelf: "center",
    alignItems: "center",
    backgroundColor: constants.colors.loadingIndicatorBackground,
  },
};

export type LoadingIndicatorProps = {
  style?: any,
  size?: number,
  loading: boolean,
};
export type LoadingIndicatorState = {
  isAnimationOn: boolean,
};
export default class LoadingIndicator extends React.Component<
  LoadingIndicatorProps,
  LoadingIndicatorState
> {
  animation: ?LottieView;
  constructor() {
    super();
    this.state = {
      isAnimationOn: false,
    };
    this.animation = null;
  }

  onRefReceived(animationRef: LottieView) {
    if (animationRef == null) {
      return;
    }
    this.animation = animationRef;
    if (!this.state.isAnimationOn) {
      this.animation.play();
      this.setState({
        isAnimationOn: true,
      });
    }
  }

  UNSAFE_componentWillReceiveProps(newProps: LoadingIndicatorProps) {
    if (!newProps.loading) {
      this.setState({ isAnimationOn: false });
    }
  }

  get defaultSize() {
    return data.constants.PlatformOS.iOS ? 150 : 180;
  }

  get size() {
    return this.props.size || this.defaultSize;
  }

  get width() {
    return data.constants.PlatformOS.iOS
      ? this.size + this.size / 3
      : this.size;
  }

  get height() {
    return this.size;
  }

  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: ui.animation.assets.loadingAnimation,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    return (
      <AnimatedEntrance
        isVisible={this.props.loading}
        style={{ ...{ ...styles.containerStyle, ...this.props.style } }}
      >
        <View
          style={{
            alignSelf: "center",
          }}
        >
          <Lottie
            options={defaultOptions}
            ref={this.onRefReceived.bind(this)}
            height={this.width}
            width={this.height}
          />
        </View>
      </AnimatedEntrance>
    );
  }
}
