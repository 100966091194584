import { store } from "cockpit-shared";
import { getRootStore } from "../../ui/app";
let CHART_TABS_ID = store.charts.chartTabs.CHART_TABS_ID;
export const routeError = "routeError";

export function getHelperPath() {
  let location = "";
  let state = getRootStore().getState();
  if (!window) {
    return "home";
  }
  location = window.location.href;
  if (location.includes("/login")) {
    return "login";
  }
  if (location.includes("/chart-data")) {
    let selectedChartTab = state.chartsStore.selectedTabId;
    switch (selectedChartTab) {
      case CHART_TABS_ID.BUBBLE: {
        return "bubble-chart";
      }
      case CHART_TABS_ID.ENTITY: {
        return "entity-chart";
      }
      case CHART_TABS_ID.ENTITY_HISTORY: {
        return "history-chart";
      }
      case CHART_TABS_ID.RANKING: {
        return "ranking-chart";
      }
      default: {
        return "bubble-chart";
      }
    }
  }
  if (location.includes("/datasets")) {
    return "home";
  }
  return "home";
}
