//@flow
import * as React from "react";
import { View } from "react-native";
import { connect } from "react-redux";
import { store, constants } from "cockpit-shared";
import SelectedTabContent from "./SelectedTabContent";
import { ChartContainerTabs } from "./tab/ChartContainerTabs";
import { ChartReducerType } from "./SelectedTabContent";
import ChartsAvailability from "cockpit-shared/src/data/chart-data/ChartsAvailability";
import { LoadingIndicator } from "../loading-indicator";
import { DimensionsTablePage } from "../dimension-table-view";
import { getRootStore } from "../app";
import { organizationsActions } from "cockpit-shared/src/store/organizations";
import { RouteActions } from "../../navigation";
import Menu from "../drillDownMenu/Menu";
import { withRouter } from "react-router-dom";
import { Header } from "../general-header";
import { Toggle } from "../toggle";

export type ChartsContainerProps = {
  chartsReducer: ChartReducerType,
  loadChartData: Function, // todo - I'm stuck, need help
  isLoading: boolean,
  isDimensionsLoading: boolean,
  isChartsLoading: boolean,
  spinnerEnabled: boolean,
  chartTabId: store.charts.chartTabs.ChartTabType,
  changeTab: (tabId: string) => void,
  changeFormatType: (
    formatType: store.charts.dataFormats.DataFormatType
  ) => void,
  isToggleOn: boolean,
  dimensionName: string,
  currentEntityName: string,
  shouldDisableOnToggle: boolean,
  isLastEntityChildSelected: boolean,
  onEntitySelected: Function,
  currenDataSetMonth: number,
  resetReducer: Function,
  dimensions: Array<Object>,
  changeFormatType: (newFormat) => void
};

export type ChartsContainerState = {};

class ChartsContainer extends React.Component<
  ChartsContainerProps,
  ChartsContainerState
  > {
  drillDonwFunc: ?Function;
  drillUpFunc: ?Function;

  constructor() {
    super();
    this.drillDonwFunc = null;
    this.drillUpFunc = null;
    this.state = {
      isDimensionSelected: false,
      isLoading: true,
    };
  }

  componentDidMount() {
    this.load();
    this.unlistenForRouteChanges = this.props.history.listen(
      (location, action) => {
        if (action === "POP") {
          this.load();
          this.setState({
            isDimensionSelected: false,
            isLoading: true,
          });
        }
      }
    );
  }

  async load() {
    await this.props.prepareParams();
    await this.props.loadChartData();
    this.setState({
      isDimensionSelected: true,
      isLoading: false,
    });
  }

  onChartTapPress(chartTabId: store.charts.chartTabs.ChartTabType) {
    this.props.changeTab(chartTabId);
  }

  onCategoryTabPress(category: Object) {
    this.props.onSubCategoryTap(category);
  }

  onDimensionItemTap(item) {
    this.props.loadChartData();
  }

  onEntityColumnTap(newSelectedOrganization: Object) {
    if (this.drillDonwFunc) {
      this.drillDonwFunc(newSelectedOrganization);
    }
  }

  onDrillUp(organizationEntityParent: Object) {
    if (this.drillUpFunc) {
      this.drillUpFunc(organizationEntityParent);
    }
  }

  componentWillUnmount() {
    this.unlistenForRouteChanges || this.unlistenForRouteChanges();
    this.props.resetReducer();
  }

  shouldComponentUpdate(newProps: ChartsContainerProps) {
    return (
      newProps.isDimensionsLoading !== this.props.isDimensionsLoading ||
      newProps.isChartsLoading !== this.props.isChartsLoading ||
      newProps.chartTabId !== this.props.chartTabId ||
      newProps.categoriesState?.category?.id !==
      this.props.categoriesState?.category?.id ||
      newProps.isToggleOn !== this.props.isToggleOn ||
      newProps.spinnerEnabled !== this.props.spinnerEnabled
    );
  }

  get chartsAvailability(): ChartsAvailability {
    let reducer = this.props.chartsReducer;
    if (reducer && reducer.data) {
      return reducer.data.chartsAvailability;
    } else {
      return new ChartsAvailability();
    }
  }

  entityHasChildren() {
    let hasChildren =
      (
        getRootStore().getState().organizationsStore.selectedOrganization
          .children || []
      ).length !== 0;
    return hasChildren;
  }

  getOrganizations() {
    return getRootStore().getState().organizationsStore.organizations;
  }

  getSelectedCategoryName() {
    let categories = this.props.categoriesState.categories;
    let categoryId = this.props.match.params.category;
    var selectedCategory = categories?.find((item) => item.id === categoryId);
    if (!selectedCategory) {
      categories.forEach((category) => {
        let selectedSubCategory = category.children.find((subCategory) => subCategory.id === categoryId);
        if (selectedSubCategory) {
          selectedCategory = selectedSubCategory.name;
        }
      });
    } else {
      return selectedCategory.name;
    }
    return selectedCategory;
  }

  onToggleChanged = (isOn) => {
    if (this.props.shouldDisableOnToggle) {
      return;
    }
    let newFormatType = isOn
      ? store.charts.dataFormats.dataFormatTypes.absolut
      : store.charts.dataFormats.dataFormatTypes.percentage;
    this.props.changeFormatType(newFormatType);
  };

  render() {
    return (
      <View style={{ display: "flex", flex: 1, flexDirection: "column" }}>
        <Header {...this.props}
          style={{ backgroundColor: constants.colors.w_blue, paddingRight: 12 }}
          title={this.getSelectedCategoryName()}
          categoryTabPress={this.onCategoryTabPress.bind(this)}
        />
        <View style={{ display: "flex", flex: 1, flexDirection: "column" }}>
          <div className="absolute-overlay" id="absolute-overlay" />
          <Menu {...this.props} />
          <div className="second_header_row">
            <View style={{ flex: 1, flexDirection: "row" }}>
              <Toggle
                onToggle={this.onToggleChanged}
                isOn={this.props.isToggleOn}
                style={{ left: "28vw", justifyContent: "center" }}
                disabled={this.props.shouldDisableOnToggle}
              />
            </View>
            <View style={{ flex: 2, flexDirection: "row" }} >
              <ChartContainerTabs
                selectedTabId={this.props.chartTabId}
                onTabPress={this.onChartTapPress.bind(this)}
                isLastEntityChildSelected={
                  this.props.isLastEntityChildSelected
                }
                chartsAvailability={this.chartsAvailability}
              />
            </View>
          </div>
          <View style={{ display: "flex", flex: 1, flexDirection: "row" }}>
            <View style={{ display: "flex", flex: 1 }}>
              <DimensionsTablePage
                match={this.props.match}
                history={this.props.history}
                isPercentage={!this.props.isToggleOn}
                categories={this.props.dimensions}
                isLoading={this.props.isDimensionsLoading}
                hasChildren={this.entityHasChildren()}
              />
            </View>
            <View style={{ display: "flex", flex: 2, backgroundColor: "white" }}>
              {this.state.isDimensionSelected ? (
                <View style={{ display: "flex", flex: 1 }}>
                  <SelectedTabContent
                    match={this.props.match}
                    history={this.props.history}
                    tabId={this.props.chartTabId}
                    chartsReducer={this.props.chartsReducer}
                    currentEntityName={this.props.currentEntityName}
                    isLoading={this.props.isChartsLoading || this.state.isLoading || this.props.isCategoriesLoading}
                    onEntityColumnTap={this.onEntityColumnTap.bind(this)}
                    onDrillUp={this.onDrillUp.bind(this)}
                    currenDataSetMonth={this.props.currenDataSetMonth}
                  />
                </View>
              ) : null}
            </View>
            <LoadingIndicator
              loading={this.props.isLoading || this.state.isLoading}
            />
          </View>
        </View>
      </View>
    );
  }
}

function createDimensionCategories(dimensions: Array<Object>) {
  if (dimensions.length === 0) {
    return [];
  }
  let allCategories = dimensions.map((dimension) => dimension.ytdLabel);
  let uniqueCategories = [...new Set(allCategories)];
  let sectionsList = uniqueCategories.map((category, index) => {
    return {
      index,
      name: category,
      data: dimensions.filter((dimension) => dimension.ytdLabel === category),
    };
  });
  return sectionsList;
}

export function findOrganization(organizations, orgId) {
  let orgs = [];
  let addOrgs = (org) => {
    orgs.push(org);
    if (org.children) {
      org.children.forEach(addOrgs);
    }
  };
  organizations.forEach(addOrgs);
  let solution = orgs.find((item) => item.id === orgId);
  return solution;
}

function mapStateToProps(state, props) {
  let formatType = state.chartsStore.formatType;
  let chartTabId = state.chartsStore.selectedTabId;
  let isToggleOn =
    formatType === store.charts.dataFormats.dataFormatTypes.absolut;
  let shouldDisableOnToggle = false;
  if (
    chartTabId === store.charts.chartTabs.CHART_TABS_ID.BUBBLE ||
    chartTabId === store.charts.chartTabs.CHART_TABS_ID.RANKING
  ) {
    isToggleOn = false;
    shouldDisableOnToggle = true;
  }
  return {
    match: props.match,
    history: props.history,
    chartsReducer: state.chartsStore,
    isLoading: state.dataSetStore.isLoading ||
      state.categoriesStore.isLoading ||
      state.dimensionsStore.isLoading ||
      state.organizationsStore.isLoading ||
      state.chartsStore.isLoading,
    isCategoriesLoading: state.categoriesStore.isLoading,
    isDimensionsLoading: state.dimensionsStore.isLoading,
    isChartsLoading: state.chartsStore.isLoading,
    spinnerEnabled: state.chartsStore.spinnerEnabled,
    chartTabId: state.chartsStore.selectedTabId,
    isToggleOn,
    currentEntityName: state.organizationsStore?.selectedOrganization?.name || "",
    shouldDisableOnToggle,
    isLastEntityChildSelected:
      state.organizationsStore.selectedOrganization.children.length === 0,
    currenDataSetMonth: state.dataSetStore.currentMonth,
    dimensions: createDimensionCategories(state.dimensionsStore.dimensions),
    categoriesState: state.categoriesStore,
  };
}

const prepareParams = async (ownProps, dispatch) => {
  let params = ownProps.match.params;
  let datasetId = params.dataset;
  let categoryId = params.category;
  let entityId = params.entity;
  let dimensionId = params.dimension;

  await dispatch(store.dataSet.dataSetActions.loadDataSets());
  if (datasetId == null || datasetId == "undefined") {
    let defaultDataSet = await dispatch(store.dataSet.dataSetActions.loadDefaultDataSet());
    await dispatch(store.dataSet.dataSetActions.changeDataSet(defaultDataSet));
    datasetId = defaultDataSet.payload.id;
  } else {
    await dispatch(store.dataSet.dataSetActions.changeDataSet(datasetId));
  }
  let categories = await dispatch(
    store.categories.categoriesActions.loadCategoriesForTheCurrentDataSet()
  );
  if (categoryId == null) {
    let defCategory = categories[0];
    categoryId = categories[0].id;
    await dispatch(store.categories.categoriesActions.selectCategory(defCategory));
  }
  var selectedCategory = categories?.find((item) => item.id === categoryId);
  if (selectedCategory == null) {
    // Search in subcategories
    for (let item of categories) {
      let findResult = item.children.find(
        (subCategory) => subCategory.id === categoryId
      );
      if (findResult != null) {
        selectedCategory = findResult;
        break;
      }
    }
  }
  if (selectedCategory == null) {
    new RouteActions().goToNotFoundPage(ownProps);
    return;
  } else {
    await dispatch(
      store.categories.categoriesActions.selectCategory(selectedCategory)
    );
  }
  let itNeedsUrlRefresh = false;
  let orgs = await dispatch(organizationsActions.loadOrganizations());
  if (entityId == null) {
    itNeedsUrlRefresh = true;
    if (orgs && orgs.length > 0) {
      entityId = orgs[0].id;
      await dispatch(organizationsActions.selectOrganization(orgs[0]));
    }
  }
  let dimensions = await dispatch(
    store.dimensions.dimensionsActions.loadDimensions()
  );
  if (dimensionId == null) {
    itNeedsUrlRefresh = true;
    if (dimensions && dimensions.length > 0) {
      dimensionId = dimensions[0].id;
      await dispatch(
        store.dimensions.dimensionsActions.selectDimension(dimensions[0])
      );
    }
  }
  if (itNeedsUrlRefresh) {
    new RouteActions().goToChartsContainer(
      ownProps,
      datasetId,
      categoryId,
      entityId,
      dimensionId
    );
    return;
  }
  let selectedOrganization = findOrganization(orgs, entityId);
  if (!selectedOrganization) {
    return;
  }
  await dispatch(organizationsActions.selectOrganization(selectedOrganization));
  dimensions = await dispatch(
    store.dimensions.dimensionsActions.loadDimensions()
  );
  var selectedDimension = dimensions.find((item) => item.id === dimensionId);
  await dispatch(
    store.dimensions.dimensionsActions.selectDimension(selectedDimension)
  );
};

function mapDispatchToProps(dispatch, ownProps) {
  return {
    dispatch,
    loadChartData: async () => {
      dispatch(store.charts.chartsActions.loadChartData());
    },
    changeTab: (tabId: string) =>
      dispatch(store.charts.chartsActions.changeTab(tabId)),
    onEntitySelected: () => ownProps.navigation.goBack(),
    resetReducer: () => dispatch(store.charts.chartsActions.resetState()),
    loadDimensionsTableData: async () => { },
    clearData: () => {
      dispatch(store.dimensions.dimensionsActions.resetState());
      dispatch(store.organizations.organizationsActions.clearOrganizations());
      dispatch(store.categories.categoriesActions.selectCategory(null));
    },
    displayLoadingSpinner: (loading) => {
      dispatch(store.dimensions.dimensionsActions.toggleSpinner(loading));
    },
    selectOrganization: async (organization) => {
      //Display spinner as soon as an organization is choosed
      await dispatch(store.dimensions.dimensionsActions.toggleSpinner(true));
      await ownProps.screenProps.onEntitySelected();
      dispatch(
        store.organizations.organizationsActions.selectOrganizationAndRefresh(
          organization
        )
      );
    },
    onSubCategoryTap: async (item: Object) => {
      new RouteActions().refreshNavigationOnCategoryChanged(ownProps, item.id);
    },
    prepareParams: () => prepareParams(ownProps, dispatch),
    changeFormatType: (newType) => {
      dispatch(store.charts.chartsActions.changeFormatType(newType));
    }
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ChartsContainer)
);
