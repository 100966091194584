//@flow
export default class ColorConverter {
  static argbToRgba(colorCode: String) {
    let codeLength = colorCode.length;
    if (codeLength === 9) {
      let rgbaColor = "#" + colorCode.substr(3, codeLength - 1);
      return rgbaColor;
    }
    return colorCode;
  }

  static hexToRgba(hex: string, alpha: number) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function(m, r, g, b) {
      return r + r + g + g + b + b;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (result == null) {
      return null;
    }
    let color = {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    };
    return `rgba(${color.r}, ${color.g}, ${color.b}, ${alpha})`;
  }
}
