//@flow
import type { HProps } from "./H";
import type { BaseComponentProps } from "../base-component/BaseComponent";
import * as React from "react";
import { Text, View, ScrollView, Image } from "react-native";
import H from "./H";

export type H5Props = HProps & {};
export type H5State = {};

export const H5Style = {
  fontSize: 11
};

export default class H5 extends React.Component<H5Props, H5State> {
  render() {
    return <H {...this.props} style={{ ...H5Style, ...this.props.style }}>{this.props.children}</H>;
  }
}
