//@flow
import * as React from "react";
import { ui } from "cockpit-shared";
import App from "./App";
import data from "../../data";
import rootReducers from "./RootReducers";
import middlewares from "./Middlewares";
import persistConfig from "./PersistConfig";
import { getOnTokenExpiredHandler } from "./TokenExpiredHandler";

export type AppNativeContainerProps = {};
const Container = (props: AppNativeContainerProps) => {
  return (
    <ui.AppContainer
      strings={data.strings.stringsRepo}
      fetch={fetch}
      reducers={rootReducers}
      middlewares={middlewares}
      persistConfig={persistConfig}
      getLocalizedNumberFormatter={data.number.getLocalizedNumberFormatter}
      getOnTokenExpiredHandler={getOnTokenExpiredHandler}
    >
      <App />
    </ui.AppContainer>
  );
};
export const AppNativeContainer = Container;