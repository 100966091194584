//@flow
import type { BaseComponentProps } from "../base-component";
import * as React from "react";
import { Text, View, ScrollView, Image } from "react-native";
import { Tappable } from "../tappable/index";
import { H3 } from "../texts/index";
import { constants } from "cockpit-shared";

export type ButtonProps = {
  children: ?string,
  style?: any,
  onPress: Function,
  isDisabled?: boolean
};
export type ButtonState = {};
export default class Button extends React.Component<ButtonProps, ButtonState> {
  get isDisabled() {
    return this.props.isDisabled || false;
  }

  get disabledStyle() {
    return {
      backgroundColor: "#73a1d6"
    };
  }

  render() {
    return (
      <View style={this.props.style}>
        <Tappable
          isDisabled={this.isDisabled}
          onTap={this.props.onPress}
          style={{
            width: 227,
            height: 50,
            backgroundColor: constants.colors.accentColor,
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 4,
            ...(this.isDisabled ? this.disabledStyle : {})
          }}
        >
          <H3 style={{ color: "white" }}>{this.props.children}</H3>
        </Tappable>
      </View>
    );
  }
}
