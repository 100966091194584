//@flow
import type { DataFormatType } from "./ChartsReducer";
import type { ChartReducerState } from "./ChartsReducer";
import { createAction } from "../base/BaseActions";
import data from "../../data";
import { chartActionTypes } from ".";
import * as store from "../../store";

export function loadChartData() {
  return async function (dispatch: Function, getState: Function) {
    let manager = new data.charts.ChartsManager();
    let chartLoadingAction = createAction(chartActionTypes.LOAD_HISTORY_CHART_DATA, {
      isLoading: true
    });
    await dispatch(chartLoadingAction);
    let rankingPromise = dispatch(
      store.entitiesRanking.entitiesRankingActions.loadEntitiesRanking()
    );
    let s = getState();
    let chartsStore: ChartReducerState = s.chartsStore;
    let dataSetStore = s.dataSetStore;
    let dimensionsStore = s.dimensionsStore;
    let organizationsStore = s.organizationsStore;
    let response = await manager.getChartData(
      dataSetStore?.id,
      s.categoriesStore.category?.id,
      organizationsStore.selectedOrganization?.id,
      dimensionsStore.selectedDimension?.id
    );
    let endActionPayload;
    if (response instanceof data.error.ServiceError) {
      endActionPayload = {
        isLoading: false
      };
    } else {
      endActionPayload = {
        isLoading: false,
        data: response
      };
    }
    let endAction = createAction(chartActionTypes.LOAD_HISTORY_CHART_DATA, endActionPayload);
    await rankingPromise;
    await dispatch(endAction);
    return response;
  };
}

export function changeTab(id: string) {
  return async function (dispatch: Function, getState: Function) {
    let action = createAction(chartActionTypes.SWITCH_TAB, {
      selectedTabId: id
    });
    return dispatch(action);
  };
}

export function changeFormatType(newFormatType: DataFormatType) {
  return async function (dispatch: Function, getState: Function) {
    let action = createAction(chartActionTypes.CHANGE_FORMAT_TYPE, {
      formatType: newFormatType
    });
    dispatch(action);
  };
}

export function resetState() {
  return async function (dispatch: Function, getState: Function) {
    let action = createAction(chartActionTypes.RESET_STATE, {});
    return dispatch(action);
  };
}

export function updateChartDataLoadingState(isLoading: boolean) {
  return async function (dispatch: Function, getState: Function) {
    let action = createAction(chartActionTypes.SWITCH_TAB, {
      isLoading
    });
    return dispatch(action);
  };
}

export function toggleSpinner(enabled: boolean) {
  return async function (dispatch: Function, getState: Function) {
    let action = createAction(chartActionTypes.TOGGLE_SPINNER, {
      isLoading: enabled,
      spinnerEnabled: enabled
    });
    return dispatch(action);
  };
}