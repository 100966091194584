//@flow
import { store } from "cockpit-shared";
import { getRootStore } from "../../ui/app";

export default class NetworkConnectionHelper {
  listenToNetworkConnectionChanges() {
    this._handleConnectionChange();
    window.addEventListener("online", this._handleConnectionChange.bind(this));
    window.addEventListener("offline", this._handleConnectionChange.bind(this));
  }

  stopListen() {
    window.removeEventListener(
      "online",
      this._handleConnectionChange.bind(this)
    );
    window.removeEventListener(
      "offline",
      this._handleConnectionChange.bind(this)
    );
  }

  _handleConnectionChange() {
    let isOnline = navigator.onLine;
    getRootStore().dispatch(
      store.connectivity.connectivityActions.onNetworkConnectionChanged(
        isOnline
      )
    );
  }
}
