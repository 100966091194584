//@flow
import type { BaseComponentProps } from "../../base-component";
import * as React from "react";
import { View } from "react-native";
import { store, strings } from "cockpit-shared";
import data from "../../../data";
import EntityChartCommonTabContent, {
  addCommonProps, isGoodColorPredicate
} from "./EntityChartCommonTabContent";
import { getRootStore } from "../../app";
import { InvertedViewModel } from "cockpit-shared/src/data/inverted";
import { ChartReducerType } from "../SelectedTabContent";

export type EntityHistoryTabContentProps = {
  chartsReducer: ChartReducerType,
  isLoading: boolean,
  currenDataSetMonth: number,
};
export type EntityHistoryTabContentState = {};
export default class EntityHistoryTabContent extends React.Component<
  EntityHistoryTabContentProps,
  EntityHistoryTabContentState
  > {

  get subEntities() {
    let formatter = data.date.getLocalizedDateFormatter();
    let currentMonthNumber = this.props.currenDataSetMonth;
    return formatter.getFiscalYearMonths(currentMonthNumber).map(monthName => {
      return { name: monthName.toUpperCase().slice(0, 3), hasChildren: false };
    });
  }

  get isPercentage() {
    return (
      this.chartsReducer.formatType ===
      store.charts.dataFormats.dataFormatTypes.percentage
    );
  }

  get chartsReducer() {
    return this.props.chartsReducer;
  }

  get budgetValues() {
    if (!this.chartsReducer.data) {
      return [];
    }
    return this.chartsReducer.data.budgetMonths(this.isPercentage);
  }

  get budgetValuesFormatted() {
    if (!this.chartsReducer.data) {
      return [];
    }
    return this.chartsReducer.data.budgetMonthsFormatted(this.isPercentage);
  }

  //Precedent
  get vorjahrValues() {
    if (!this.chartsReducer.data) {
      return [];
    }
    return this.chartsReducer.data.previousYearMonths(this.isPercentage);
  }

  get vorjahrValuesFormatted() {
    if (!this.chartsReducer.data) {
      return [];
    }
    return this.chartsReducer.data.previousYearMonthsFormatted(
      this.isPercentage
    );
  }

  //Current year
  get istValues() {
    if (!this.chartsReducer.data) {
      return [];
    }
    return this.chartsReducer.data.actMonthsAbsolute;
  }

  get istValuesFormatted() {
    if (!this.chartsReducer.data) {
      return [];
    }
    return this.chartsReducer.data.actMonthsFormatted();
  }

  istValueColorPredicate(value: number, index: number) {
    let currentDataSetMonth = getRootStore().getState().dataSetStore.currentMonth; // 0 - 11
    let numberOfMonthsInTheCurrentYear = 12 - currentDataSetMonth;
    //Only the months of the following year will be dark blue
    return index >= numberOfMonthsInTheCurrentYear;
  }

  get invertedStatus(): InvertedViewModel {
    if (!this.chartsReducer.data) {
      return false;
    }
    return this.chartsReducer.data.invertedStatus;
  }

  get istChartInverted() {
    if (!this.chartsReducer.data) {
      return false;
    }
    return this.invertedStatus.isBarChartActualYearInverted();
  }
  get vorjahrChartInverted() {
    if (!this.chartsReducer.data) {
      return false;
    }
    return this.invertedStatus.isBarChartDeviationInverted(this.isPercentage);
  }
  get budgetChartInverted() {
    if (!this.chartsReducer.data) {
      return false;
    }
    return this.invertedStatus.isBarChartDeviationInverted(this.isPercentage);
  }
  get isPercentage() {
    return this.chartsReducer.formatType === store.charts.dataFormats.dataFormatTypes.percentage;
  }

  budgetValueColorPredicate(value: number, index: number, isInverted: boolean) {
    // We always use the absolute value. The "value" parameter might be a percentage.
    let absoluteValue = this.chartsReducer.data.budgetMonths(false)[index];
    return isGoodColorPredicate(absoluteValue, isInverted);
  }

  vorjahrValueColorPredicate(value: number, index: number, isInverted: boolean) {
    // We always use the absolute value. The "value" parameter might be a percentage.
    let absoluteValue = this.chartsReducer.data.previousYearMonthsAbsolute[index];
    return isGoodColorPredicate(absoluteValue, isInverted);
  }


  render() {
    return (
      <View style={{ flex: 1, paddingHorizontal: 12 }}>
        <EntityChartCommonTabContent
          {...addCommonProps.bind(this)()}
          currentEntityName={strings.stringsRepo.ytd}
          istValueColorPredicate={this.istValueColorPredicate.bind(this)}
          headersDisabled={true}
          istChartInverted={this.istChartInverted}
          vorjahrChartInverted={this.vorjahrChartInverted}
          budgetChartInverted={this.budgetChartInverted}
          scrollChartHorizontallyToEnd={true}
          yAxisPosition={"right"}
          isHistory={true}
        />
      </View>
    );
  }
}
