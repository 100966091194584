//@flow
import { BaseManager } from "../base";
import { RequestTypes } from "../request-type/RequestType";
import type { BaseManagerResponse } from "../base/BaseManagerResponse";
import ServiceError from "../error/ServiceError";
import { RankViewModel } from "../rank";
import RankResponse from "../rank/RankResponse";
import { stringsRepo } from "../../strings";

export default class EntitiesRankingManager extends BaseManager {
  async getEntitiesRanking(
    dataSetId: string,
    category: string,
    entityId: string,
    dimensionId: string
  ) {
    let url = `${dataSetId}/categories/${category}/entities/${entityId}/dimensions/${dimensionId}/ranking-data/`;
    let response: BaseManagerResponse | RankResponse = await this.fetchJSON(RequestTypes.GET, url);
    if (response instanceof ServiceError) {
      return response;
    }
    var json = {
      ...response,
      ytdLabel: response.ytd_label || stringsRepo.ytd
    }
    let ranks = json.entries.map(rankJson => new RankViewModel(rankJson));
    let relativeRanks = this.addRelativeYtd(ranks)
    return {
      rankings: relativeRanks,
      ytdLabel: json.ytd_label
    };
  }

  addRelativeYtd(_rankings: Array<RankViewModel>): Array<RankViewModel> {
    if (_rankings.length === 0) {
      return [];
    }
    // clone rankings
    let rankings = _rankings.map((value) => value);
    //Descending sorting by ytd value
    let descendingSortedRankings = rankings.sort((a, b) => (Math.abs(a.ytd) > Math.abs(b.ytd) ? -1 : 1));
    //Get max ytd to compute relative ranking bar width
    let maxYtd = descendingSortedRankings[0].ytd;
    return _rankings
      .map((value, index) => {
        value.relativeYtd = ((100 * Math.abs(value.ytd)) / Math.abs(maxYtd)) | 0;
        value.relativeRank = index + 1;
        return value;
      });
  }
}