import { chartTabs } from "../../store/charts";

const chartDataStandard = "standard";
const chartDataHistory = "history";
const chartDataNone = "none";
const chartDataNoHistory = "no-history";
export default class ChartsAvailability {
  chartData: string;
  constructor(chartData: string) {
    if (chartData) {
      this.chartData = chartData;
    } else {
      this.chartData = "none";
    }
  }
  isAnyChartAvailable(): boolean {
    return this.chartData !== chartDataNone;
  }
  isHistoryChartAvailable(): boolean {
    //Bubble chart is available for "standard" and "history" data
    return this.chartData === chartDataStandard
      || this.chartData === chartDataHistory;
  }
  isStandardChartAvailable(): boolean {
    return this.chartData === chartDataStandard;
  }
  isBubbleChartAvailable(): boolean {
    //Bubble chart is available for "standard" and "no-history" data
    return this.isStandardOrNoHistory();
  }
  isEntityChartAvailable(): boolean {
    //Entity chart is available for "standard" and "no-history" data
    return this.isStandardOrNoHistory();
  }
  isRankingChartAvailable(): boolean {
    //Ranking chart is available for "standard" and "no-history" data
    return this.isStandardOrNoHistory();
  }
  isStandardOrNoHistory(): boolean {
    return this.isStandardChartAvailable()
      || this.chartData === chartDataNoHistory;
  }
  isTabAvailable(tabId: String): boolean {
    switch (tabId) {
      case chartTabs.CHART_TABS_ID.BUBBLE: {
        return this.isBubbleChartAvailable();
      }
      case chartTabs.CHART_TABS_ID.ENTITY: {
        return this.isEntityChartAvailable();
      }
      case chartTabs.CHART_TABS_ID.ENTITY_HISTORY: {
        return this.isHistoryChartAvailable();
      }
      default: {
        return this.isRankingChartAvailable();
      }
    }
  }
}
