//@flow
import type { BaseComponentProps } from "../base-component";
import * as React from "react";
import {
  View,
  Animated,
  TouchableOpacity
} from "react-native";
import { constants } from "cockpit-shared";
import * as icon from "../icon";

export type ToggleProps = BaseComponentProps & {
  isOn: boolean,
  disabled: boolean,
  size?: string,
  onToggle: Function,
  onBackgroundColor?: string,
  offBackgroundColor?: string,
  circleOnColor?: string,
  circleOffColor?: string,
  renderOnCircleContent?: Function,
  renderOffCircleContent?: Function,
  width?: number,
  animationDuration?: number,
};

export type ToggleState = {
  isOn: boolean,
  offsetX: Animated.Value,
  initialDirection: Object
};

export default class Toggle extends React.Component<ToggleProps, ToggleState> {
 
  constructor(props: ToggleProps) {
    super(props);
    this.state = {
      isOn: this.props.isOn || false,
      offsetX: new Animated.Value(this.props.isOn ? 0 : this.offOffsetXValue),
      initialDirection: this.props.isOn ? { right: 0 } : { right: 0 }
    };
  }

  UNSAFE_componentWillReceiveProps(newProps: ToggleProps) {
    if (newProps.isOn !== this.props.isOn) {
      this.onToggle();
    }
  }

  get dimensions() {
    return {
      width: this.props.width || 55,
      cercleWidth: 28,
      cercleHeight: 28,
      translateX: 28
    };
  }

  get onColor() {
    return this.props.onBackgroundColor || constants.colors.grayBlue;
  }

  get offColor() {
    return this.props.offBackgroundColor || constants.colors.grayBlue;
  }

  get circleOnColor() {
    return this.props.circleOnColor || constants.colors.middleBlue;
  }

  get circleOffColor() {
    return this.props.circleOffColor || constants.colors.middleBlue;
  }

  get circleColor() {
    return this.state.isOn ? this.circleOnColor : this.circleOffColor;
  }

  get size() {
    return this.props.size || "medium";
  }

  get onIconName() {
    return icon.iconAssets.absolute;
  }

  get offIconName() {
    return icon.iconAssets.percentage;
  }

  get iconName() {
    return this.state.isOn ? this.onIconName : this.offIconName;
  }

  get onOffsetXValue() {
    return -this.dimensions.width + this.dimensions.translateX;
  }

  get offOffsetXValue() {
    return this.dimensions.translateX - this.dimensions.width;
  }

  get animationDuration() {
    return this.props.animationDuration || 500;
  }

  onToggle() {
    var toValue = 0;
    if (this.state.isOn) {
      toValue = this.onOffsetXValue;
    }
    Animated.timing(this.state.offsetX, {
      toValue: toValue,
      duration: this.animationDuration
    }).start();
    let newState = !this.state.isOn;
    this.setState({
      ...this.state,
      isOn: newState
    });
    this.props.onToggle(newState);
  }

  get shouldRenderCustomCircleContent() {
    return (
      this.props.renderOnCircleContent != null &&
      this.props.renderOffCircleContent != null
    );
  }

  renderDefaultCircleContent() {
    return () => (
      <icon.Icon
        name={this.iconName}
        color={constants.colors.blackBlue}
        size={12}
      />
    );
  }

  renderCustomCircleContent() {
    return this.state.isOn
      ? this.props.renderOnCircleContent()
      : this.props.renderOffCircleContent();
  }

  render() {
    let CircleContent = this.shouldRenderCustomCircleContent
      ? this.renderCustomCircleContent()
      : this.renderDefaultCircleContent();
    return (
      <View
        style={[this.props.style, { opacity: this.props.disabled ? 0.3 : 1 }]}
      >
        <TouchableOpacity
          style={{
            justifyContent: "center",
            width: this.dimensions.width,
            height: this.dimensions.cercleHeight,
            borderRadius: this.dimensions.cercleWidth / 2,
            backgroundColor: this.state.isOn ? this.onColor : this.offColor
          }}
          activeOpacity={0.8}
          onPress={this.onToggle.bind(this)}
          disabled={this.props.disabled}
        >
          <Animated.View
            style={{
              transform: [{ translateX: this.state.offsetX }],
              position: "absolute",
              ...this.state.initialDirection,
              width: this.dimensions.cercleWidth,
              height: this.dimensions.cercleHeight,
              borderRadius: this.dimensions.cercleWidth / 2,
              backgroundColor: this.circleColor,
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Animated.View
              style={{
                transform: [
                  {
                    rotate: this.state.offsetX.interpolate({
                      inputRange: [
                        this.onOffsetXValue,
                        this.onOffsetXValue / 2,
                        0
                      ],
                      outputRange: ["0deg", "90deg", "360deg"]
                    })
                  }
                ]
              }}
            >
              <CircleContent />
            </Animated.View>
          </Animated.View>
        </TouchableOpacity>
      </View>
    );
  }
}
