//@flow
import ServiceError from "../error/ServiceError";
import type { RequestType } from "../request-type";
import type { BaseServiceResponse } from "./BaseServiceResponse";
import { ConnectivityInterface } from "../connectivity";

export interface BaseServiceInterface {
  fetch: typeof fetch;
  conectivity: ConnectivityInterface;
  makeRequest: (
    type: RequestType,
    url: string,
    token: string,
    body: ?Object,
    callId: ?string,
    headers: ?any
  ) => Promise<BaseServiceResponse>
};
