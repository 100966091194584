//@flow
import React from "react";
import { View } from "react-native";
import { LoginHeader } from "../navigation-header";
import { connect } from "react-redux";
import { constants } from "cockpit-shared";
import { H1 } from "../texts";

export type NotFoundPageProps = {};
export type NotFoundPageState = {};

class NotFoundPage extends React.Component<
  NotFoundPageProps,
  NotFoundPageState
  > {

  render() {
    return (
      <div
        style={{
          flex: 1,
          background: constants.colors.w_blue,
          display: "flex",
          flexDirection: "column"
        }}
      >
        <LoginHeader
          notFoundPage={true}
        />
        <View style={{ display: "flex", flex: 1, justifyContent: "center" }}>
          <H1
            style={{
              display: "flex",
              color: "white",
              alignSelf: "center",
            }}
          >
            404 NOT FOUND
          </H1>
        </View>
      </div>
    );
  }
}

function mapStateToPros(state, props) {
  return {};
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    dispatch,
  };
}

export default connect(mapStateToPros, mapDispatchToProps)(NotFoundPage);
