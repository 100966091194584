//@flow
import * as React from "react";
import "../../charts.css";
import { View } from "react-native";
import styles from "./BarChartStyle";
import BarChartNoDataContainer from "./BarChartNoDataContainer";
import Chart from "chart.js";
import * as chartData from "./BarChartData";
import "../../../app/App.css";

export type onLayoutFunction = (layout: LayoutType) => void;
export type LayoutType = {
  x: number,
  y: number,
  width: number,
  height: number,
};

export type BarChartProps = {
  goodColor: string,
  badColor: string,
  yValues: Array<Object>,
  valueColorPredicate: (value: number, index: number) => boolean,
  onLayout: onLayoutFunction,
  onColumnSelect: (x: number) => void,
  isLoading: boolean,
  minimumNumberOfCollumns: number,
  isPercentageValues: boolean,
  axisInverted: boolean,
  highlightedXIndex: ?number,
  title: string,
  yAxisPosition: ?"left" | "right", //todo ask about it
  columnWidthInEms: ?number,
  isHistory: ?boolean,
  barWidth: ?number,
  multiplicator: ?number
};

type DataSetType = {
  values: { y: number, marker: string }[],
  label: string,
  config: {
    drawValues: boolean,
    colors: string[],
  },
};
export type BarChartState = {
  data: {
    dataSets: Array<DataSetType>,
  },
  yAxis: { left: AxisType, right: AxisType },
};

export type AxisType = {
  labelWidth: string,
  labelWidthStyle: string,
  labelCount: string,
};
class BarChart extends React.Component<BarChartProps, BarChartState> {
  barChartRef;
  chartPaddingLeft: number;
  state: BarChartState;

  constructor(props: BarChartProps) {
    super(props);
    this.chartInstance = undefined;
    this.barChartRef = React.createRef();

    let labelWidth = {};
    if (props.labelWidthStyle) {
      //If the style is fixedInput, but the width is undefined, do not apply the prop
      if (
        props.labelWidthStyle !== "fixedInput" ||
        (typeof props.labelWidth !== "undefined" && props.labelWidth !== null)
      ) {
        labelWidth = {
          labelWidthStyle: props.labelWidthStyle,
          labelWidth: props.labelWidth,
        };
      }
    }
    let colors = BarChart.getColorsFromProps(props);
    this.state = {
      data: {
        dataSets: [
          {
            values: BarChart.getDataSetValues(props.yValues),
            label: "",
            config: {
              drawValues: false,
              colors,
            },
          },
        ],
        config: {
          barWidth: this.getBarWidth(props.columnWidthInEms),
        },
      },
      yAxis: {
        [this.mainYAxisName]: {
          drawGridLines: false,
          ...labelWidth,
          inverted: this.props.axisInverted,
        },
        [this.secondYAxisName]: {
          drawGridLines: false,
          inverted: this.props.axisInverted,
        },
      },
    };
  }

  componentDidMount() {
    const myChartRef = this.barChartRef.current;
    this.chartInstance = new Chart(myChartRef, {
      type: "bar",
      data: chartData.barChartData(
        this.state.data.dataSets[0],
        this.state.yAxis[this.mainYAxisName].inverted,
        this.props.minimumNumberOfCollumns
      ),
      options: chartData.barChartOptions(this.props.onColumnSelect, this.props.title, this.state.yAxis[this.mainYAxisName].inverted, this.props.isPercentageValues),
    });
  }

  clearHighlight(index) {
    var requestedElem = this.chartInstance.getDatasetMeta(0).data[index];
    requestedElem._model.backgroundColor = this.chartInstance.tooltip._data.datasets[0].backgroundColor[
      index
    ];

    var activeElements = this.chartInstance.tooltip._active;
    if (activeElements == undefined || activeElements.length == 0) {
      return;
    }
    for (var i = 0; i < activeElements.length; i++) {
      if (requestedElem._index == activeElements[i]._index) {
        activeElements.splice(i, 1);
        break;
      }
    }
    this.chartInstance.tooltip._active = activeElements;
    this.chartInstance.tooltip.update();
    this.chartInstance.draw();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.highlightedXIndex !== this.props.highlightedXIndex) {
      if (this.props.highlightedXIndex == null) {
        this.clearHighlight(prevProps.highlightedXIndex);
      } else {
        this.handleSelect(this.props.highlightedXIndex);
      }
    }
  }

  getBarWidth(columnWidthInEms: number) {
    return (columnWidthInEms || 0) <= 3 ? 0.9 : 0.675;
  }

  static getColorsFromProps(props: BarChartProps) {
    let y = props.yValues;
    let colors = y.map((value: Object, index: number) =>
      props.valueColorPredicate(value.y, index)
        ? props.goodColor
        : props.badColor
    );
    return colors;
  }

  static getDerivedStateFromProps(
    nextProps: BarChartProps,
    prevState: BarChartState
  ) {
    let y1 = prevState.data.dataSets[0].values || [];
    let y2 = nextProps.yValues || [];
    //Compare y values
    if (
      y1.length === y2.length &&
      y1.every((val, index) => {
        return val.y === y2[index].y && val.marker === y2[index].marker;
      })
    ) {
      //Nothing is changed, we do not update the chart
      return null;
    }
    if (nextProps.isLoading) {
      return null;
    }
    let colors = BarChart.getColorsFromProps(nextProps);
    let newState: BarChartState = {
      ...prevState,
      data: {
        ...prevState.data,
        dataSets: [
          {
            values: BarChart.getDataSetValues(y2),
            label: "",
            config: {
              drawValues: false,
              colors,
            },
          },
        ],
        yAxis: {
          ...prevState.yAxis,
          left: {
            ...prevState.yAxis["left"],
            inverted: nextProps.axisInverted,
          },
        },
      },
    };
    return newState;
  }

  static getDataSetValues(yVals: Array<Object>): Array<Object> {
    return (yVals || []).map((val: Object, index: number) => {
      return { ...val, x: index };
    });
  }

  /**
   * The main Y axis is the one with values and labels,
   * the other one will only contain some basic styling
   * and it will be invisible.
   *
   * The main Y axis can be different for some charts,
   * it can be the left one or the right one.
   *
   * @readonly
   * @memberof BarChart
   */
  get mainYAxisName() {
    // Using "left" as default if no prop is passed.
    return this.props.yAxisPosition || "left";
  }

  get secondYAxisName() {
    return this.mainYAxisName === "left" ? "right" : "left";
  }

  get dataSets(): DataSetType[] {
    return this.state.data.dataSets;
  }

  get isDataEmpty() {
    return this.dataSets[0].values.every((value) => value.marker === "na");
  }

  handleSelect(index) {
    var segment = this.chartInstance.getDatasetMeta(0).data[index];
    //Need to save for later use
    var originalColor = segment._model.backgroundColor;
    segment._model.backgroundColor = this.chartInstance.tooltip._data.datasets[0].hoverBackgroundColor[
      index
    ];
    this.chartInstance.tooltip._active = [segment];
    this.chartInstance.tooltip.update(true);
    this.chartInstance.draw();
    //Set the original color back so when clearing the highlight the good color is restored
    segment._model.backgroundColor = originalColor;
  }

  getYAxisTicks() {
    if (!this.chartInstance) return null;
    let elementsObj = document.getElementsByClassName("barChart-yAxis_labels");
    for (let i = 0; i < elementsObj.length; i++) {
      if (elementsObj[i].style.display !== "flex") {
        elementsObj[i].style.display = "flex"
      }
    }
    return (
      <div className="barChart-yAxis-ticks">
        {this.chartInstance.scales["y-axis-0"].ticks.map(item => {
          return <div>{item}</div>
        })}
      </div>
    );
  }

  render() {
    return (
      <View
        style={{
          flex: 1,
          display: this.isDataEmpty ? "none" : "flex"
        }}
        onLayout={(event) => {
          this.props.onLayout
            ? this.props.onLayout(event.nativeEvent.layout)
            : null;
        }}
      >
        <View style={styles.container}>
          {this.props.isLoading ? null : (
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                marginTop: 10
              }}
            >
              <div
                style={{
                  display: 'flex', flexDirection: "row",
                  height: this.isDataEmpty ? 0 : undefined,
                  width: !this.props.isHistory && this.props.yValues.length > 13 ? this.props.yValues.length * this.props.barWidth : "100%"
                }}
                className="chart-container"
              >
                <div id="YAxisLabels" className="barChart-yAxis_labels"
                  style={{ height: `calc((100% - ${this.props.multiplicator * 30}px - 51px - 35px - 144px) / ${this.props.multiplicator})` }}
                >
                  <div className="barChart-yAxis_label">{this.props.title}</div>
                  {this.getYAxisTicks()}
                </div>
                <canvas id="myChart" ref={this.barChartRef} />
              </div>
              <View
                style={{
                  flex: 1,
                  justifyContent: "center",
                }}
              >
                <BarChartNoDataContainer isDataEmpty={this.isDataEmpty} />
              </View>
            </View>
          )}
        </View>
      </View>
    );
  }
}

export default BarChart;
